import React from 'react';
import Modal from 'react-modal';
import VideoPlayer from '@src/components/VideoPlayer';
import styles from './VideoModal.module.scss';
import { ButtonIcon } from '../Button';
import { COLOR_LIME } from '@src/types/colors';

interface VideoModal {
  mediaVideo: any;
  videoIsOpen: boolean;
  closeModal: () => void;
}

const VideoModal: React.FC<VideoModal> = ({
  mediaVideo,
  videoIsOpen,
  closeModal
}) => {
  return (
    <Modal
      appElement={
        (typeof document !== 'undefined' &&
          document.getElementById('__next')) ||
        undefined
      }
      isOpen={videoIsOpen}
      onRequestClose={closeModal}
      overlayClassName={styles.videoOverlay}
      className={styles.videoContent}
      contentLabel={mediaVideo?.caption || ''}
    >
      <VideoPlayer
        source={
          (mediaVideo.cloudinaryVideoSource &&
            mediaVideo.cloudinaryVideoSource[0].secure_url) ||
          mediaVideo.externalUrl
        }
      />
      <div className={styles.closeButton}>
        <ButtonIcon
          icon="Close"
          color={COLOR_LIME}
          onClick={() => closeModal()}
        ></ButtonIcon>
      </div>
    </Modal>
  );
};

export default VideoModal;

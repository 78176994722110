import {
  ContentfulPageHyperlinkFragments,
  HyperLink
} from '@src/types/contentful';
import {
  addTrailingSlash,
  arrayPathToString,
  flattenPagePaths,
  getLinkData,
  getPaddedURLPathArray
} from '@src/utils/url';
import { Breadcrumb } from '@src/components/Nav/Breadcrumbs/Breadcrumbs';
import {
  SvgIconName,
  SVG_FACEBOOK,
  SVG_INSTAGRAM,
  SVG_LINKEDIN,
  SVG_YOUTUBE
} from '@src/types/svg';
import {
  CtaFragment,
  GalleryFragment,
  MediaImageFragment,
  NationalBasePageQuery,
  NavElFragment,
  PageCommunityDetail,
  PageCommunityDetailFragment,
  PageCommunityStandardPart1ContentBlocksQuery,
  PageCommunityStandardPart2ContentBlocksQuery,
  PageNationalStandardPart1ContentBlocksQuery,
  PageNationalStandardPart2ContentBlocksQuery
} from '@src/graphql/gql-types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isDefined } from '@src/utils/graphql';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { PATHS } from '@src/constants/site';
import { ContentBlocksItem } from '@src/types/contentBlocks';
import { ButtonStandard } from '@src/components/Button';
import { ButtonLinkStandard } from '@src/components/ButtonLink';
import { cloudinaryClient } from '@src/services/cloudinary';
import { fill } from '@cloudinary/url-gen/actions/resize';

/**
 * Look through the breadcrumbs to find if isLiveChatEnabled which is on the Community details content-type
 *  - We use the breadcrumbs as the object is already stored and flattened for easy search.
 * @param breadcrumbs
 * @returns
 */
export const getIsLiveChatEnabledFromBreadcrumbs = (
  breadcrumbs: Breadcrumb[]
): boolean | undefined => {
  const isLiveChatEnabled = breadcrumbs.find(
    (crumb) => !!crumb.isLiveChatEnabled
  )?.isLiveChatEnabled;
  return isLiveChatEnabled;
};

/* Look through the breadcrumbs to find the Community details page
 *  - We use the breadcrumbs as the object is already stored and flattened for easy search.
 * @param breadcrumbs
 * @returns
 */
export const getCommunityDetailsFromBreadcrumbs = (
  breadcrumbs: Breadcrumb[]
): Breadcrumb | undefined => {
  const communityDetailsCrumb = breadcrumbs.find(
    (crumb) => crumb.__typename === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY
  );
  return communityDetailsCrumb;
};

/**
 *
 * @param primaryNav
 * @param footerNav
 * @param socialLinks
 * @param legalLinks
 * @param pagePathData
 * @returns Object representation of the footer navigation menu and navigation elements
 */
export const getFooterNavItems = (
  primaryNav: NationalBasePageQuery['primaryNav'],
  footerNav: NationalBasePageQuery['footerNav'],
  socialLinks: NationalBasePageQuery['socialLinks'],
  legalLinks: NationalBasePageQuery['legalLinks'],
  breadcrumbs: Breadcrumb[]
) => {
  return {
    firstColumn: primaryNav?.items[0]?.navigationElementsCollection?.items,
    secondColumn: footerNav?.items[0]?.navigationElementsCollection?.items,
    socialLinks: socialLinks?.items[0]?.navigationElementsCollection?.items,
    legalLinks: legalLinks?.items[0]?.navigationElementsCollection?.items,
    breadcrumbs
  };
};

/**
 * Create the breadcrumb path.
 *  - __Contains business rules for padding out the breadcrumbs where required__. Applies to the contentful page types __pageCommunityNews, pageCommunityPropertyDetail, pageSharedNews__
 *  - There is also padding for National News pages __pageNationalNews__
 *
 *  __NOTE: If the slug names change then this function will need to be updated with the new slugs to find for community 'for-sale','news-and-events' and National 'news'__
 *
 * @param hyperlinksObj
 * @param lookupPages The page lookup array for a specific community or National page, so that we can get the specific breadcrumb parent folder.
 *  - We look for __`for-sale`__ and __`news-and-events`__ pages by __slug__ in this array of pages for __pageCommunityNews, pageCommunityPropertyDetail, pageSharedNews__ page types
 *  - We also look for `news` page for National pages
 * @param trailingSlash
 * @returns breadcrumbs
 */
export const getBreadcrumbs = (
  hyperlinksObj: ContentfulPageHyperlinkFragments,
  lookupPages?: ContentfulPageHyperlinkFragments[],
  trailingSlash = true
): Breadcrumb[] => {
  const arraySegments = flattenPagePaths(hyperlinksObj);

  const currentArrayPath: any[] = [];

  /**
   * Below code is used for splicing in the missing page segments for breadcrumbs
   * This is a business rule for Community News (and Shared news) and Property pages
   * - This splice code is currently only needed for the contentful page types
   *   - pageCommunityNews, pageCommunityPropertyDetail, pageSharedNews
   */
  if (
    lookupPages &&
    lookupPages.length &&
    arraySegments &&
    arraySegments.length
  ) {
    const lastSegment = arraySegments[arraySegments.length - 1];
    const typename = lastSegment?.__typename;
    switch (typename) {
      case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_PROPERTY_DETAIL:
        {
          const forSaleSegment = getItemFromMatchingKeyValue(
            lookupPages as Record<never, string>[],
            'slug',
            'for-sale'
          );
          arraySegments.splice(1, 0, forSaleSegment);
        }
        break;

      case CONTENTFUL_PAGE_CONTENT_TYPES.SHARED_NEWS:
      case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_NEWS:
        {
          const newsAndEventsSegment = getItemFromMatchingKeyValue(
            lookupPages as Record<never, string>[],
            'slug',
            'news-and-events'
          );
          arraySegments.splice(1, 0, newsAndEventsSegment);
        }
        break;
      case CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_NEWS:
        {
          const newsSegment = getItemFromMatchingKeyValue(
            lookupPages as Record<never, string>[],
            'slug',
            'news'
          );
          arraySegments.splice(0, 0, newsSegment);
        }
        break;
    }
  }
  // END Splice code

  // Start building the crumbs
  const crumbsArray = arraySegments.filter(isDefined).map((crumbItem) => {
    currentArrayPath.push(arraySegments.shift()); // append the first segment from arraySegments during each loop
    return {
      __typename: crumbItem?.__typename,
      id: crumbItem?.sys?.id,
      slug: crumbItem?.slug,
      title: crumbItem?.title,
      urlPath: addTrailingSlash(
        arrayPathToString(getPaddedURLPathArray(currentArrayPath)),
        trailingSlash
      ),
      theme: crumbItem?.theme,
      salesforceProjectId: crumbItem?.salesforceProjectId,
      isLiveChatEnabled: crumbItem?.isLiveChatEnabled
    };
  });

  return crumbsArray;
};

/**
 *
 * @param page items: pagePayload?.page?.items[0]
 * @returns boolean, true is current page is National or Community landing page
 */
export const isNationalOrCommunityLandingPage = (
  page:
    | {
        slug?: Maybe<string>;
        __typename?: Maybe<string>;
      }
    | undefined
    | null
) => {
  const pageType = page?.__typename;
  return (
    (page?.slug === PATHS.HOME &&
      pageType === CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD) ||
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY
  );
};

/**
 *
 * @param page items: pagePayload?.page?.items[0]
 * @returns boolean, true is current page is a Community page
 */
export const isCommunityPage = (
  page:
    | {
        slug?: Maybe<string>;
        __typename?: Maybe<string>;
      }
    | undefined
    | null
) => {
  const pageType = page?.__typename;
  return (
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY ||
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_STANDARD ||
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_PROPERTY_DETAIL ||
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_NEWS ||
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.SHARED_NEWS ||
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_EVENT
  );
};

/**
 *
 * @param navElements
 * @returns linkData decorated with social icon, compares urlPath with platform name
 */
export const getSocialLinksWithIcon = (
  navElements?: Maybe<NavElFragment>[]
) => {
  const socialPlatforms = [
    { name: 'facebook', icon: SVG_FACEBOOK },
    { name: 'instagram', icon: SVG_INSTAGRAM },
    { name: 'linkedin', icon: SVG_LINKEDIN },
    { name: 'youtube', icon: SVG_YOUTUBE }
  ];

  const socialLinks = getLinkData(navElements, true);

  return socialLinks?.flatMap((item: HyperLink) => {
    return socialPlatforms
      .map((platform) => {
        if (item.urlPath?.includes(platform.name)) {
          return { ...item, icon: platform.icon as SvgIconName };
        }
      })
      .filter((item) => !!item);
  });
};

/**
 * Find Community Details page from the flattened segments
 * @param flattenPagePathsArray
 */
export const getCommunityDetailSegmentFromFlattenPagePaths = (
  flattenPagePathsArray: any[]
): PageCommunityDetail => {
  return flattenPagePathsArray.find(
    (item) => item?.__typename === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY
  );
};

/**
 * Takes an array of objects, looks for the key and value pair. returns first matching object
 * @param ary [{}]
 * @param key
 * @param value
 * @returns
 */
export const getItemFromMatchingKeyValue = (
  ary: Record<any, string>[] | undefined,
  key: string,
  value: string
): Record<never, string> | undefined => {
  if (!ary) {
    return undefined;
  }
  const foundItem = ary.find((item) => {
    return key in item && item[key] === value;
  });
  return foundItem;
};

/**
 * Merges multiple Content Block graphql query responses into one array of blocks.
 * Each query has a Content Blocks array of identical length. If the Content Block was not fetched in the query,
 * the array object contains only '__typename' (used to indicate positioning).
 * @param queries []
 * @returns contentBlocks[]
 */

export const mergeContentBlocks = (
  queries: Array<
    | PageNationalStandardPart1ContentBlocksQuery
    | PageNationalStandardPart2ContentBlocksQuery
    | PageCommunityStandardPart1ContentBlocksQuery
    | PageCommunityStandardPart2ContentBlocksQuery
  >
) => {
  const mergedContentBlocks = [];

  // fetch content blocks array from each payload query
  const contentBlocksArrays = queries.map((contentBlock) => {
    return contentBlock.pageContentBlocks?.items[0]?.contentBlocksCollection
      ?.items;
  });

  // make sure there is at least one content block query fetched
  if (contentBlocksArrays[0]) {
    // loop through all content block entries on the page
    for (let i = 0; i < contentBlocksArrays[0]?.length; i++) {
      // loop through all the queried responses and...
      for (let j = 0; j < contentBlocksArrays.length; j++) {
        // ...find the content block with more key values than just '__typename'
        if (Object.keys(contentBlocksArrays[j]?.[i] || []).length > 1) {
          mergedContentBlocks.push(contentBlocksArrays[j]?.[i]);
        }
      }
    }
  }

  return mergedContentBlocks as ContentBlocksItem[];
};

export const groupCommunitiesByState = (
  communities?: PageCommunityDetailFragment[]
) => {
  const group: Record<string, PageCommunityDetailFragment[]> = {};
  communities?.forEach((item) => {
    const stateLabel = item?.state?.stateterritoryLabel as string;
    if (!group[stateLabel]) {
      group[stateLabel] = [item];
    } else {
      group[stateLabel].push(item);
    }
  });
  const sortedGroup = Object.keys(group)
    .sort()
    .reduce((acc: Record<string, PageCommunityDetailFragment[]>, currValue) => {
      acc[currValue] = group[currValue];
      return acc;
    }, {});

  return sortedGroup;
};

export const groupStatesByLabel = (
  communities?: PageCommunityDetailFragment[]
) => {
  const group: Record<string, { slug?: string; stateterritoryLabel?: string }> =
    {};
  communities?.forEach((item) => {
    const stateLabel = item?.state?.stateterritoryLabel as string;
    if (!group[stateLabel]) {
      group[stateLabel] = item?.state as {
        slug?: string;
        stateterritoryLabel?: string;
      };
    }
  });
  return group;
};

export const splitCommunityGroupsInColumns = (
  communities?: PageCommunityDetailFragment[]
) => {
  const groupedByState = groupCommunitiesByState(communities) || {};
  const columnA: Record<string, PageCommunityDetailFragment[]> = {};
  const columnB: Record<string, PageCommunityDetailFragment[]> = {};
  const numberOfGroups = Object.keys(groupedByState).length;
  Object.keys(groupedByState).forEach((state, i) => {
    // put the first n / 2 groups in the first column, the rest in the second
    // * n being the number groups (=== number of states that have communities in them)
    if (i <= Math.floor(numberOfGroups / 2)) {
      columnA[state] = groupedByState[state];
    } else {
      columnB[state] = groupedByState[state];
    }
  });
  return [columnA, columnB];
};

/**
 * Take in the callToActionCollection.items query type and spit out
 * an array of CtaFragments
 * @param items __typename "CallToAction"
 * @returns Array<CtaFragment>
 */
export const getArrayOfCTAs = (
  items: CtaFragment[]
): CtaFragment[] | undefined => items as CtaFragment[];

/**
 * Depending on what the cta consists of, return a component name that will suit the purpose
 * @param cta CtaFragment
 * @returns name of ButtonLinkStandard || ButtonStandard
 */
export const getTypeOfButton = (cta: CtaFragment) => {
  if (cta.internalLink || cta.urlLink || cta.assetLink) {
    return ButtonLinkStandard;
  } else if (cta.modalId) {
    return ButtonStandard;
  }
  return;
};

/**
 * Generates the metaTitle by combining the provided string values and separating them by pipes.
 * E.g. string | string | string
 * Input is an array of strings. It can take nested arrays and will use the first defined value in that nested array.
 * E.g. [['', 'a'],'b','','c',['d']] = "a | b | c | d"
 * @param titleArray an array of title strings. Can contain nested arrays 2 levels deep
 * @returns string
 */
export const generateMetaTitle = (titleArray: Array<any>) => {
  let metaTitle = '';
  titleArray.forEach(function each(item: Array<any> | string) {
    if (Array.isArray(item)) {
      // If is array, iterate again
      item.forEach((i) => {
        if (i) {
          if (metaTitle === '') {
            metaTitle += `${i}`;
          } else {
            metaTitle += ` | ${i}`;
          }
        }
      });
    } else {
      if (item) {
        if (metaTitle === '') {
          metaTitle += `${item}`;
        } else {
          metaTitle += ` | ${item}`;
        }
      }
    }
  });
  return metaTitle;
};

/**
 *
 * @param item
 * @returns object representation of url and alt text OR null for use in SEO metaOgImage & metaOgImageAlt
 */
export const getOgImage = (
  item?: Maybe<MediaImageFragment | GalleryFragment>
) => {
  if (
    (item?.__typename === 'MediaImage' && item?.cloudinarySource) ||
    item?.__typename === 'Gallery'
  ) {
    // resize for facebook og size
    const img = cloudinaryClient
      .image(item.cloudinarySource[0].public_id)
      .resize(fill().width(1200).height(600))
      .quality('75')
      .format('jpg');
    return {
      url: img.toURL(),
      alt: item.cloudinarySource[0].context?.custom?.alt || 'image'
    };
  }

  return null;
};

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './BedSVG.module.scss';

interface BedSVG {
  color: Colors;
}

const BedSVG: React.FC<BedSVG> = ({ color }) => {
  const fillColor = classNames(styles.bedSvg, getColorClasses(color, styles));
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <path
        className={fillColor}
        d="M88 48c0-4.4-3.6-8-8-8V28c0-4.4-3.6-8-8-8H24c-4.4 0-8 3.6-8 8v12c-4.4 0-8 3.6-8 8v20h5.32L16 76h4l2.68-8h50.68L76 76h4l2.68-8H88V48zm-16-8H52V28h20v12zM24 28h20v12H24V28zm-8 20h64v12H16V48z"
      ></path>
    </svg>
  );
};

export default BedSVG;

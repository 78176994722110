import Icon from '@src/elements/Icon';
import { Colors, COLOR_SLATE } from '@src/types/colors';
import React from 'react';
import styles from './LoadingState.module.scss';

interface LoadingState {
  color?: Colors;
}

const LoadingState: React.FC<LoadingState> = ({ color = COLOR_SLATE }) => (
  <div className={styles.loadingState}>
    <Icon icon="Loading" color={color} />
  </div>
);

export default LoadingState;

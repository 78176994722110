import React from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { COLOR_SLATE } from '@src/types/colors';
import { getButtonPillClasses } from '@src/utils/buttonClasses';
import buttonStyles from '@src/components/Button/ButtonPill/ButtonPill.module.scss';
import linkStyles from '@src/components/LinkWrapper/LinkWrapper.module.scss';
import Icon from '@src/elements/Icon';
import { Button, LinkAsAButton } from '@src/types/buttons';

const ButtonLinkPill: React.FC<
  LinkAsAButton & Omit<Button, 'fillVariant' | 'size'>
> = ({
  color = COLOR_SLATE,
  colorVariant = 'dark',
  icon,
  iconPosition = 'right',
  isIconBouncing,
  href,
  openNewWindow,
  disabled,
  children,
  ...rest
}) => {
  const target = openNewWindow ? '_blank' : '_self';
  const rel = openNewWindow ? 'noopener noreferrer' : 'Prefetch';
  const buttonPillStyle = classNames(
    getButtonPillClasses(color, colorVariant, buttonStyles)
  );

  return (
    <span className={linkStyles.linkWrapper}>
      <NextLink
        href={href ? href : {}}
        passHref
        aria-disabled={disabled}
        target={target}
        rel={rel}
        className={classNames(buttonPillStyle, buttonStyles.buttonContainer, {
          [buttonStyles.buttonContainerIconLeft]: iconPosition === 'left',
          [buttonStyles.disabled]: disabled
        })}
        {...rest}
      >
        {children}
        {icon && (
          <div
            className={classNames(buttonStyles.icon, {
              [buttonStyles.iconSolo]: !children,
              [buttonStyles.iconLeft]: iconPosition === 'left',
              [buttonStyles.bounceIcon]: isIconBouncing
            })}
          >
            <Icon icon={icon} />
          </div>
        )}
      </NextLink>
    </span>
  );
};

export default ButtonLinkPill;

import React, { useState } from 'react';
import ButtonLinkStandard from '@src/components/ButtonLink/ButtonLinkStandard';
import { COLOR_SLATE } from '@src/types/colors';
import { Button } from '@src/types/buttons';
import { getCTA } from '@src/utils/url';
import ButtonStandard from '@src/components/Button/ButtonStandard';
import { CtaFragment } from '@src/graphql/gql-types';
import { CTA_TYPE } from '@src/types/callToAction';
import VideoModal from '@src/components/VideoModal';

type CTAButton = Button & {
  cta: CtaFragment;
  label: string;
};

const CTAButton: React.FC<CTAButton> = ({
  cta,
  icon,
  label,
  color = COLOR_SLATE,
  colorVariant,
  fillVariant = 'filled',
  iconPosition = 'left'
}) => {
  const [videoIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  // TODO: add modal enquire as a type
  const CTAtype = ((): CTA_TYPE => {
    if (
      cta.assetLink?.__typename === 'MediaVideo' &&
      cta.assetLink.externalUrl != undefined
    ) {
      return CTA_TYPE.VIDEO;
    } else {
      return CTA_TYPE.LINK;
    }
  })();

  // TODO: add modal enquire CTA as a case
  switch (CTAtype) {
    case CTA_TYPE.VIDEO:
      return (
        <span>
          <ButtonStandard
            icon={icon}
            iconPosition={iconPosition}
            aria-label={`Play ${label} Video`}
            color={color}
            colorVariant={colorVariant}
            onClick={() => openModal()}
          >
            {label}
          </ButtonStandard>
          <VideoModal
            mediaVideo={cta.assetLink}
            videoIsOpen={videoIsOpen}
            closeModal={closeModal}
          />
        </span>
      );
    default:
      return (
        <ButtonLinkStandard
          {...getCTA(cta)}
          icon={icon}
          iconPosition={iconPosition}
          color={color}
          colorVariant={colorVariant}
          fillVariant={fillVariant}
          aria-label={label}
        >
          {label}
        </ButtonLinkStandard>
      );
  }
};

export default CTAButton;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './SlashSVG.module.scss';

interface SlashSVG {
  color: Colors;
}

const SlashSVG: React.FC<SlashSVG> = ({ color }) => {
  const fillColor = classNames(styles.slashSvg, getColorClasses(color, styles));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 6 26"
      width="100"
      height="100"
    >
      <path
        className={fillColor}
        d="M3.34.978l1.98.278-3.34 23.767L0 24.744z"
      ></path>
    </svg>
  );
};

export default SlashSVG;

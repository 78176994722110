import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ExternalLinkSVG.module.scss';

interface ExternalLinkSVG {
  color: Colors;
}

const ExternalLinkSVG: React.FC<ExternalLinkSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.externalLinkSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.62775 0 0 3.62775 0 8V64C0 68.3723 3.62775 72 8 72H64C68.3723 72 72 68.3723 72 64V36H64V64H8V8H36V0H8ZM44 0V8H58.3438L21.1719 45.1719L26.8281 50.8281L64 13.6562V28H72V0H44Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ExternalLinkSVG;

import React from 'react';

type HtmlComment = {
  comment: string;
};

const HtmlComment: React.FC<HtmlComment> = ({ comment }) => {
  if (process.env.NEXT_PUBLIC_LOCAL_DEV) {
    return <span dangerouslySetInnerHTML={{ __html: `<!-- ${comment} -->` }} />;
  }
  return null;
};

export default HtmlComment;

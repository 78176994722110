import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './BathSVG.module.scss';

interface BathSVG {
  color: Colors;
}

const BathSVG: React.FC<BathSVG> = ({ color }) => {
  const fillColor = classNames(styles.bathSvg, getColorClasses(color, styles));
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <path className={fillColor} d="M28 36a8 8 0 100-16 8 8 0 000 16z"></path>
      <path
        className={fillColor}
        d="M80 52V19.32C80 13.08 74.92 8 68.68 8c-3 0-5.88 1.2-8 3.32l-5 5c-.64-.2-1.32-.32-2.04-.32-1.6 0-3.08.48-4.32 1.28l11.04 11.04c.8-1.24 1.28-2.72 1.28-4.32 0-.72-.12-1.36-.28-2.04l5-5a3.3 3.3 0 012.32-.96c1.84 0 3.32 1.48 3.32 3.32V52H44.6c-1.2-.84-2.28-1.8-3.28-2.88l-5.6-6.2a8.581 8.581 0 00-2.76-2c-1.24-.6-2.6-.92-4-.92C24 40.04 20 44.04 20 49v3H8v24c0 4.4 3.6 8 8 8 0 2.2 1.8 4 4 4h56c2.2 0 4-1.8 4-4 4.4 0 8-3.6 8-8V52h-8zm0 24H16V60h64v16z"
      ></path>
    </svg>
  );
};

export default BathSVG;

import { GlobalSettingItemFragment } from '@src/graphql/gql-types';

export const getGlobalSettingByCodeId = (
  globalSettings: GlobalSettingItemFragment[],
  codeId: string
) => globalSettings?.find((globalSetting) => globalSetting?.codeId === codeId);

export const getSiteWideAlertBannerEntry = (
  items: GlobalSettingItemFragment[]
): GlobalSettingItemFragment | undefined =>
  getGlobalSettingByCodeId(items, 'content-global-alert-banner');

export const getGlobalCTAPanelEntry = (
  items: GlobalSettingItemFragment[]
): GlobalSettingItemFragment | undefined =>
  getGlobalSettingByCodeId(items, 'content-global-cta-panel');

export const getGlobalImpactPanelEntry = (
  items: GlobalSettingItemFragment[]
): GlobalSettingItemFragment | undefined =>
  getGlobalSettingByCodeId(items, 'content-global-impact-panel');

export const getFormEnquiryDisclaimerEntry = (
  items: GlobalSettingItemFragment[]
): GlobalSettingItemFragment | undefined =>
  getGlobalSettingByCodeId(
    items,
    'content-form-enquiry-disclaimer-contact-us-field-agreement'
  );
export const getNationalContactUsHeadOfficeEntry = (
  items: GlobalSettingItemFragment[]
): GlobalSettingItemFragment | undefined =>
  getGlobalSettingByCodeId(
    items,
    'content-national-contact-us-head-office-text'
  );

export const getGlobalContactPhoneNumberEntry = (
  items: GlobalSettingItemFragment[]
): GlobalSettingItemFragment | undefined =>
  getGlobalSettingByCodeId(items, 'content-global-contact-phone-number');

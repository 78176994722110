import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './TwitterSVG.module.scss';

interface TwitterSVG {
  color: Colors;
}

const TwitterSVG: React.FC<TwitterSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.twitterSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 88 73">
      <path
        className={fillColor}
        d="M88 1a43.6 43.6 0 01-12.56 6.12 17.92 17.92 0 00-31.44 12v4A42.64 42.64 0 018 5S-8 41 28 57a46.56 46.56 0 01-28 8c36 20 80 0 80-46a18 18 0 00-.32-3.32A30.88 30.88 0 0088 1z"
      ></path>
    </svg>
  );
};

export default TwitterSVG;

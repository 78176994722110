export const SITE_NAME = 'Levande';
export const SITE_LOGO = 'assets/logo.png';
export const MAP_MARKER = 'assets/marker.png';
export const MAP_PIN = 'assets/map-pin.svg';
export const CONTACT_NUMBER = '1800 72 71 70';
export const AVAILABLE_HOURS = 'Monday - Friday, 9:00am - 5:00pm';

export const PATHS = {
  HOME: '/',
  NATIONAL_NEWS: 'news',
  COMMUNITY: 'community',
  COMMUNITY_NEWS_AND_EVENTS: 'news-and-events',
  COMMUNITY_NEWS: 'news',
  COMMUNITY_EVENT: 'event',
  COMMUNITY_FOR_SALE: 'for-sale', // Community Property Detail pages go under here
  COMMUNITY_SHARED: 'shared', // For National news pages shared under a community
  FIND_YOUR_COMMUNITY: 'find-your-community'
};

export const SELECT_COMMUNITY_EVENT_TAB_QUERY_PARAM = '?selectedTab=events';
export const SELECT_COMMUNITY_NEWS_TAB_QUERY_PARAM = '?selectedTab=news';

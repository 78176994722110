import classNames from 'classnames';
import React from 'react';
import styles from './SelectInput.module.scss';

interface SelectInput extends Omit<React.HTMLProps<HTMLSelectElement>, 'ref'> {
  /**
   * make ID required
   */
  id: string;
  /**
   * The label for the input
   */
  label?: string;
  /**
   * The form field assistive / helper text
   */
  assistiveText?: string;
  /**
   * The form field is in an error state
   */
  hasError?: boolean;
  /**
   * The form field error text
   */
  fieldErrorMessage?: string;
}

const SelectInput = React.forwardRef<HTMLSelectElement, SelectInput>(
  function SelectInput(
    {
      id,
      label,
      children,
      assistiveText,
      hasError,
      fieldErrorMessage,
      ...rest
    },
    ref
  ) {
    return (
      <div className={styles.selectInput}>
        <label
          className={classNames(styles.label, {
            [styles.labelError]: hasError
          })}
          htmlFor={id}
        >
          {label}
        </label>

        <select
          className={classNames(styles.select, {
            [styles.inputError]: hasError,
            [styles.selectReadOnly]: rest.readOnly
          })}
          aria-label="select-input"
          ref={ref}
          {...rest}
        >
          {children}
        </select>

        {assistiveText && !hasError && (
          <p className={styles.assistiveText}>{assistiveText}</p>
        )}

        {hasError && fieldErrorMessage && (
          <p className={styles.fieldErrorMessage}>{fieldErrorMessage}</p>
        )}
      </div>
    );
  }
);

export default SelectInput;

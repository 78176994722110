import React, { ReactNode } from 'react';
import styles from './Table.module.scss';

interface Table {
  children: ReactNode;
}

const Table: React.FC<Table> = ({ children }) => (
  <div className={styles.tableContainer}>
    <div className={styles.table}>
      <table>
        <tbody>{children}</tbody>
      </table>
    </div>
  </div>
);

export default Table;

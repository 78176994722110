import React from 'react';
import styles from './AlertBanner.module.scss';
import { CtaFragment, GlobalSettingItemFragment } from '@src/graphql/gql-types';
import { Column, Container, Row } from '@src/elements/Grid';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { COLOR_SLATE } from '@src/types/colors';
import { getArrayOfCTAs } from '@src/utils/helpers';
import CTAButton from '@src/components/Button/CTAButton';

type AlertBanner = GlobalSettingItemFragment;

const AlertBanner: React.FC<AlertBanner> = ({
  richText,
  callToActionCollection
}) => {
  const ctasArray = getArrayOfCTAs(
    callToActionCollection?.items as CtaFragment[]
  );

  return (
    <div className={styles.bannerContainer}>
      <Container>
        <Row>
          <Column
            xs={4}
            md={8}
            lg={6}
            xl={10}
            alignXs="flex-start"
            justifyXs="flex-start"
            justifyLg="center"
          >
            <div className={styles.bannerContent}>
              {richText && (
                <RichTextRender
                  bodyContent={richText}
                  pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                  renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                  slateLinkStyle
                />
              )}
            </div>
          </Column>

          {ctasArray?.map((cta, index) => (
            <Column
              xs={4}
              md={8}
              lg={2}
              xl={2}
              alignXs="flex-start"
              alignLg="flex-end"
              justifyXs="flex-start"
              justifyLg="center"
              key={index}
            >
              <CTAButton
                cta={cta}
                label={cta.label || ''}
                fillVariant="outline"
                color={COLOR_SLATE}
              />
            </Column>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default AlertBanner;

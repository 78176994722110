import React from 'react';
import NextLink from 'next/link';
import styles from './LinkWrapper.module.scss';
import classNames from 'classnames';

interface LinkWrapper extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isActive?: boolean;
  hoverUnderline?: boolean;
  openNewWindow?: boolean;
}

const LinkWrapper: React.FC<LinkWrapper> = ({
  href,
  isActive,
  hoverUnderline,
  openNewWindow,
  children,
  className,
  ...rest
}) => {
  const target = openNewWindow ? '_blank' : '_self';
  const rel = openNewWindow ? 'noopener noreferrer' : 'Prefetch';

  return (
    <span
      className={classNames(className, styles.linkWrapper, {
        [styles.linkWrapperHover]: !!hoverUnderline
      })}
    >
      {!isActive && href ? (
        <NextLink
          href={href ? href : {}}
          passHref
          target={target}
          rel={rel}
          className={styles.linkAnchor}
          {...rest}
          // suppress hydration warnings for links as tracker query params may differ between CSR and SSR (e.g. Cloudinary)
          suppressHydrationWarning={true}
        >
          {children}
        </NextLink>
      ) : (
        <span {...rest}>{children}</span>
      )}
    </span>
  );
};

export default LinkWrapper;

import { Colors } from '@src/types/colors';
import { getColorClasses } from '@src/utils/colorClasses';
import classNames from 'classnames';
import React from 'react';
import styles from './FilterSVG.module.scss';

interface FilterSVG extends React.SVGProps<SVGSVGElement> {
  color: Colors;
}

const FilterSVG: React.FC<FilterSVG> = ({ color, ...rest }) => {
  const fillColor = classNames(
    styles.filterSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      className={fillColor}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" />
    </svg>
  );
};

export default FilterSVG;

import Icon from '@src/elements/Icon';
import { MediaVideoFragment } from '@src/graphql/gql-types';
import { COLOR_SLATE } from '@src/types/colors';
import { SVG_PLAY } from '@src/types/svg';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import MediaImage from '@src/components/MediaImage';
import VideoModal from '@src/components/VideoModal';
import styles from './MediaVideo.module.scss';
import { useRouter } from 'next/router';
import {
  ModalState,
  useModalOpenOrClose
} from '@src/hooks/useModalOpenOrClose';

type MediaVideo = MediaVideoFragment & {
  id?: string | null;
  applyTheme?: boolean;
};

const MediaVideo: React.FC<MediaVideo> = (mediaVideo) => {
  const videoName = (mediaVideo.caption || mediaVideo.id || 'video')
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase();
  const [videoIsOpen, setModal] = useState<ModalState>({ open: false });
  const { handleOpen, handleClose } = useModalOpenOrClose(setModal, videoName);
  const router = useRouter();

  const mediaVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    router.beforePopState(() => {
      setModal({ open: false });
      return true;
    });
  }, [router.asPath, videoName, router]);

  const openModal = () => {
    handleOpen();
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    handleClose();
    document.body.style.overflow = 'auto';
  };

  return (
    <div ref={mediaVideoRef} className={styles.videoPreview}>
      {mediaVideo && <MediaImage {...mediaVideo} priority />}
      <svg
        className={classNames(styles.backgroundTriangle, {
          [styles.backgroundTriangleThemed]: mediaVideo.applyTheme
        })}
        viewBox="0 0 301 677"
      >
        <polygon points="301 0, 0 677, 301 677" />
      </svg>
      <div className={styles.playButton} onClick={() => openModal()}>
        <div className={styles.outerRing}></div>
        <div className={styles.playIconButtonWrapper}>
          <div className={styles.playIconWrapper}>
            <div className={styles.playIconPad}>
              <Icon icon={SVG_PLAY} color={COLOR_SLATE} />
            </div>
          </div>
        </div>
      </div>
      <VideoModal
        mediaVideo={mediaVideo}
        videoIsOpen={videoIsOpen.open}
        closeModal={() => {
          closeModal();
        }}
      />
    </div>
  );
};

export default MediaVideo;

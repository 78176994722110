import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './PenSVG.module.scss';

interface PenSVG {
  color: Colors;
}

const PenSVG: React.FC<PenSVG> = ({ color }) => {
  const fillColor = classNames(styles.penSvg, getColorClasses(color, styles));
  return (
    <svg viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fillColor}
        d="M44.24 24.08L47.92 27.76L11.68 64H8V60.32L44.24 24.08ZM58.64 0C57.64 0 56.6 0.4 55.84 1.16L48.52 8.48L63.52 23.48L70.84 16.16C72.4 14.6 72.4 12.08 70.84 10.52L61.48 1.16C60.68 0.36 59.68 0 58.64 0ZM44.24 12.76L0 57V72H15L59.24 27.76L44.24 12.76Z"
      />
    </svg>
  );
};

export default PenSVG;

import React from 'react';
import classNames from 'classnames';
import { COLOR_SLATE } from '@src/types/colors';
import { getButtonClasses } from '@src/utils/buttonClasses';
import Icon from '@src/elements/Icon';
import { Button } from '@src/types/buttons';
import styles from './ButtonStandard.module.scss';

const ButtonStandard: React.FC<Button> = ({
  color = COLOR_SLATE,
  colorVariant = 'dark',
  fillVariant = 'filled',
  size = 'md',
  icon,
  iconPosition = 'right',
  isIconBouncing,
  children,
  ...rest
}) => {
  const buttonStyle = classNames(
    getButtonClasses(
      color,
      size,
      colorVariant,
      fillVariant === 'outline',
      styles
    )
  );

  return (
    <button
      type="button"
      className={classNames(buttonStyle, styles.buttonContainer, {
        [styles.buttonContainerIconLeft]: iconPosition === 'left',
        [styles.disabled]: rest?.disabled
      })}
      {...rest}
    >
      {children}
      {icon && (
        <div
          className={classNames(styles.icon, {
            [styles.iconSolo]: !children,
            [styles.iconLeft]: iconPosition === 'left',
            [styles.bounceIcon]: isIconBouncing
          })}
        >
          <Icon icon={icon} />
        </div>
      )}
    </button>
  );
};

export default ButtonStandard;

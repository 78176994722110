import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './PauseSVG.module.scss';

interface PauseSVG {
  color: Colors;
}

const PauseSVG: React.FC<PauseSVG> = ({ color }) => {
  const fillColor = classNames(styles.pauseSvg, getColorClasses(color, styles));
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillColor}
        d="M-6.10352e-05 56H15.9999V0H-6.10352e-05V56ZM31.9999 0V56H47.9999V0H31.9999Z"
      />
    </svg>
  );
};

export default PauseSVG;

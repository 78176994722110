import React from 'react';
import {
  GlobalSettingItemFragment,
  Maybe,
  NavElFragment,
  NavSubElFragment,
  PageCommunityDetail,
  PageCommunityNews,
  PageCommunityPropertyDetail,
  PageCommunityStandard
} from '@src/graphql/gql-types';
import { THEME_DEFAULT, Themes } from '@src/types/themes';
import Header from '@src/components/Header';
import Footer from '@src/components/Footer';
import styles from './Layout.module.scss';
import { FooterMenu } from '@src/components/Footer/Footer';
import AlertBanner from '@src/components/AlertBanner';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import { useRouter } from 'next/router';
import GlobalModal from '../GlobalModal';

type Layout = {
  alertBannerData?: AlertBanner;
  primaryNav?: Maybe<NavElFragment & NavSubElFragment>[];
  footerNav?: FooterMenu;
  pageCommunityData?: Partial<
    | PageCommunityStandard
    | PageCommunityDetail
    | PageCommunityPropertyDetail
    | PageCommunityNews
  >;
  communityName?: Maybe<string>;
  communitySlug?: Maybe<string>;
  showBreadCrumbs?: boolean;
  dynamicFormValues?: NationalEnquiryDynamicFormValues;
  formDisclaimerRichText?: GlobalSettingItemFragment;
  headOfficePhoneNumber?: Maybe<string>;
  theme?: Themes;
  children: React.ReactNode;
} & Header &
  Footer;

const Layout: React.FC<Layout> = ({
  alertBannerData,
  primaryNav,
  footerNav,
  pageCommunityData,
  communityName,
  communitySlug,
  showBreadCrumbs,
  dynamicFormValues,
  formDisclaimerRichText,
  headOfficePhoneNumber,
  theme = THEME_DEFAULT,
  showLiveChat = false,
  children
}) => {
  const router = useRouter();
  const preview = router.isPreview;

  return (
    <div className={`theme-${theme}`}>
      <div className={styles.container}>
        <Header
          primaryMenu={primaryNav}
          pageCommunityData={pageCommunityData}
          communityName={communityName}
          communitySlug={communitySlug}
          dynamicFormValues={dynamicFormValues}
          formDisclaimerRichText={formDisclaimerRichText}
          headOfficePhoneNumber={headOfficePhoneNumber}
          preview={preview}
        />
        {alertBannerData && <AlertBanner {...alertBannerData} />}
        <main>{children}</main>
        <Footer
          footerMenu={footerNav}
          showBreadCrumbs={showBreadCrumbs}
          showLiveChat={showLiveChat}
        />
      </div>
      <GlobalModal theme={theme} />
    </div>
  );
};

export default Layout;

import React from 'react';
import classNames from 'classnames';
import styles from './FieldSet.module.scss';

interface FieldSet {
  label?: string;
  children: React.ReactNode;
  hasError?: boolean;
  noBottomSpacing?: boolean;
  disabled?: boolean;
}

const FieldSet: React.FC<FieldSet> = ({
  label,
  hasError,
  noBottomSpacing,
  children,
  disabled
}) => {
  return (
    <fieldset
      className={classNames(styles.fieldSet, {
        [styles.noBottomSpacing]: !!noBottomSpacing,
        [styles.fieldSetDisabled]: !!disabled
      })}
    >
      {label && (
        <label
          className={classNames(styles.fieldSetLabel, {
            [styles.fieldSetError]: !!hasError
          })}
        >
          {label}
        </label>
      )}
      {children}
    </fieldset>
  );
};

export default FieldSet;

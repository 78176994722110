import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './CarSVG.module.scss';

interface CarSVG {
  color: Colors;
}

const CarSVG: React.FC<CarSVG> = ({ color }) => {
  const fillColor = classNames(styles.carSvg, getColorClasses(color, styles));
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <path
        className={fillColor}
        d="M75.68 24.04A5.977 5.977 0 0070 20H26c-2.64 0-4.84 1.68-5.68 4.04L12 48v32c0 2.2 1.8 4 4 4h4c2.2 0 4-1.8 4-4v-4h48v4c0 2.2 1.8 4 4 4h4c2.2 0 4-1.8 4-4V48l-8.32-23.96zM27.4 28h41.16l4.32 12.44h-49.8L27.4 28zM76 68H20V48h56v20z"
      ></path>
      <path
        className={fillColor}
        d="M30 64a6 6 0 100-12 6 6 0 000 12zm36 0a6 6 0 100-12 6 6 0 000 12z"
      ></path>
    </svg>
  );
};

export default CarSVG;

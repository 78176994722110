import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ChevronDownSVG.module.scss';

interface ChevronDownSVG {
  color: Colors;
}

const ChevronDownSVG: React.FC<ChevronDownSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.chevronDownSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 30">
      <path
        className={fillColor}
        d="M5.64.36L24 18.68 42.36.36 48 6 24 30 0 6 5.64.36z"
      ></path>
    </svg>
  );
};

export default ChevronDownSVG;

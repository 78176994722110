import React from 'react';
import styles from './Visibility.module.scss';

interface Visibility {
  /**
   * xs screen size visibility
   */
  xs?: boolean;
  /**
   * sm screen size visibility
   */
  sm?: boolean;
  /**
   * md screen size visibility
   */
  md?: boolean;
  /**
   * lg screen size visibility
   */
  lg?: boolean;
  /**
   * xl screen size visibility
   */
  xl?: boolean;
  /**
   * xxl screen size visibility
   */
  xxl?: boolean;
  xxxl?: boolean;
  children: React.ReactNode;
}

const Visibility: React.FC<Visibility> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
  children
}) => {
  return (
    <div
      className={styles.visibility}
      data-show-xs={xs}
      data-show-sm={sm}
      data-show-md={md}
      data-show-lg={lg}
      data-show-xl={xl}
      data-show-xxl={xxl}
      data-show-xxxl={xxxl}
    >
      {children}
    </div>
  );
};

export default Visibility;

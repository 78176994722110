import React from 'react';
import Head from 'next/head';
import { SITE_NAME, SITE_LOGO } from '@src/constants/site';
import { getPageUrlWithBaseURL } from '@src/utils/url';

interface SEOProps {
  metaTitle?: string | undefined;
  metaPageUrl: string;
  metaDesc?: string;
  metaKeywords?: string;
  metaOgTitle?: string;
  metaOgImage?: string;
  metaOgImageAlt?: string;
  noIndex?: boolean | null | undefined;
  noFollow?: boolean | null | undefined;
  seoRedirect?: string;
  canonicalUrl?: string;
}

// Google Search - structured data
const ldJsonData = {
  __html: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: SITE_NAME,
    alternateName: 'Levande Living',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`
  })
};

const SEO: React.FC<SEOProps> = ({
  metaTitle = 'Levande | A new definition of lifestyle living',
  metaPageUrl = process.env.NEXT_PUBLIC_BASE_URL,
  metaDesc,
  metaKeywords,
  metaOgTitle,
  metaOgImage = getPageUrlWithBaseURL(SITE_LOGO),
  metaOgImageAlt,
  noIndex,
  noFollow,
  seoRedirect,
  canonicalUrl
}) => {
  const fullPageUrl = getPageUrlWithBaseURL(metaPageUrl as string);
  const fullPageCanonicalUrl = canonicalUrl
    ? getPageUrlWithBaseURL(canonicalUrl)
    : undefined;
  const metaDescription = metaDesc ? metaDesc : metaTitle;
  const metaRobots = [];

  if (noIndex || !!seoRedirect) {
    metaRobots.push('noindex');
  }
  if (noFollow) {
    metaRobots.push('nofollow');
  }

  return (
    <Head key="seo-head">
      <title>{metaTitle}</title>
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href={fullPageCanonicalUrl || fullPageUrl} />

      {!!seoRedirect && (
        <meta httpEquiv="refresh" content={`0;URL='${seoRedirect}'`} />
      )}
      {!!metaRobots.length && (
        <meta name="robots" content={metaRobots.join(',')} />
      )}

      <meta name="description" content={metaDescription} />

      {!!metaKeywords && <meta name="keywords" content={metaKeywords} />}

      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaOgImage} />

      {/* Open Graph data */}
      <meta
        property="og:title"
        content={metaOgTitle ? metaOgTitle : metaTitle}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullPageUrl} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:image" content={metaOgImage as string} />
      <meta property="og:image:secure_url" content={metaOgImage as string} />
      {metaOgImageAlt && (
        <meta property="og:image:alt" content={metaOgImageAlt as string} />
      )}

      <script type="application/ld+json" dangerouslySetInnerHTML={ldJsonData} />
    </Head>
  );
};

export default SEO;

import { Gallery } from '@src/graphql/gql-types';
import React, { useEffect, useState } from 'react';
import styles from './Showcase.module.scss';
import Image from '@src/elements/Image';
import classNames from 'classnames';
import Heading from '@src/elements/Heading';
import Modal from 'react-modal';
import GalleryModal from '@src/components/GalleryModal';
import { useRouter } from 'next/router';
import {
  ModalState,
  useModalOpenOrClose
} from '@src/hooks/useModalOpenOrClose';
import { useWindow } from '@src/hooks/useWindow';

interface Showcase {
  heading?: string;
  gallery: Gallery['cloudinarySource'];
}

const Showcase: React.FC<Showcase> = ({ heading, gallery }) => {
  const { document } = useWindow();
  const galleryImages = gallery;
  const [modal, setModal] = useState<ModalState>({
    open: false,
    initial: 0
  });
  const showcaseId = (heading || 'gallery')
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase();

  const router = useRouter();
  const { handleOpen, handleClose } = useModalOpenOrClose(setModal, showcaseId);

  useEffect(() => {
    if (modal.open) {
      router.beforePopState(() => {
        setModal({ open: false, initial: 0 });
        return true;
      });
    }
  }, [modal.open, router]);

  useEffect(() => {
    if (document) {
      if (modal.open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [document, modal.open]);

  return (
    <div className={styles.galleryShowcase}>
      {gallery[0] && (
        <div className={styles.featuredImage} onClick={() => handleOpen(0)}>
          <Image image={galleryImages?.[0]} caption={gallery[0].caption} />
        </div>
      )}
      <div className={styles.snippets}>
        <div className={styles.snippetSquare} onClick={() => handleOpen(1)}>
          {galleryImages?.[1] && (
            <Image
              image={galleryImages?.[1]}
              caption={gallery?.cloudinarySource?.[1].caption}
              thumb
            />
          )}
        </div>
        <div className={styles.snippetSquare} onClick={() => handleOpen(2)}>
          {galleryImages?.[2] && (
            <Image
              image={galleryImages?.[2]}
              caption={gallery?.cloudinarySource?.[0].caption}
              thumb
            />
          )}
        </div>
        <div
          className={classNames(styles.snippetSquare, styles.snippetViewAll)}
          onClick={() => handleOpen(0)}
        >
          <Heading priority={4} fontStyle="h4">
            View all
          </Heading>
        </div>
      </div>
      <Modal
        appElement={
          (typeof document !== 'undefined' &&
            document.getElementById('__next')) ||
          undefined
        }
        isOpen={modal.open}
        onRequestClose={() => setModal({ open: false, initial: 0 })}
        overlayClassName={styles.galleryOverlay}
        className={styles.galleryContent}
        contentLabel={`${heading} Gallery`}
      >
        <GalleryModal
          gallery={galleryImages}
          initial={modal.initial}
          onRequestClose={() => handleClose()}
        />
      </Modal>
    </div>
  );
};

export default Showcase;

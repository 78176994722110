import React from 'react';
import Heading from '@src/elements/Heading';
import { COLOR_SLATE, COLOR_WHITE } from '@src/types/colors';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Modal from 'react-modal';
import ButtonIcon from '../Button/ButtonIcon';
import styles from './GlobalModal.module.scss';
import useModalStore from '@src/store';
import { Themes } from '@src/types/themes';

const GlobalModal: React.FC<{ theme: Themes }> = ({ theme }) => {
  const router = useRouter();
  const { closeModal, modalState } = useModalStore();

  const onRequestClose = () => {
    if (!modalState.disableBackgroundClosable) {
      closeModal();
    }
  };

  return (
    <Modal
      appElement={
        (typeof document !== 'undefined' &&
          document.getElementById('__next')) ||
        undefined
      }
      isOpen={!!modalState.isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={styles.modalOverlay}
      // Apply theme class - modals reside in a React Portal (sibling of div#_next")
      portalClassName={`theme-${theme}`}
      className={styles.modalContent}
      contentLabel={modalState.heading}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'auto')}
    >
      <div
        className={classNames(styles.modalHeader, {
          [styles.modalHeaderError]: modalState.error
        })}
      >
        <Heading priority={1} fontStyle="h4">
          {modalState.heading}
        </Heading>
        <ButtonIcon
          icon="Close"
          color={modalState?.error ? COLOR_WHITE : COLOR_SLATE}
          fillVariant="outline"
          isBorderless
          aria-label="Close Enquiry Form"
          onClick={() => {
            closeModal();
            // TODO: why is this needed?
            // regardless, added scroll:false so we don't scroll to top which is quite a janky experience
            router.push(window.location?.href.split('#')[0], undefined, {
              scroll: false
            });
          }}
        />
      </div>
      {modalState.isOpen && (
        <div className={styles.modalBody}>{modalState.content}</div>
      )}
    </Modal>
  );
};

export default GlobalModal;

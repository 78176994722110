import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ListSVG.module.scss';

interface ListSVG {
  color: Colors;
}

const ListSVG: React.FC<ListSVG> = ({ color }) => {
  const fillColor = classNames(styles.listSvg, getColorClasses(color, styles));

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 72 40">
      <path
        className={fillColor}
        d="M0 24H8V16H0V24ZM0 40H8V32H0V40ZM0 8H8V0H0V8ZM16 24H72V16H16V24ZM16 40H72V32H16V40ZM16 0V8H72V0H16Z"
      ></path>
    </svg>
  );
};

export default ListSVG;

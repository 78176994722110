import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './YoutubeSVG.module.scss';

interface YoutubeSVG {
  color: Colors;
}

const YoutubeSVG: React.FC<YoutubeSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.youtubeSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <path
        className={fillColor}
        fillRule="evenodd"
        d="M87.355 20.638a11.12 11.12 0 012.805 5.042A116 116 0 0192 47c.025 7.041-.59 14.07-1.84 21a11.12 11.12 0 01-7.76 8C75.52 77.84 48 77.84 48 77.84s-27.52 0-34.4-1.84a11.12 11.12 0 01-7.76-7.68A116 116 0 014 47c-.035-7.095.58-14.178 1.84-21.16a11.12 11.12 0 017.76-8C20.48 16 48 16 48 16s27.52 0 34.4 1.68a11.12 11.12 0 014.954 2.958zM62 47L39 60.08V33.92L62 47z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default YoutubeSVG;

import { Entry, EntryFields } from '@src/types/contentful';
import { ENTRY_TYPES, RichTextBodyLinks } from '@src/types/richText';

export const getReferencedEntryDataWithId = (
  id: string,
  entryType: ENTRY_TYPES,
  links?: RichTextBodyLinks
): Entry | undefined => {
  let entryData;
  const { entries } = links || {};
  switch (entryType) {
    case ENTRY_TYPES.ENTRY_HYPERLINK:
      entryData = entries?.hyperlink?.find(
        (x) => x?.sys?.id === id
      ) as EntryFields;
      break;
    case ENTRY_TYPES.EMBEDDED_ENTRY_INLINE:
      if (entries && 'inline' in entries) {
        entryData = entries?.inline?.find(
          (x) => x?.sys?.id === id
        ) as EntryFields;
      }
      break;

    case ENTRY_TYPES.BLOCK:
      if (entries && 'block' in entries) {
        entries.block.map((block) => {
          /**
           * Also check for "__typename" field to see that there is data with this id.
           *
           * - Records WITH "__typename" field are the correct records and contain the content-type data to be returned.
           * - Records WITHOUT "__typename" field are empty and should be skipped.
           *
           * This can be a side effect of splitting the richText field into 2 separate graphQL calls to get around the 8kb contentful Graphql query size limit.
           */
          if (block?.sys.id === id && block?.__typename) {
            entryData = block;
          }
        });
      }
      break;
  }

  if (entryData) {
    return {
      fields: { ...entryData },
      sys: { ...entryData.sys }
    };
  }
};

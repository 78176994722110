import React, { useState } from 'react';
import styles from './Image.module.scss';
import { AdvancedImage } from '@cloudinary/react';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import {
  LG_BREAKPOINT,
  MD_BREAKPOINT,
  SM_BREAKPOINT,
  XL_BREAKPOINT,
  XXL_BREAKPOINT
} from '@src/constants/breakpoints';
import { cloudinaryClient } from '@src/services/cloudinary';
import { CloudinarySource } from '@src/types/cloudinary';
import { ImageAspectRatio } from '@src/types/image';
import classNames from 'classnames';
import LoadingState from '@src/components/LoadingState';

import NextImage from 'next/image';

interface Image {
  // NOTE: priority should only be set for images above the fold
  priority?: boolean;
  image: CloudinarySource;
  base64Img?: string;
  caption?: string;
  thumb?: boolean;
  aspectRatio?: ImageAspectRatio;
  hasLoadingState?: boolean;
  contain?: boolean;
}

const cloudinaryImageLoader = ({ src }: { src: string }) => {
  return `${src}?q=75`;
};

const Image: React.FC<Image> = ({
  priority,
  image,
  base64Img,
  caption,
  thumb,
  aspectRatio,
  hasLoadingState = false,
  contain
}) => {
  const imageId = image?.public_id || '';
  const img = cloudinaryClient.image(imageId);
  const fallback = cloudinaryClient.image(imageId);
  const [isLoading, setIsLoading] = useState(true);

  const srcs: {
    [key: string]: {
      cloudinaryClient: CloudinaryImage;
      breakpoint: number;
    };
  } = {
    sm: {
      cloudinaryClient: cloudinaryClient.image(imageId),
      breakpoint: SM_BREAKPOINT
    },
    md: {
      cloudinaryClient: cloudinaryClient.image(imageId),
      breakpoint: MD_BREAKPOINT
    },
    lg: {
      cloudinaryClient: cloudinaryClient.image(imageId),
      breakpoint: LG_BREAKPOINT
    },
    xl: {
      cloudinaryClient: cloudinaryClient.image(imageId),
      breakpoint: XL_BREAKPOINT
    },
    xxl: {
      cloudinaryClient: cloudinaryClient.image(imageId),
      breakpoint: XXL_BREAKPOINT
    }
  };

  if (thumb) {
    img
      .resize(fill().width(256).height(256).aspectRatio(1))
      .format('auto')
      .quality('auto');
  } else {
    let ar;
    switch (aspectRatio) {
      case '16:9':
        ar = 16 / 9;
        break;
      case '4:3':
        ar = 4 / 3;
        break;
      case '1:1':
        ar = 1;
        break;
      default:
        break;
    }

    for (const key in srcs) {
      const resizeFnChain = fill()
        .width(srcs[key].breakpoint)
        .gravity(autoGravity());
      if (ar) {
        resizeFnChain.aspectRatio(ar);
      }
      srcs[key].cloudinaryClient
        .resize(resizeFnChain)
        .format('auto')
        .quality('auto');
    }

    if (ar) {
      fallback
        .resize(
          fill().width(SM_BREAKPOINT).aspectRatio(ar).gravity(autoGravity())
        )
        .format('jpg')
        .quality('auto');
    } else {
      fallback
        .resize(fill().width(SM_BREAKPOINT).gravity(autoGravity()))
        .format('jpg')
        .quality('auto');
    }
  }

  return (
    <>
      {thumb ? (
        <AdvancedImage cldImg={img} alt={caption || ''} />
      ) : (
        <>
          {isLoading && hasLoadingState && (
            <div className={styles.loadingState}>
              <LoadingState />
            </div>
          )}
          <div
            data-next-image
            className={classNames(styles.nextImageWrapper, {
              [styles.contain]: contain
            })}
          >
            <NextImage
              onLoad={() => {
                setIsLoading(false);
              }}
              src={srcs.xxl.cloudinaryClient.toURL()}
              layout="fill"
              alt={caption || 'image'}
              quality={75}
              // preload priority images - preload links will be injected into the the document head at build time
              {...(priority && { priority: true })}
              // set blurDataURL with build time generated 10*10px base64 encoded hero image
              {...(priority &&
                base64Img && { blurDataURL: base64Img, placeholder: 'blur' })}
              {...(!priority && { loading: 'lazy' })}
              loader={cloudinaryImageLoader}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Image;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './PlaySVG.module.scss';

interface PlaySVG {
  color: Colors;
}

const PlaySVG: React.FC<PlaySVG> = ({ color }) => {
  const fillColor = classNames(styles.playSvg, getColorClasses(color, styles));
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 44 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className={fillColor} d="M0 0V56L44 28L0 0Z" />
    </svg>
  );
};

export default PlaySVG;

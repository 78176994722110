import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ArrowDownSVG.module.scss';

interface ArrowDownSVG {
  color: Colors;
}

const ArrowDownSVG: React.FC<ArrowDownSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.arrowDownSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
      <path
        className={fillColor}
        d="M64 32l-5.64-5.64L36 48.68V0h-8v48.68L5.68 26.32 0 32l32 32 32-32z"
      ></path>
    </svg>
  );
};

export default ArrowDownSVG;

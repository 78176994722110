import { NodeData } from '@contentful/rich-text-types';
import React from 'react';
import {
  isMediaImageEntry,
  isMediaVideoEntry,
  ContentfulPageTypes,
  isGalleryEntry,
  isMatterportBlockEntry
} from '@src/types/contentful';
import { ENTRY_TYPES, RichTextBodyLinks } from '@src/types/richText';
import { getReferencedEntryDataWithId } from '@src/utils/richText';
import MediaImage from '@src/components/MediaImage';
import styles from '@src/elements/Content/Content.module.scss';
import Showcase from '@src/components/Showcase';
import MediaVideo from '@src/components/MediaVideo';
import MatterportBlock from '@src/components/MatterportBlock';
import classNames from 'classnames';

interface RenderReferencedComponent {
  nodeData: NodeData;
  links?: RichTextBodyLinks;
  /**
   * Page type to determine how components are rendered per page if needed
   */
  pageType: ContentfulPageTypes;
}

const RenderReferencedComponent: React.FC<RenderReferencedComponent> = ({
  nodeData,
  links
}) => {
  const referencedBlockData = getReferencedEntryDataWithId(
    nodeData.target.sys.id,
    ENTRY_TYPES.BLOCK,
    links
  );
  const item = referencedBlockData?.fields;

  return (
    <div
      className={classNames(styles.mediaContent, {
        [styles.mediaContentImage]: item && isMediaImageEntry(item)
      })}
    >
      {item && isMediaImageEntry(item) && <MediaImage {...item} />}
      {item && isMediaVideoEntry(item) && <MediaVideo {...item} />}
      {item && isGalleryEntry(item) && (
        <Showcase heading="Gallery" gallery={item?.cloudinarySource} />
      )}
      {item && isMatterportBlockEntry(item) && <MatterportBlock {...item} />}
    </div>
  );
};

export default RenderReferencedComponent;

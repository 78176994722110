import React from 'react';
import classNames from 'classnames';
import Icon from '@src/elements/Icon';
import { SvgIconName } from '@src/types/svg';
import styles from './CheckboxSelectionInput.module.scss';
import { COLOR_ERROR, COLOR_SLATE } from '@src/types/colors';

interface CheckboxSelectionInput
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref'> {
  /**
   * make ID required
   */
  id: string;
  /**
   * label icon
   * */
  labelIcon?: SvgIconName;
  /**
   * The form field is in an error state
   */
  hasError?: boolean;
  /**
   * The form field error text
   */
  fieldErrorMessage?: string;
}

const CheckboxSelectionInput = React.forwardRef<
  HTMLInputElement,
  CheckboxSelectionInput
>(function CheckboxSelectionInput(
  { id, labelIcon, hasError, fieldErrorMessage, children, ...rest },
  ref
) {
  return (
    <>
      <input
        ref={ref}
        id={id}
        type="checkbox"
        {...rest}
        className={classNames(styles.checkboxSelectionInput, {
          [styles.checkboxSelectionInputError]: hasError,
          [styles.checkboxSelectionInputReadOnly]: rest.readOnly
        })}
      />
      <label htmlFor={id} className={styles.checkboxSelectionLabel}>
        {labelIcon && (
          <span className={styles.checkboxSelectionLabelIcon}>
            <Icon
              icon={labelIcon}
              color={hasError ? COLOR_ERROR : COLOR_SLATE}
            />
          </span>
        )}
        {children}
      </label>
      {hasError && fieldErrorMessage && (
        <p className={styles.fieldErrorMessage}>{fieldErrorMessage}</p>
      )}
    </>
  );
});

export default CheckboxSelectionInput;

import { GlobalSettingItemFragment } from '@src/graphql/gql-types';
import { getGlobalContactPhoneNumberEntry } from '@src/utils/globalSettings';
import { formatDate } from '@src/utils/date';
import { WordDictionary } from '@src/types/dictionary';

/**
 * Makes a string camelCase
 * @param str
 * @returns
 */
export const camelizeString = (str: string): string => {
  return str
    .replace(/(?:^\w|\[A-Z\]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

/**
 * Converts a string to Pascal Case. Retains existing capital characters
 * E.g."pageNationalStandard", "page-National-standard" -> "PageNationalStandard", "PageNationalStandard"
 * @param str
 * @returns a converted string
 */
export const toPascalCase = (str: string) => {
  return `${str}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
};

/**
 * Converts a string from Pascal Case to spaced. Retains existing capital characters
 * E.g."PageNationalStandard" -> "Page National Standard"
 * @param str
 * @returns a converted string
 */
export const pascalToDisplay = (str: string | undefined) => {
  return str?.replace(/\B([A-Z])\B/g, ' $1');
};

/**
 * Finds and replaces strings based on a dictionary and matching `{key}` and replacing with `value`
 * @param contentString string to look in
 * @param dictionary key:value pairs used to find and replace strings in `{ }`
 * @returns modified string
 */
export const replaceStringWithDictionary = (
  contentString?: string | null,
  dictionary?: WordDictionary
): string | undefined | null => {
  if (contentString && dictionary && typeof contentString === 'string') {
    for (const [key, value] of Object.entries(dictionary)) {
      contentString = contentString.replace(
        new RegExp(`{${key}}`, 'gi'),
        value
      );
    }
  }
  return contentString;
};

/**
 * Takes the RichText JSON and replaces strings within { } (where they match a "dictionary" of strings to replace)
 *   - eg : "todays date is {date}"  = "todays date is 27/09/22"
 * @param json json object from contentful RichText
 * @param dictionary an object of key:value pairs, and replaces strings based on the key, eg {key} with the value of that key
 * @returns updated json
 */
export const replaceRichTextJson = (
  json: any,
  dictionary?: WordDictionary
): any => {
  if (dictionary) {
    for (const k in json) {
      if (typeof json[k] === 'object') {
        replaceRichTextJson(json[k], dictionary);
      } else {
        // base case, stop recurring
        if (['value', 'uri'].includes(k)) {
          const str = replaceStringWithDictionary(json[k], dictionary);
          if (str && str?.length) {
            json[k] = str;
          }
        }
      }
    }
  }
  return json;
};

/**
 * Creates an object/dictionary of key:value pairs to replace in a string.
 *  - pre defines the following keys
 *    - {phonenumber} (from globalSettings content-type)
 *    - {date}
 * @param globalSettings The global settings Items (From graphQL)
 * @param date UTC Date string (Usually the sys.publishedAt date from a contentful entry)
 * @param wordsToAdd key:value pairs for words to add
 * @returns
 */
export const createReplaceDictionary = (
  globalSettings?: GlobalSettingItemFragment[],
  publishedAt?: string,
  wordsToAdd?: WordDictionary
): WordDictionary | undefined => {
  const phonenumber = globalSettings
    ? (getGlobalContactPhoneNumberEntry(globalSettings)?.value as string)
    : undefined;

  let dic = {};
  if (phonenumber) {
    dic = { phonenumber };
  }

  if (publishedAt) {
    dic = { ...dic, publishedAt: formatDate(publishedAt) };
  }

  if (wordsToAdd) {
    dic = { ...dic, ...wordsToAdd };
  }

  return Object.keys(dic).length !== 0 ? dic : undefined;
};

/**
 * Takes a word with a number to return the pluralised form with the quantity. Optionally takes a custom plural word to overwrite.
 * Example:
 * (1 "mug") => "1 mug"
 * (4 "mug") => "4 mugs"
 * (1 "mug" "muggos") => "1 mug"
 * (2 "mug" "muggos") => "2 muggos"
 * @param val
 * @param word
 * @param plural
 * @returns label string
 */
export const pluraliseWord = (val: number, word: string, plural?: string) => {
  let label = `${val} ${word}`;
  if (val > 1 || val === 0) {
    if (!plural) {
      label = `${val} ${word}s`;
    } else {
      label = `${val} ${plural}`;
    }
  }
  return label;
};

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './TelephoneSVG.module.scss';

interface TelephoneSVG {
  color: Colors;
}

const TelephoneSVG: React.FC<TelephoneSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.telephoneSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 72 72">
      <path
        fill={fillColor}
        d="M14.16 8C14.4 11.56 15 15.04 15.96 18.36L11.16 23.16C9.52 18.36 8.48 13.28 8.12 8H14.16ZM53.6 56.08C57 57.04 60.48 57.64 64 57.88V63.84C58.72 63.48 53.64 62.44 48.8 60.84L53.6 56.08ZM18 0H4C1.8 0 0 1.8 0 4C0 41.56 30.44 72 68 72C70.2 72 72 70.2 72 68V54.04C72 51.84 70.2 50.04 68 50.04C63.04 50.04 58.2 49.24 53.72 47.76C53.32 47.6 52.88 47.56 52.48 47.56C51.44 47.56 50.44 47.96 49.64 48.72L40.84 57.52C29.52 51.72 20.24 42.48 14.48 31.16L23.28 22.36C24.4 21.24 24.72 19.68 24.28 18.28C22.8 13.8 22 9 22 4C22 1.8 20.2 0 18 0Z"
      ></path>
    </svg>
  );
};

export default TelephoneSVG;

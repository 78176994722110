import React from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import Icon from '@src/elements/Icon';
import { ButtonIconType, LinkAsAButton } from '@src/types/buttons';
import { COLOR_SLATE } from '@src/types/colors';
import { getButtonLinkIconClasses } from '@src/utils/buttonClasses';
import buttonStyles from '@src/components/Button/ButtonIcon/ButtonIcon.module.scss';
import linkStyles from '@src/components/LinkWrapper/LinkWrapper.module.scss';

const ButtonLinkIcon: React.FC<LinkAsAButton & ButtonIconType> = ({
  icon,
  color = COLOR_SLATE,
  colorVariant = 'dark',
  fillVariant = 'filled',
  size = 'md',
  isIconBouncing,
  href,
  openNewWindow,
  disabled,
  isBorderless = false,
  ...rest
}) => {
  const target = openNewWindow ? '_blank' : '_self';
  const rel = openNewWindow ? 'noopener noreferrer' : 'Prefetch';
  const buttonStyle = classNames(
    getButtonLinkIconClasses(
      color,
      size,
      colorVariant,
      fillVariant === 'outline',
      buttonStyles
    )
  );

  return (
    <span className={linkStyles.linkWrapper}>
      <NextLink
        href={href ? href : {}}
        passHref
        aria-disabled={disabled}
        target={target}
        rel={rel}
        className={classNames(buttonStyle, buttonStyles.buttonContainer, {
          [buttonStyles.borderless]:
            fillVariant === 'outline' ? isBorderless : false,
          [buttonStyles.outlineHover]: fillVariant === 'outline',
          [buttonStyles.disabled]: disabled
        })}
        {...rest}
      >
        <div
          className={classNames({
            [buttonStyles.bounceIcon]: isIconBouncing
          })}
        >
          <Icon icon={icon} />
        </div>
      </NextLink>
    </span>
  );
};

export default ButtonLinkIcon;

import React from 'react';
import classNames from 'classnames';
import { BodyCopyVariant } from '@src/types/typography';
import styles from './Content.module.scss';

interface Content {
  children: React.ReactNode;
  bodyCopyVariant?: BodyCopyVariant;
}

const Content: React.FC<Content> = ({
  bodyCopyVariant = 'paragraphRegular',
  children
}) => {
  return (
    <div
      className={classNames(styles.content, {
        [styles.largeRegular]: bodyCopyVariant === 'largeRegular',
        [styles.largeSemibold]: bodyCopyVariant === 'largeSemibold',
        [styles.largeBold]: bodyCopyVariant === 'largeBold',
        [styles.paragraphRegular]: bodyCopyVariant === 'paragraphRegular',
        [styles.paragraphSemibold]: bodyCopyVariant === 'paragraphSemibold',
        [styles.paragraphBold]: bodyCopyVariant === 'paragraphBold',
        [styles.smallRegular]: bodyCopyVariant === 'smallRegular',
        [styles.smallSemibold]: bodyCopyVariant === 'smallSemibold',
        [styles.smallBold]: bodyCopyVariant === 'smallBold',
        [styles.smallestRegular]: bodyCopyVariant === 'smallestRegular',
        [styles.smallestSemibold]: bodyCopyVariant === 'smallestSemibold',
        [styles.smallestBold]: bodyCopyVariant === 'smallestBold'
      })}
    >
      {children}
    </div>
  );
};

export default Content;

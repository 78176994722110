import React from 'react';
import styles from './MatterportBlock.module.scss';
import { MatterportBlockFragment } from '@src/graphql/gql-types';
import Heading from '@src/elements/Heading';
import Script from 'next/script';

type MatterportBlock = MatterportBlockFragment;

const MatterportBlock: React.FC<MatterportBlock> = ({
  heading,
  description,
  sourceUrl
}) => {
  return (
    <div className={styles.matterportBlock}>
      <Script
        id="matterport-sdk"
        src="https://static.matterport.com/showcase-sdk/latest.js"
      />
      <div className={styles.textWrapper}>
        <div className={styles.headingWrapper}>
          <Heading priority={2} fontStyle="h2">
            {heading}
          </Heading>
        </div>
        <Heading priority={4} fontStyle="h4">
          {description}
        </Heading>
      </div>
      <iframe
        title={`${heading} Home Virtual Tour`}
        className={styles.matterportIframe}
        width="100%"
        src={`${sourceUrl}`}
        frameBorder="0"
        allow="fullscreen; vr; xr-spatial-tracking"
        id="showcase-iframe"
      />
    </div>
  );
};

export default MatterportBlock;

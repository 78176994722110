import React from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { COLOR_SLATE } from '@src/types/colors';
import { getButtonClasses } from '@src/utils/buttonClasses';
import Icon from '@src/elements/Icon';
import { Button, LinkAsAButton } from '@src/types/buttons';
import buttonStyles from '@src/components/Button/ButtonStandard/ButtonStandard.module.scss';
import linkStyles from '@src/components/LinkWrapper/LinkWrapper.module.scss';

const ButtonLinkStandard: React.FC<LinkAsAButton & Button> = ({
  color = COLOR_SLATE,
  colorVariant = 'dark',
  fillVariant = 'filled',
  size = 'md',
  icon,
  iconPosition = 'right',
  isIconBouncing,
  href,
  openNewWindow,
  disabled,
  children,
  ...rest
}) => {
  const target = openNewWindow ? '_blank' : '_self';
  const rel = openNewWindow ? 'noopener noreferrer' : 'Prefetch';
  const buttonStandardStyle = classNames(
    getButtonClasses(
      color,
      size,
      colorVariant,
      fillVariant === 'outline',
      buttonStyles
    )
  );

  return (
    <span className={linkStyles.linkWrapper}>
      <NextLink
        href={href ? href : {}}
        passHref
        aria-disabled={disabled}
        target={target}
        rel={rel}
        className={classNames(
          buttonStandardStyle,
          buttonStyles.buttonContainer,
          {
            [buttonStyles.buttonContainerIconLeft]: iconPosition === 'left',
            [buttonStyles.disabled]: disabled
          }
        )}
        {...rest}
      >
        {children}
        {icon && (
          <div
            className={classNames(buttonStyles.icon, {
              [buttonStyles.iconSolo]: !children,
              [buttonStyles.iconLeft]: iconPosition === 'left',
              [buttonStyles.bounceIcon]: isIconBouncing
            })}
          >
            <Icon icon={icon} />
          </div>
        )}
      </NextLink>
    </span>
  );
};

export default ButtonLinkStandard;

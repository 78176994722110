import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useRecaptchaV3 = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyRecaptcha = async (id: string): Promise<boolean> => {
    if (!executeRecaptcha) {
      console.log('Unable to execute reCAPTCHA');
      return false;
    }

    try {
      /**
       * Get token to send to api route to verify ReCaptcha.
       * Id is where the ReCaptcha is being triggered from, used for analytics.
       */
      const gRecaptchaToken = await executeRecaptcha(id);

      const response = await fetch('/api/validateReCaptchaV3/', {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ gRecaptchaToken })
      });

      const data = await response.json();

      // Uncomment the console logs if you would like to check your reCaptcha scores in the console
      if (data?.score >= 0.5) {
        // console.log(`Success with score: ${data?.score}`);
        return true;
      } else {
        // console.log(`Failure with score: ${data?.score}`);
        return false;
      }
    } catch (error) {
      console.error('Error during ReCaptcha verification:', error);
      return false;
    }
  };

  return { verifyRecaptcha };
};

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ArrowLeftSVG.module.scss';

interface ArrowLeftSVG {
  color: Colors;
}

const ArrowLeftSVG: React.FC<ArrowLeftSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.arrowLeftSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
      <path
        className={fillColor}
        d="M64 28H15.32L37.68 5.64 32 0 0 32l32 32 5.64-5.64L15.32 36H64v-8z"
      ></path>
    </svg>
  );
};

export default ArrowLeftSVG;

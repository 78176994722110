import {
  ArrowDownSVG,
  ArrowLeftSVG,
  ArrowRightSVG,
  ArrowUpSVG,
  AtSVG,
  BangSVG,
  BathSVG,
  BedSVG,
  CalendarSVG,
  CarSVG,
  ChevronDownSVG,
  ChevronLeftSVG,
  ChevronRightSVG,
  ChevronUpSVG,
  ClockSVG,
  CloseSVG,
  EnvelopeSVG,
  ExternalLinkSVG,
  FacebookSVG,
  FeedbackSVG,
  FilterSVG,
  HamburgerMenuSVG,
  HelpSolidSVG,
  InstagramSVG,
  LinkedinSVG,
  ListSVG,
  LoadingSVG,
  LogoSVG,
  MapMarkerSVG,
  MinusSVG,
  PauseSVG,
  PdfSVG,
  PenSVG,
  PhoneSVG,
  PlaySVG,
  PlanSVG,
  PlusSVG,
  QuestionAnswerSVG,
  SearchSVG,
  SlashSVG,
  TelephoneSVG,
  TwitterSVG,
  YoutubeSVG,
  BusinessSVG,
  DollarSignSVG,
  PersonSVG
} from '@src/svgs';

import {
  SvgIconComponent,
  SvgIconName,
  SVG_ARROW_DOWN,
  SVG_ARROW_LEFT,
  SVG_ARROW_RIGHT,
  SVG_ARROW_UP,
  SVG_AT,
  SVG_BANG,
  SVG_BATH,
  SVG_BED,
  SVG_CAR,
  SVG_CHEVRON_DOWN,
  SVG_CHEVRON_LEFT,
  SVG_CHEVRON_RIGHT,
  SVG_CHEVRON_UP,
  SVG_CLOCK,
  SVG_CLOSE,
  SVG_ENVELOPE,
  SVG_EXTERNAL_LINK,
  SVG_FACEBOOK,
  SVG_FEEDBACK,
  SVG_HAMBURGER,
  SVG_HELP_SOLID,
  SVG_INSTAGRAM,
  SVG_LINKEDIN,
  SVG_LIST,
  SVG_LOADING,
  SVG_LOGO,
  SVG_MAP_MARKER,
  SVG_MINUS,
  SVG_PAUSE,
  SVG_PDF,
  SVG_PEN,
  SVG_PHONE,
  SVG_PLAN,
  SVG_PLAY,
  SVG_PLUS,
  SVG_QUESTION_ANSWER,
  SVG_SEARCH,
  SVG_SLASH,
  SVG_TELEPHONE,
  SVG_TWITTER,
  SVG_YOUTUBE,
  SVG_FILTER,
  SVG_BUSINESS,
  SVG_DOLLAR_SIGN,
  SVG_CALENDAR,
  SVG_PERSON
} from '@src/types/svg';

export const findSvgIcon = (name: SvgIconName): SvgIconComponent | undefined =>
  svgIconDefinitions.find((c) => c.name === name);

const svgIconDefinitions: SvgIconComponent[] = [
  {
    name: SVG_ARROW_DOWN,
    component: ArrowDownSVG
  },
  {
    name: SVG_ARROW_LEFT,
    component: ArrowLeftSVG
  },
  {
    name: SVG_ARROW_RIGHT,
    component: ArrowRightSVG
  },
  {
    name: SVG_ARROW_UP,
    component: ArrowUpSVG
  },
  {
    name: SVG_AT,
    component: AtSVG
  },
  {
    name: SVG_BANG,
    component: BangSVG
  },
  {
    name: SVG_BATH,
    component: BathSVG
  },
  {
    name: SVG_BED,
    component: BedSVG
  },
  {
    name: SVG_CALENDAR,
    component: CalendarSVG
  },
  {
    name: SVG_CAR,
    component: CarSVG
  },
  {
    name: SVG_CHEVRON_DOWN,
    component: ChevronDownSVG
  },
  {
    name: SVG_CHEVRON_LEFT,
    component: ChevronLeftSVG
  },
  {
    name: SVG_CHEVRON_RIGHT,
    component: ChevronRightSVG
  },
  {
    name: SVG_CHEVRON_UP,
    component: ChevronUpSVG
  },
  {
    name: SVG_CLOCK,
    component: ClockSVG
  },
  {
    name: SVG_CLOSE,
    component: CloseSVG
  },
  {
    name: SVG_ENVELOPE,
    component: EnvelopeSVG
  },
  {
    name: SVG_EXTERNAL_LINK,
    component: ExternalLinkSVG
  },
  {
    name: SVG_FACEBOOK,
    component: FacebookSVG
  },
  {
    name: SVG_FEEDBACK,
    component: FeedbackSVG
  },
  {
    name: SVG_HAMBURGER,
    component: HamburgerMenuSVG
  },
  {
    name: SVG_HELP_SOLID,
    component: HelpSolidSVG
  },
  {
    name: SVG_INSTAGRAM,
    component: InstagramSVG
  },
  {
    name: SVG_LINKEDIN,
    component: LinkedinSVG
  },
  {
    name: SVG_LIST,
    component: ListSVG
  },
  {
    name: SVG_LOADING,
    component: LoadingSVG
  },
  {
    name: SVG_LOGO,
    component: LogoSVG
  },
  {
    name: SVG_MAP_MARKER,
    component: MapMarkerSVG
  },
  {
    name: SVG_MINUS,
    component: MinusSVG
  },
  {
    name: SVG_PAUSE,
    component: PauseSVG
  },
  {
    name: SVG_PDF,
    component: PdfSVG
  },
  {
    name: SVG_PEN,
    component: PenSVG
  },
  {
    name: SVG_PERSON,
    component: PersonSVG
  },
  {
    name: SVG_PHONE,
    component: PhoneSVG
  },
  {
    name: SVG_PLAN,
    component: PlanSVG
  },
  {
    name: SVG_PLAY,
    component: PlaySVG
  },
  {
    name: SVG_PLUS,
    component: PlusSVG
  },
  {
    name: SVG_QUESTION_ANSWER,
    component: QuestionAnswerSVG
  },
  {
    name: SVG_SEARCH,
    component: SearchSVG
  },
  {
    name: SVG_SLASH,
    component: SlashSVG
  },
  {
    name: SVG_TELEPHONE,
    component: TelephoneSVG
  },
  {
    name: SVG_TWITTER,
    component: TwitterSVG
  },
  {
    name: SVG_YOUTUBE,
    component: YoutubeSVG
  },
  {
    name: SVG_FILTER,
    component: FilterSVG
  },
  {
    name: SVG_BUSINESS,
    component: BusinessSVG
  },
  {
    name: SVG_DOLLAR_SIGN,
    component: DollarSignSVG
  }
];

export default svgIconDefinitions;

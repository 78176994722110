/**
 * Formate the Date to short form dd/mm/yy (Defaults to en-AU : Aussie timezone)
 * @param utcDateTime
 * @param locale eg: 'en-AU'
 * @returns
 */
export const formatDate = (utcDateTime: string, locale = 'en-AU'): string => {
  const theDate = new Date(utcDateTime);
  const formatter = Intl.DateTimeFormat(locale, { dateStyle: 'short' });

  return formatter.format(theDate);
};

export const getLatestDate = (d1: string, d2: string): string => {
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  if (date1 < date2) {
    // ${d1} is less than ${d2}
    return d2;
  } else if (date1 > date2) {
    // ${d1} is greater than ${d2}
    return d1;
  } else {
    // Both dates are equal
    return d1;
  }
};

/**
 * Format today's date (Defaults to Aussie timezone) to midnight and return it as an ISOString
 * @returns ISOstring of today's date at midnight this morning
 */
export const getTodaysEarliestISOString = () => {
  const todaysDateMidnightTimestamp = new Date();
  const localeString = todaysDateMidnightTimestamp.toLocaleDateString('en-US', {
    timeZone: 'Australia/Sydney'
  });

  const splitString = localeString.split('/');

  const isoString = new Date(
    Date.UTC(
      parseInt(splitString[2]),
      // months are zero indexed
      parseInt(splitString[0]) - 1,
      parseInt(splitString[1])
    )
  ).toISOString();

  return isoString;
};
/**
 * Formats todays date to YYYY-MM-DD, matching Contenfuls format.
 * @param date
 * @returns A string of the given date in format YYYY-MM-DD.
 */
export const formatToContentfulDate = (date: Date) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', {
    month: '2-digit'
  });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return [year, month, day].join('-').toString();
};

/**
 * Takes a date and adds a given amount of days to it.
 * @param date
 * @param amountOfDays
 * @returns a date.
 */
export const addDaysToDate = (date: Date, amountOfDays: number) => {
  const futureDate = new Date(date);
  futureDate.setDate(futureDate.getDate() + amountOfDays);

  return futureDate;
};

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ArrowRightSVG.module.scss';

interface ArrowRightSVG {
  color: Colors;
}

const ArrowRightSVG: React.FC<ArrowRightSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.arrowRightSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
      <path
        className={fillColor}
        d="M32 0l-5.64 5.64L48.68 28H0v8h48.68L26.36 58.36 32 64l32-32L32 0z"
      ></path>
    </svg>
  );
};

export default ArrowRightSVG;

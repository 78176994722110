import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './HamburgerMenuSVG.module.scss';

interface HamburgerMenuSVG {
  color: Colors;
}

const HamburgerMenuSVG: React.FC<HamburgerMenuSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.hamburgerMenuSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 72 48">
      <path
        className={fillColor}
        d="M0 48h72v-8H0v8zm0-20h72v-8H0v8zM0 0v8h72V0H0z"
      ></path>
    </svg>
  );
};

export default HamburgerMenuSVG;

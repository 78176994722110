import classNames from 'classnames';
import React from 'react';
import styles from './RadioInput.module.scss';

interface RadioInput extends Omit<React.HTMLProps<HTMLInputElement>, 'ref'> {
  /**
   * make ID required
   */
  id: string;
  /**
   * The form field is in an error state
   */
  hasError?: boolean;
  /**
   * The form field error text
   */
  fieldErrorMessage?: string;
}

const RadioInput = React.forwardRef<HTMLInputElement, RadioInput>(
  function RadioInput(
    { id, hasError, fieldErrorMessage, children, ...rest },
    ref
  ) {
    return (
      <>
        <label
          htmlFor={id}
          className={classNames(styles.radioLabel, {
            [styles.radioLabelError]: hasError,
            [styles.radioLabelDisabled]: rest?.disabled,
            [styles.radioLabelReadOnly]: rest?.readOnly
          })}
        >
          <input
            ref={ref}
            id={id}
            type="radio"
            {...rest}
            className={classNames(styles.radioInput, {
              [styles.radioInputError]: hasError
            })}
          />
          {children}
        </label>
        {hasError && fieldErrorMessage && (
          <p className={styles.fieldErrorMessage}>{fieldErrorMessage}</p>
        )}
      </>
    );
  }
);

export default RadioInput;

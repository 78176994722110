import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './DollarSignSVG.module.scss';

interface DollarSignSVG extends React.SVGProps<SVGSVGElement> {
  color: Colors;
}

const DollarSignSVG: React.FC<DollarSignSVG> = ({ color, ...rest }) => {
  const fillColor = classNames(
    styles.dollarSignSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      {...rest}
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillColor}
        d="M5.79995 7.9C3.52995 7.31 2.79995 6.7 2.79995 5.75C2.79995 4.66 3.80995 3.9 5.49995 3.9C7.27995 3.9 7.93995 4.75 7.99995 6H10.2099C10.1399 4.28 9.08995 2.7 6.99995 2.19V0H3.99995V2.16C2.05995 2.58 0.499946 3.84 0.499946 5.77C0.499946 8.08 2.40995 9.23 5.19995 9.9C7.69995 10.5 8.19995 11.38 8.19995 12.31C8.19995 13 7.70995 14.1 5.49995 14.1C3.43995 14.1 2.62995 13.18 2.51995 12H0.319946C0.439946 14.19 2.07995 15.42 3.99995 15.83V18H6.99995V15.85C8.94995 15.48 10.4999 14.35 10.4999 12.3C10.4999 9.46 8.06995 8.49 5.79995 7.9Z"
        fill="#323737"
      />
    </svg>
  );
};

export default DollarSignSVG;

import React from 'react';
import {
  AlignItems,
  MobileColumnSpans,
  TabletColumnSpans,
  DesktopColumnSpans
} from '@src/types/grid';
import styles from './Column.module.scss';

interface Column extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * xs screen size column span
   */
  xs?: MobileColumnSpans;
  /**
   * sm screen size column span
   */
  sm?: MobileColumnSpans;
  /**
   * md screen size column span
   */
  md?: TabletColumnSpans;
  /**
   * lg screen size column span
   */
  lg?: TabletColumnSpans;
  /**
   * xl screen size column span
   */
  xl?: DesktopColumnSpans;
  /**
   * xxl screen size column span
   */
  xxl?: DesktopColumnSpans;
  /**
   * xs screen size column span
   */
  offsetXs?: MobileColumnSpans;
  /**
   * sm screen size column offset
   */
  offsetSm?: MobileColumnSpans;
  /**
   * md screen size column offset
   */
  offsetMd?: TabletColumnSpans;
  /**
   * lg screen size column offset
   */
  offsetLg?: DesktopColumnSpans;
  /**
   * xl screen size column offset
   */
  offsetXl?: DesktopColumnSpans;
  /**
   * xxl screen size column offset
   */
  offsetXxl?: DesktopColumnSpans;
  /**
   * xs screen size visibility
   */
  showXs?: boolean;
  /**
   * sm screen size visibility
   */
  showSm?: boolean;
  /**
   * md screen size visibility
   */
  showMd?: boolean;
  /**
   * lg screen size visibility
   */
  showLg?: boolean;
  /**
   * xl screen size visibility
   */
  showXl?: boolean;
  /**
   * xxl screen size visibility
   */
  showXxl?: boolean;
  /**
   * horizontal align - will apply to all breakpoints that didn't get a more specific justify<breakpoint> value
   */
  align?: AlignItems;
  /**
   * xs screen size horizontal align
   */
  alignXs?: AlignItems;
  /**
   * sm screen size horizontal align
   */
  alignSm?: AlignItems;
  /**
   * md screen size horizontal align
   */
  alignMd?: AlignItems;
  /**
   * lg screen size horizontal align
   */
  alignLg?: AlignItems;
  /**
   * xl screen size horizontal align
   */
  alignXl?: AlignItems;
  /**
   * xxl screen size horizontal align
   */
  alignXxl?: AlignItems;
  /**
   * vertical align - will apply to all breakpoints that didn't get a more specific justify<breakpoint> value
   */
  justify?: AlignItems;
  /**
   * xs screen size vertical align
   */
  justifyXs?: AlignItems;
  /**
   * sm screen size vertical align
   */
  justifySm?: AlignItems;
  /**
   * md screen size vertical align
   */
  justifyMd?: AlignItems;
  /**
   * lg screen size vertical align
   */
  justifyLg?: AlignItems;
  /**
   * xl screen size vertical align
   */
  justifyXl?: AlignItems;
  /**
   * xxl screen size vertical align
   */
  justifyXxl?: AlignItems;
}

const Column: React.FC<Column> = ({
  xs = 4,
  sm,
  md = 8,
  lg,
  xl = 12,
  xxl,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  offsetXxl,
  showXs,
  showSm,
  showMd,
  showLg,
  showXl,
  showXxl,
  align,
  alignXs,
  alignSm,
  alignMd,
  alignLg,
  alignXl,
  alignXxl,
  justify,
  justifyXs,
  justifySm,
  justifyMd,
  justifyLg,
  justifyXl,
  justifyXxl,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={styles.column}
      data-xs={xs}
      data-sm={sm}
      data-md={md}
      data-lg={lg}
      data-xl={xl}
      data-xxl={xxl}
      data-offset-xs={offsetXs}
      data-offset-sm={offsetSm}
      data-offset-md={offsetMd}
      data-offset-lg={offsetLg}
      data-offset-xl={offsetXl}
      data-offset-xxl={offsetXxl}
      data-show-xs={showXs}
      data-show-sm={showSm}
      data-show-md={showMd}
      data-show-lg={showLg}
      data-show-xl={showXl}
      data-show-xxl={showXxl || align}
      data-align-xs={alignXs || align}
      data-align-sm={alignSm || align}
      data-align-md={alignMd || align}
      data-align-lg={alignLg || align}
      data-align-xl={alignXl || align}
      data-align-xxl={alignXxl}
      data-justify-xs={justifyXs || justify}
      data-justify-sm={justifySm || justify}
      data-justify-md={justifyMd || justify}
      data-justify-lg={justifyLg || justify}
      data-justify-xl={justifyXl || justify}
      data-justify-xxl={justifyXxl || justify}
    >
      {children}
    </div>
  );
};

export default Column;

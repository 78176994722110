import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.scss';

interface VideoPlayer {
  source: string;
}

const VideoPlayer: React.FC<VideoPlayer> = ({ source }) => {
  return (
    <div className={styles.videoPlayer}>
      <ReactPlayer
        url={source}
        width="100%"
        height="100%"
        controls
        playsinline
        playing
      />
    </div>
  );
};

export default VideoPlayer;

import React from 'react';
import classNames from 'classnames';
import styles from './TextAreaInput.module.scss';

interface TextAreaInput
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'ref'> {
  /**
   * make ID required
   */
  id: string;
  /**
   * The label for the input
   */
  label?: string;
  /**
   * The form field assistive / helper text
   */
  assistiveText?: string;
  /**
   * The form field is in an error state
   */
  hasError?: boolean;
  /**
   * The form field error text
   */
  fieldErrorMessage?: string;
}

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInput>(
  function TextAreaInput(
    {
      id,
      label,
      assistiveText,
      hasError,
      fieldErrorMessage,
      placeholder,
      ...rest
    },
    ref
  ) {
    return (
      <div className={styles.textAreaInput}>
        <label
          className={classNames(styles.label, {
            [styles.labelError]: hasError
          })}
          htmlFor={id}
        >
          {label}
        </label>
        <textarea
          id={id}
          ref={ref}
          className={classNames(styles.textarea, {
            [styles.textareaError]: hasError,
            [styles.textareaReadOnly]: rest.readOnly
          })}
          placeholder={placeholder}
          {...rest}
        />

        {assistiveText && !hasError && (
          <p className={styles.assistiveText}>{assistiveText}</p>
        )}

        {hasError && fieldErrorMessage && (
          <p className={styles.fieldErrorMessage}>{fieldErrorMessage}</p>
        )}
      </div>
    );
  }
);

export default TextAreaInput;

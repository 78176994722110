import Icon from '@src/elements/Icon';
import { ButtonIconType } from '@src/types/buttons';
import { COLOR_SLATE } from '@src/types/colors';
import { getButtonIconClasses } from '@src/utils/buttonClasses';
import classNames from 'classnames';
import React from 'react';
import styles from './ButtonIcon.module.scss';

const ButtonIcon: React.FC<ButtonIconType> = ({
  icon,
  color = COLOR_SLATE,
  colorVariant = 'dark',
  fillVariant = 'filled',
  size = 'md',
  isIconBouncing,
  isBorderless = false,
  ...rest
}) => {
  const buttonStyle = classNames(
    getButtonIconClasses(
      color,
      size,
      colorVariant,
      fillVariant === 'outline',
      styles
    )
  );

  return (
    <button
      type="button"
      className={classNames(buttonStyle, styles.buttonContainer, {
        [styles.borderless]: fillVariant === 'outline' ? isBorderless : false,
        [styles.outlineHover]: fillVariant === 'outline',
        [styles.disabled]: rest?.disabled
      })}
      {...rest}
    >
      <div
        className={classNames({
          [styles.bounceIcon]: isIconBouncing
        })}
      >
        <Icon icon={icon} />
      </div>
    </button>
  );
};

export default ButtonIcon;

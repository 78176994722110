import React from 'react';
import { Container } from '@src/elements/Grid';
import ChevronRightSVG from '@src/svgs/ChevronRightSVG';
import { COLOR_WHITE } from '@src/types/colors';
import styles from './Breadcrumbs.module.scss';
import LinkWrapper from '@src/components/LinkWrapper';
import ButtonIcon from '@src/components/Button/ButtonIcon';
import { SVG_ARROW_UP } from '@src/types/svg';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';

export type Breadcrumb = {
  title: string;
  slug: string;
  urlPath: string;
  id: string;
  __typename: string;
  theme?: string;
  isLiveChatEnabled?: boolean;
};

interface Breadcrumbs {
  items?: Breadcrumb[];
}

const Breadcrumbs: React.FC<Breadcrumbs> = ({ items }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // only render `Levande` top level link on NATIONAL_STANDARD pages
  // community pages render community name as top level item
  const isNationalPage = items?.some(
    (item) =>
      item.__typename === CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD
  );

  return (
    <div className={styles.breadcrumbs}>
      <Container>
        <div className={styles.breadcrumbsContent}>
          <ul className={styles.breadcrumbList} data-testid="breadcrumbs">
            {isNationalPage && (
              <li>
                <LinkWrapper href="/" hoverUnderline data-testid="homecrumb">
                  Levande
                </LinkWrapper>

                <ChevronRightSVG color={COLOR_WHITE} />
              </li>
            )}
            {items?.map((item, index) => (
              <li key={item?.id}>
                <LinkWrapper
                  href={item.urlPath}
                  hoverUnderline
                  isActive={index === items.length - 1}
                  data-testid="breadcrumbsItems"
                >
                  {item.title}
                </LinkWrapper>
                {index < items.length - 1 && (
                  <span data-testid="breadcrumbSeparator">
                    <ChevronRightSVG color={COLOR_WHITE} />
                  </span>
                )}
              </li>
            ))}
          </ul>

          <ButtonIcon
            size="md"
            fillVariant="outline"
            isBorderless
            color={COLOR_WHITE}
            icon={SVG_ARROW_UP}
            aria-label="Scroll to top"
            onClick={scrollToTop}
            data-testid="scrollUpBtn"
          />
        </div>
      </Container>
    </div>
  );
};

export default Breadcrumbs;

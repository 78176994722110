import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './FacebookSVG.module.scss';

interface FacebookSVG {
  color: Colors;
}

const FacebookSVG: React.FC<FacebookSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.facebookSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 44 80"
      width="50"
      height="50"
    >
      <path
        className={fillColor}
        d="M44 0H32a20 20 0 00-20 20v12H0v16h12v32h16V48h12l4-16H28V20a4 4 0 014-4h12V0z"
      ></path>
    </svg>
  );
};

export default FacebookSVG;

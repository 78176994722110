export const TEXT_H1_HERO = 'h1-hero';
export const TEXT_H1 = 'h1';
export const TEXT_H2 = 'h2';
export const TEXT_H3 = 'h3';
export const TEXT_H4 = 'h4';

export const TEXT_LARGE_REGULAR = 'textLargeRegular';
export const TEXT_LARGE_SEMIBOLD = 'textLargeSemibold';
export const TEXT_LARGE_BOLD = 'textLargeBold';

export const TEXT_PARAGRAPH_REGULAR = 'textParagraphRegular';
export const TEXT_PARAGRAPH_SEMIBOLD = 'textParagraphSemibold';
export const TEXT_PARAGRAPH_BOLD = 'textParagraphBold';

export const TEXT_SMALL_REGULAR = 'textSmallRegular';
export const TEXT_SMALL_SEMIBOLD = 'textSmallSemibold';
export const TEXT_SMALL_BOLD = 'textSmallBold';

export const TEXT_SMALLEST_REGULAR = 'textSmallestRegular';
export const TEXT_SMALLEST_SEMIBOLD = 'textSmallestSemibold';
export const TEXT_SMALLEST_BOLD = 'textSmallestBold';

export type TextH1Hero = typeof TEXT_H1_HERO;
export type TextH1 = typeof TEXT_H1;
export type TextH2 = typeof TEXT_H2;
export type TextH3 = typeof TEXT_H3;
export type TextH4 = typeof TEXT_H4;

export type TextLargeRegular = typeof TEXT_LARGE_REGULAR;
export type TextLargeSemibold = typeof TEXT_LARGE_SEMIBOLD;
export type TextLargeBold = typeof TEXT_LARGE_BOLD;

export type TextParagraphRegular = typeof TEXT_PARAGRAPH_REGULAR;
export type TextParagraphSemibold = typeof TEXT_PARAGRAPH_SEMIBOLD;
export type TextParagraphBold = typeof TEXT_PARAGRAPH_BOLD;

export type TextSmallRegular = typeof TEXT_SMALL_REGULAR;
export type TextSmallSemibold = typeof TEXT_SMALL_SEMIBOLD;
export type TextSmallBold = typeof TEXT_SMALL_BOLD;

export type TextSmallestRegular = typeof TEXT_SMALLEST_REGULAR;
export type TextSmallestSemibold = typeof TEXT_SMALLEST_SEMIBOLD;
export type TextSmallestBold = typeof TEXT_SMALLEST_BOLD;

export type HeadingFontStyle = TextH1Hero | TextH1 | TextH2 | TextH3 | TextH4;

export type HeadingFontSpacer =
  | TextH1
  | TextH2
  | TextH3
  | TextH4
  | TextParagraphRegular;

export type BodyCopyVariant =
  | 'largeRegular'
  | 'largeSemibold'
  | 'largeBold'
  | 'paragraphRegular'
  | 'paragraphSemibold'
  | 'paragraphBold'
  | 'smallRegular'
  | 'smallSemibold'
  | 'smallBold'
  | 'smallestRegular'
  | 'smallestSemibold'
  | 'smallestBold';

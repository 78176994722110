import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './QuestionAnswerSVG.module.scss';

interface QuestionAnswerSVG {
  color: Colors;
}

const QuestionAnswerSVG: React.FC<QuestionAnswerSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.questionAnswerSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        className={fillColor}
        d="M19 4h-2v9H4v2c0 .55.45 1 1 1h11l4 4V5c0-.55-.45-1-1-1zm-4 6V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v14l4-4h10c.55 0 1-.45 1-1z"
      ></path>
    </svg>
  );
};

export default QuestionAnswerSVG;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors, COLOR_SLATE } from '@src/types/colors';
import styles from './LoadingSVG.module.scss';

interface LoadingSVG {
  color?: Colors;
}

const LoadingSVG: React.FC<LoadingSVG> = ({ color = COLOR_SLATE }) => {
  const stopColor = classNames(
    styles.loadingSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <defs>
        <clipPath id="cut-off">
          <rect x="0" y="50" width="100%" height="100%" />
        </clipPath>
        <linearGradient id="gradient">
          <stop offset="0" className={stopColor}></stop>
          <stop offset="90%" className={stopColor} stopOpacity="0"></stop>
        </linearGradient>
      </defs>

      <circle
        cx="50"
        cy="50"
        r="38"
        stroke="url(#gradient)"
        strokeWidth="15"
        fill="none"
        clipPath="url(#cut-off)"
      ></circle>
    </svg>
  );
};

export default LoadingSVG;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ChevronUpSVG.module.scss';

interface ChevronUpSVG {
  color: Colors;
}

const ChevronUpSVG: React.FC<ChevronUpSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.chevronUpSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 30">
      <path
        className={fillColor}
        d="M5.64 29.64L24 11.32l18.36 18.32L48 24 24 0 0 24l5.64 5.64z"
      ></path>
    </svg>
  );
};

export default ChevronUpSVG;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ChevronLeftSVG.module.scss';

interface ChevronLeftSVG {
  color: Colors;
}

const ChevronLeftSVG: React.FC<ChevronLeftSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.chevronLeftSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 48"
      width="50"
      height="50"
    >
      <path
        className={fillColor}
        d="M29.64 42.36L11.32 24 29.64 5.64 24 0 0 24l24 24 5.64-5.64z"
      ></path>
    </svg>
  );
};

export default ChevronLeftSVG;

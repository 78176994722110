import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './BusinessSVG.module.scss';

interface BusinessSVG {
  color: Colors;
}

const BusinessSVG: React.FC<BusinessSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.businessSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={fillColor}
        d="M10 4V0H0V18H20V4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM18 16H10V14H12V12H10V10H12V8H10V6H18V16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z"
      />
    </svg>
  );
};

export default BusinessSVG;

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './NumericStepper.module.scss';
import ButtonStandard from '@src/components/Button/ButtonStandard';
import {
  UseFormGetValues,
  UseFormSetFocus,
  UseFormSetValue
} from 'react-hook-form';
import { SvgIconName } from '@src/types/svg';
import Icon from '@src/elements/Icon';

interface NumericStepper
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref'> {
  /**
   * make ID required
   */
  id: string;
  /**
   * The label for the input
   */
  label?: string;
  /**
   * Icon for the field label
   */
  labelIcon?: SvgIconName;
  /**
   * Min value for the stepper
   */
  min: number;
  /**
   * Max value for the stepper
   */
  max: number;
  /**
   * React Hook Form getValues method
   */
  getValues: UseFormGetValues<any>;
  /**
   * React Hook Form setValue method
   */
  setValue: UseFormSetValue<any>;
  /**
   * React Hook Form setFocus method
   */

  setFocus: UseFormSetFocus<any>;
  /**
   * The form field is in an error state
   */
  hasError?: boolean;
  /**
   * The form field error text
   */
  fieldErrorMessage?: string;
}

const NumericStepper = React.forwardRef<HTMLInputElement, NumericStepper>(
  function NumericStepper(
    {
      id,
      label,
      labelIcon,
      min,
      max,
      getValues,
      setValue,
      setFocus,
      hasError,
      fieldErrorMessage,
      ...rest
    },
    ref
  ) {
    const [maxReached, setMaxReached] = useState(false);
    const [minReached, setMinReached] = useState(false);
    const fieldName = rest.name as string;

    // get current value, exclude '+'
    const getStepperValue = useCallback(() => {
      const fieldValue = getValues(fieldName).split('+');
      return Number(fieldValue[0]);
    }, [fieldName, getValues]);

    // set initial disabled state
    useEffect(() => {
      const stepperValue = getStepperValue();
      setMaxReached(stepperValue === max);
      setMinReached(stepperValue === min);
    }, [fieldName, getStepperValue, max, min, setFocus]);

    // update field value and disabled state
    const updateValue = ({ increment }: { increment: boolean }) => {
      const stepperValue = getStepperValue();

      if (increment && stepperValue < max) {
        setValue(fieldName, `${stepperValue + 1}+`);
        setMaxReached(stepperValue + 1 === max);
        setMinReached(false);
      }

      if (!increment && stepperValue > min) {
        setValue(fieldName, `${stepperValue - 1}+`);
        setMaxReached(false);
        setMinReached(stepperValue - 1 === min);
      }

      // wait for update to complete before setting focus
      requestAnimationFrame(() => {
        setFocus(fieldName);
      });
    };

    return (
      <div className={styles.numericStepper}>
        <label
          className={classNames(styles.label, {
            [styles.labelError]: hasError
          })}
          htmlFor={id}
        >
          {labelIcon && <Icon icon={labelIcon} />}
          {label}
        </label>

        <div className={styles.numericStepperControls}>
          {/* TODO swap with ButtonIcon when ready */}
          <ButtonStandard
            fillVariant="outline"
            colorVariant="dark"
            onClick={() => updateValue({ increment: false })}
            disabled={minReached}
          >
            &mdash;
          </ButtonStandard>

          <input
            id={id}
            ref={ref}
            type="text"
            readOnly
            className={classNames(styles.input, {
              [styles.inputError]: hasError,
              [styles.inputReadOnly]: rest.readOnly
            })}
            disabled={minReached || maxReached}
            {...rest}
          />

          {/* TODO swap with ButtonIcon when ready */}
          <ButtonStandard
            fillVariant="outline"
            colorVariant="dark"
            onClick={() => updateValue({ increment: true })}
            disabled={maxReached}
          >
            +
          </ButtonStandard>
        </div>

        {hasError && fieldErrorMessage && (
          <p className={styles.fieldErrorMessage}>{fieldErrorMessage}</p>
        )}
      </div>
    );
  }
);

export default NumericStepper;

import { Maybe } from '@src/graphql/gql-types';
import React from 'react';
import LinkWrapper from '../LinkWrapper';
import styles from './HeadOfficePhoneNumber.module.scss';

interface HeadOfficePhoneNumber {
  preText?: Maybe<string>;
  headOfficePhoneNumber?: Maybe<string>;
}

const HeadOfficePhoneNumber: React.FC<HeadOfficePhoneNumber> = ({
  preText,
  headOfficePhoneNumber
}) => {
  return (
    <p className={styles.headOfficePhoneNumber}>
      {`${preText} `}
      <LinkWrapper href={`tel://${headOfficePhoneNumber}`}>
        {headOfficePhoneNumber}
      </LinkWrapper>
    </p>
  );
};

export default HeadOfficePhoneNumber;

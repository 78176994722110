import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { HyperLink } from '@src/types/contentful';
import { ButtonIcon } from '@src/components/Button';
import SiteSearch from '@src/components/Header/SiteSearch';
import MobileMenuAccordion from './MobileMenuAccordion';
import styles from './MobileMenu.module.scss';
import { SVG_CLOSE } from '@src/types/svg';
import { COLOR_WHITE } from '@src/types/colors';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import { isCommunityPage } from '@src/utils/helpers';
import {
  PageCommunityStandard,
  PageCommunityDetail,
  PageCommunityPropertyDetail,
  PageCommunityNews,
  GlobalSettingItemFragment
} from '@src/graphql/gql-types';
import EnquireButton from '@src/components/Header/EnquireButton';
import { useWindow } from '@src/hooks/useWindow';

export interface MobileMenu {
  menuLinks: HyperLink[];
  mobileMenuState: {
    open: boolean;
    focusSearch: boolean;
  };
  setMobileMenuState: Dispatch<
    SetStateAction<{ open: boolean; focusSearch: boolean }>
  >;
  pageCommunityData?: Partial<
    | PageCommunityStandard
    | PageCommunityDetail
    | PageCommunityPropertyDetail
    | PageCommunityNews
  >;
  dynamicFormValues?: NationalEnquiryDynamicFormValues;
  formDisclaimerRichText?: GlobalSettingItemFragment;
}

const MobileMenu: React.FC<MobileMenu> = ({
  menuLinks,
  mobileMenuState,
  setMobileMenuState,
  pageCommunityData,
  dynamicFormValues,
  formDisclaimerRichText
}) => {
  const { document } = useWindow();
  const isCommunity = isCommunityPage(pageCommunityData);

  const handleCloseMobileMenu = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      // prevent accordion expanding/collapsing when clicking the AccordionItemButton link
      event.stopPropagation();
      setMobileMenuState(() => ({
        open: false,
        focusSearch: false
      }));
    },
    [setMobileMenuState]
  );

  useEffect(() => {
    if (document) {
      if (mobileMenuState.open) {
        (
          document.getElementsByTagName('body')[0] as HTMLBodyElement
        ).style.overflow = 'hidden';
      } else {
        (
          document.getElementsByTagName('body')[0] as HTMLBodyElement
        ).style.overflow = 'auto';
      }
    }
  }, [document, mobileMenuState]);

  return (
    <>
      <div
        onClick={handleCloseMobileMenu}
        className={classNames(styles.mobileMenuMask, {
          [styles.mobileMenuMaskShow]: mobileMenuState.open
        })}
        data-testid="mobileMenu"
      />
      <div
        className={classNames(styles.mobileMenu, {
          [styles.mobileMenuOpen]: mobileMenuState.open
        })}
      >
        <div className={styles.mobileMenuInner}>
          <div className={styles.mobileMenuTopBar}>
            <ButtonIcon
              data-testid="mobileMenuCloseButton"
              size="lg"
              isBorderless
              fillVariant="outline"
              color={COLOR_WHITE}
              icon={SVG_CLOSE}
              onClick={handleCloseMobileMenu}
              aria-label="Close the menu"
            />
          </div>

          <div className={styles.mobileMenuControls}>
            <EnquireButton
              testid="mobileMenuEnquireButton"
              isCommunity={isCommunity}
              dynamicFormValues={dynamicFormValues}
              formDisclaimerRichText={formDisclaimerRichText}
              handleCloseMobileMenu={handleCloseMobileMenu}
            />
            <SiteSearch
              testid="mobileMenuSiteSearch"
              variant="light"
              focusSearch={mobileMenuState.focusSearch}
              handleCloseMobileMenu={handleCloseMobileMenu}
            />
          </div>
          <MobileMenuAccordion
            menuLinks={menuLinks}
            handleCloseMobileMenu={handleCloseMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default MobileMenu;

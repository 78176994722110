import {
  CONTENTFUL_PAGE_CONTENT_TYPES,
  CONTENTFUL_TYPENAME
} from '@src/constants/contentful';
import {
  PageCommunityDetailHyperlinkFragment,
  PageCommunityNewsHyperlinkFragment,
  PageCommunityStandardHyperlinkFragment,
  PageNationalNewsHyperlinkFragment,
  PageNationalStandardHyperlinkFragment,
  SysFragment,
  Maybe,
  MediaAssetFragment,
  MediaImageFragment,
  MediaVideoFragment,
  PageCommunityDetailMetadataFragment,
  PageCommunityNewsMetadataFragment,
  PageCommunityStandardMetadataFragment,
  PageNationalStandardMetadataFragment,
  PageNationalNewsMetadataFragment,
  PageCommunityPropertyDetailMetadataFragment,
  PageCommunityDetailFragment,
  PageSharedNewsHyperlinkFragment,
  PageSharedNewsMetadataFragment,
  GalleryFragment,
  MatterportBlockFragment,
  PageCommunityEventHyperlinkFragment,
  PageCommunityEventMetadataFragment
} from '@src/graphql/gql-types';

export interface HyperLink {
  sys?: Maybe<SysFragment>;
  title?: Maybe<string>;
  urlPath?: Maybe<string>;
  openNewWindow?: Maybe<boolean>;
  subNav?: Maybe<HyperLink[]>;
}

/**
 * Contentful page content-types
 */
export type ContentfulPageTypes =
  (typeof CONTENTFUL_PAGE_CONTENT_TYPES)[keyof typeof CONTENTFUL_PAGE_CONTENT_TYPES];

export type PageMetadataFragment =
  | PageCommunityDetailMetadataFragment
  | PageCommunityNewsMetadataFragment
  | PageCommunityEventMetadataFragment
  | PageCommunityStandardMetadataFragment
  | PageCommunityPropertyDetailMetadataFragment
  | PageNationalStandardMetadataFragment
  | PageNationalNewsMetadataFragment
  | PageSharedNewsMetadataFragment;

export type ContentfulPageHyperlinkFragments =
  | PageNationalStandardHyperlinkFragment
  | PageNationalNewsHyperlinkFragment
  | PageCommunityDetailHyperlinkFragment
  | PageCommunityStandardHyperlinkFragment
  | PageCommunityNewsHyperlinkFragment
  | PageCommunityEventHyperlinkFragment
  | PageSharedNewsHyperlinkFragment;

/**
 * Content Types
 */

export type ContentfulTypename = CONTENTFUL_TYPENAME;

/**
 * Contentful Media Types
 */

export type MediaFragment =
  | MediaVideoFragment
  | MediaImageFragment
  | MediaAssetFragment;

export const isMediaVideo = (
  media: MediaFragment
): media is MediaVideoFragment =>
  (media as MediaVideoFragment).__typename === CONTENTFUL_TYPENAME.MEDIA_VIDEO;

export const isMediaImage = (
  media: MediaFragment
): media is MediaImageFragment =>
  (media as MediaImageFragment).__typename === CONTENTFUL_TYPENAME.MEDIA_IMAGE;

export const isMediaAsset = (
  media: MediaFragment
): media is MediaAssetFragment =>
  (media as MediaAssetFragment).__typename === CONTENTFUL_TYPENAME.MEDIA_ASSET;

export const isGallery = (media: GalleryFragment): media is GalleryFragment =>
  (media as GalleryFragment).__typename === CONTENTFUL_TYPENAME.GALLERY;

export type ImageSource = {
  url?: Maybe<string>;
  description?: Maybe<string>;
};

/**
 * Contentful Rich Text Types
 */
export interface Entry {
  sys: SysFragment;
  fields: EntryFields;
}

/**
 * Rich Text Entry Components
 */

export type EntryFields =
  | MediaImageFragment
  | MediaVideoFragment
  | MediaAssetFragment
  | GalleryFragment
  | PageMetadataFragment
  | MatterportBlockFragment;

export const isMediaImageEntry = (
  entry: EntryFields
): entry is MediaImageFragment =>
  (entry as MediaImageFragment).__typename === CONTENTFUL_TYPENAME.MEDIA_IMAGE;

export const isMediaVideoEntry = (
  entry: EntryFields
): entry is MediaVideoFragment =>
  (entry as MediaVideoFragment).__typename === CONTENTFUL_TYPENAME.MEDIA_VIDEO;

export const isMediaAssetEntry = (
  entry: EntryFields
): entry is MediaAssetFragment =>
  (entry as MediaAssetFragment).__typename === CONTENTFUL_TYPENAME.MEDIA_ASSET;

export const isGalleryEntry = (entry: EntryFields): entry is GalleryFragment =>
  (entry as GalleryFragment).__typename === CONTENTFUL_TYPENAME.GALLERY;

export const isPageMetadataEntry = (
  entry: EntryFields
): entry is PageMetadataFragment => {
  const pageMetadataEntry = entry as PageMetadataFragment;
  return (
    pageMetadataEntry.__typename === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY ||
    pageMetadataEntry.__typename ===
      CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_NEWS ||
    pageMetadataEntry.__typename ===
      CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_STANDARD ||
    pageMetadataEntry.__typename ===
      CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_PROPERTY_DETAIL ||
    pageMetadataEntry.__typename ===
      CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_NEWS ||
    pageMetadataEntry.__typename ===
      CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD
  );
};

export const isMatterportBlockEntry = (
  entry: EntryFields
): entry is MatterportBlockFragment =>
  (entry as MatterportBlockFragment).__typename ===
  CONTENTFUL_TYPENAME.MATTERPORT_BLOCK;

export type EnquiryFormValuesFragment = PageCommunityDetailFragment;

export type ContentfulFieldsObject = {
  fields: Record<string, { 'en-US': string }>;
};

import React, { useEffect } from 'react';
import { COLOR_WHITE } from '@src/types/colors';
import styles from './Footer.module.scss';
import { Column, Container, Row } from '@src/elements/Grid';
import { NavLogo } from '@src/components/Nav';
import Breadcrumbs from '@src/components/Nav/Breadcrumbs';
import { getLinkData } from '@src/utils/url';
import LinkWrapper from '@src/components/LinkWrapper';
import { Maybe, NavElFragment, NavSubElFragment } from '@src/graphql/gql-types';
import { Breadcrumb } from '@src/components/Nav/Breadcrumbs/Breadcrumbs';
import { getSocialLinksWithIcon } from '@src/utils/helpers';
import { ButtonLinkIcon, ButtonLinkStandard } from '../ButtonLink';
import Script from 'next/script';
import { useWindow } from '@src/hooks/useWindow';

export type FooterMenu = {
  firstColumn?: Maybe<NavElFragment & NavSubElFragment>[];
  secondColumn?: Maybe<NavElFragment>[];
  socialLinks?: Maybe<NavElFragment>[];
  legalLinks?: Maybe<NavElFragment>[];
  breadcrumbs?: Breadcrumb[];
};

interface Footer {
  footerMenu?: FooterMenu;
  showBreadCrumbs?: boolean;
  showLiveChat?: boolean;
}

const Footer: React.FC<Footer> = ({
  footerMenu,
  showBreadCrumbs,
  showLiveChat = false
}) => {
  const firstColumnLinks = getLinkData(footerMenu?.firstColumn, true) || [];
  const secondColumnLinks = getLinkData(footerMenu?.secondColumn, true) || [];
  const legalLinks = getLinkData(footerMenu?.legalLinks, true) || [];
  const socialLinks = getSocialLinksWithIcon(footerMenu?.socialLinks) || [];
  const { document } = useWindow();

  const chatMinimized = document?.getElementById('designstudio-minimize');
  const chatButton = document?.getElementById('designstudio-button');
  const existingScript = document?.getElementById('chat_js_lib');

  // Visually force hide the DOM nodes that house the live chat if not enabled
  useEffect(() => {
    if (!showLiveChat) {
      if (chatMinimized) {
        chatMinimized.style.display = 'none';
      }
      if (chatButton) {
        chatButton.style.display = 'none';
      }
    } else {
      if (chatMinimized) {
        chatMinimized.style.display = 'block';
      }
      if (chatButton) {
        chatButton.style.display = 'block';
      }
    }
  }, [chatButton, chatMinimized, existingScript, showLiveChat]);

  return (
    <div className={styles.footer} data-testid="footer">
      {showLiveChat && (
        <div data-key="0rQcG1xtlYP4kBsrTUKU" id="chat_js_lib">
          <Script
            strategy="afterInteractive"
            type="text/javascript"
            src="https://www.widgetinstall.com/plugin/chat.js"
            data-key="0rQcG1xtlYP4kBsrTUKU"
            id="chat_js_lib"
          />
        </div>
      )}

      {showBreadCrumbs && <Breadcrumbs items={footerMenu?.breadcrumbs} />}

      <div className={styles.footerContent}>
        <Container>
          <Row>
            <Column xs={4} md={8} lg={8} xl={4}>
              <div className={styles.footerLogoWrapper}>
                <div className={styles.footerLogo} data-testid="footerLogo">
                  <p>It&lsquo;s all in the name</p>
                  <NavLogo color={COLOR_WHITE} />
                  <p>Welcome to a new definition of living.</p>
                </div>

                <ButtonLinkStandard
                  data-testid="footerContactButtonLink"
                  href="/contact-us/"
                  color={COLOR_WHITE}
                  fillVariant="outline"
                >
                  Contact Levande
                </ButtonLinkStandard>

                <div
                  className={styles.footerMenuSocial}
                  data-testid="socialMenu"
                >
                  {socialLinks.map((item) => (
                    <ButtonLinkIcon
                      key={item?.sys?.id}
                      href={item?.urlPath as string}
                      openNewWindow={!!item?.openNewWindow}
                      size="xl"
                      fillVariant="outline"
                      isBorderless
                      icon={item?.icon}
                      color={COLOR_WHITE}
                      aria-label={item?.title as string}
                    />
                  ))}
                </div>
              </div>
            </Column>

            {firstColumnLinks.length > 0 && (
              <Column xs={4} md={4} lg={4} xl={4}>
                <div
                  className={styles.footerMenuFirstColumn}
                  data-testid="firstColumn"
                >
                  <p>In this section</p>
                  <ul>
                    {firstColumnLinks?.map((item) => (
                      <li key={item.sys?.id}>
                        <LinkWrapper
                          href={item.urlPath as string}
                          hoverUnderline
                          openNewWindow={!!item.openNewWindow}
                        >
                          {item.title}
                        </LinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              </Column>
            )}

            {secondColumnLinks.length > 0 && (
              <Column xs={4} md={4} lg={4} xl={4}>
                <div
                  className={styles.footerMenuSecondColumn}
                  data-testid="secondColumn"
                >
                  <p>Our Business</p>
                  <ul>
                    {secondColumnLinks?.map((item) => (
                      <li key={item.sys?.id}>
                        <LinkWrapper
                          href={item.urlPath as string}
                          hoverUnderline
                          openNewWindow={!!item.openNewWindow}
                        >
                          {item.title}
                        </LinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              </Column>
            )}
          </Row>
        </Container>
      </div>
      <div className={styles.footerBottomBar} data-testid="bottomBar">
        <Container>
          <Row directionXs="row">
            <Column xs={4} md={6} xl={6}>
              <ul>
                {legalLinks.map((item) => (
                  <li key={item?.sys?.id}>
                    <LinkWrapper
                      href={item.urlPath as string}
                      hoverUnderline
                      openNewWindow={!!item.openNewWindow}
                    >
                      {item.title}
                    </LinkWrapper>
                  </li>
                ))}
              </ul>
            </Column>
            <Column xs={4} md={2} xl={6} alignXl="flex-end">
              <p>&copy; Levande {new Date().getFullYear()}</p>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;

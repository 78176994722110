import {
  AccordionBlockRtFragment,
  AccordionBlockRtLinksFragment,
  AccordionItemRtFragment,
  AccordionItemRtLinksFragment,
  AlternatingGalleryBlockRtFragment,
  AlternatingGalleryBlockRtLinksFragment,
  AlternatingTextBlockRtFragment,
  AlternatingTextBlockRtLinksFragment,
  GenericTextBlockRtFragment,
  GenericTextBlockRtLinksFragment,
  GlobalSettingsRtFragment,
  GlobalSettingsRtLinksFragment,
  PageCommunityPropertyDetailRtFragment,
  PageCommunityPropertyDetailRtLinksFragment,
  TwoColumnLeftBlockRtFragment,
  TwoColumnRightBlockRtFragment,
  TwoColumnLeftBlockRtLinksFragment,
  TwoColumnRightBlockRtLinksFragment,
  SectionTitleBlockRtFragment,
  SectionTitleBlockRtLinksFragment,
  SalesBannerBlockRtFragment,
  SalesBannerBlockDisclaimerTextRtFragment,
  SalesBannerBlockRtLinksFragment,
  SalesBannerBlockDisclaimerTextRtLinksFragment,
  PageNationalNewsRtlFragment,
  PageNationalNewsRtlLinksFragment,
  PageCommunityNewsRtlFragment,
  PageCommunityNewsRtlLinksFragment,
  PageCommunityEventRtlFragment,
  PageCommunityEventRtlLinksFragment
} from '@src/graphql/gql-types';

export enum ENTRY_TYPES {
  ENTRY_HYPERLINK = 'entry-hyperlink',
  HYPERLINK = 'hyperlink',
  BLOCK = 'block',
  ASSET_HYPERLINK = 'asset-hyperlink',
  EMBEDDED_ENTRY_INLINE = 'embedded-entry-inline'
}

export enum RICHTEXT_RENDER_MODE {
  NO_BLOCK_EMBEDS = 'no-blocks-embedded',
  ALLOW_BLOCK_EMBEDS = 'allow-blocks-embedded'
}

export type TagName = keyof Omit<
  HTMLElementTagNameMap,
  'applet' | 'dir' | 'font' | 'frame' | 'frameset' | 'marquee'
>;

export type RichTextBodyContent =
  | AccordionBlockRtFragment
  | AccordionItemRtFragment
  | AlternatingGalleryBlockRtFragment
  | AlternatingTextBlockRtFragment
  | GenericTextBlockRtFragment
  | GlobalSettingsRtFragment
  | SectionTitleBlockRtFragment
  | PageCommunityPropertyDetailRtFragment
  | SalesBannerBlockRtFragment
  | SalesBannerBlockDisclaimerTextRtFragment
  | TwoColumnLeftBlockRtFragment
  | TwoColumnRightBlockRtFragment
  | PageNationalNewsRtlFragment
  | PageCommunityNewsRtlFragment
  | PageCommunityEventRtlFragment;

export type RichTextBodyLinks =
  | AccordionBlockRtLinksFragment
  | AccordionItemRtLinksFragment
  | AlternatingGalleryBlockRtLinksFragment
  | AlternatingTextBlockRtLinksFragment
  | GenericTextBlockRtLinksFragment
  | GlobalSettingsRtLinksFragment
  | SectionTitleBlockRtLinksFragment
  | PageCommunityPropertyDetailRtLinksFragment
  | SalesBannerBlockRtLinksFragment
  | SalesBannerBlockDisclaimerTextRtLinksFragment
  | TwoColumnLeftBlockRtLinksFragment
  | TwoColumnRightBlockRtLinksFragment
  | PageNationalNewsRtlLinksFragment
  | PageCommunityNewsRtlLinksFragment
  | PageCommunityEventRtlLinksFragment;

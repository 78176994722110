import React from 'react';
import classNames from 'classnames';
import {
  HeadingFontStyle,
  TEXT_H1_HERO,
  TEXT_H1,
  TEXT_H2,
  TEXT_H3,
  TEXT_H4
} from '@src/types/typography';
import styles from './Heading.module.scss';

interface Heading extends React.HTMLProps<HTMLHeadingElement> {
  /**
   * Heading priority (1 - 4)
   */
  priority: 1 | 2 | 3 | 4 | 'none';
  /**
   * Heading font style
   */
  fontStyle: HeadingFontStyle;
}

const Heading: React.FC<Heading> = ({
  priority,
  fontStyle,
  children,
  className,
  ...props
}) => {
  const HeadingTag = priority === 'none' ? 'p' : `h${priority}`;
  const attr: React.HTMLAttributes<HTMLHeadingElement> = {
    className: classNames(className, styles.preLineWrap, {
      [styles.h1Hero]: fontStyle === TEXT_H1_HERO,
      [styles.h1]: fontStyle === TEXT_H1,
      [styles.h2]: fontStyle === TEXT_H2,
      [styles.h3]: fontStyle === TEXT_H3,
      [styles.h4]: fontStyle === TEXT_H4
    })
  };

  if (!children) {
    return null;
  }

  return (
    <HeadingTag {...attr} {...props}>
      {children}
    </HeadingTag>
  );
};

export default Heading;

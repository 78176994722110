import * as yup from 'yup';

// add debug email value as you see fit
export const SALESFORCE_DEBUG_EMAIL = '';

export const FORM_SUBMIT_DELAY_MS = 250;

export const FORM_ERROR_MESSAGES = {
  alphaChars: 'Name must only contain letters',
  fieldRequired: 'This field is required',
  charLimit: 'Please enter no more than 256 characters',
  titleRequired: 'Please select a valid title',
  invalidFirstName: 'Please enter a valid first name',
  invalidLastName: 'Please enter a valid last name',
  invalidName: 'Please enter a valid name',
  invalidEmail: 'Please enter a valid email',
  invalidPhone:
    'Please enter a 10 digit number (include area code for landlines) with no spaces',
  invalidPostcode: 'Please enter a valid postcode',
  invalidType: 'Please select an option',
  invalidState: 'Please select a state'
};

export const VALIDATION_REGEX = {
  firstName: /^[a-zA-Z-\s]+$/,
  lastName: /^[a-zA-Z-.\s]+$/,
  name: /^[a-zA-Z-.\s]+$/,
  email:
    /^[a-zA-Z0-9+'_-]+(?:\.[a-zA-Z0-9+'_-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
  phone: /^(\+?61[0-9]{9}|0[1-9][0-9]{8})$/,
  postcode: /^[0-9]{4}$/,
  address: /^[ A-Za-z0-9_@,./#&+-]*$/,
  suburb: /^([\sA-Za-z]+)$/,
  optIn: /\btrue\b/,
  enquiringDetails: /\bMyself\b|\bFor Family\b|\bFor Friends\b/
};

export const CREATE_LEAD_FORM_SCHEMA = yup.object().shape({
  title: yup.string().required(FORM_ERROR_MESSAGES.titleRequired),
  firstName: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.firstName, FORM_ERROR_MESSAGES.invalidFirstName)
    .max(40, FORM_ERROR_MESSAGES.charLimit),
  lastName: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.lastName, FORM_ERROR_MESSAGES.invalidLastName)
    .max(80, FORM_ERROR_MESSAGES.charLimit),
  emailAddress: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
    .max(80, FORM_ERROR_MESSAGES.charLimit),
  phoneNumber: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
    .max(40, FORM_ERROR_MESSAGES.charLimit),
  postCode: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.postcode, FORM_ERROR_MESSAGES.invalidPostcode),
  enquiringState: yup.string().nullable(),
  enquiringDetails: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(
      VALIDATION_REGEX.enquiringDetails,
      FORM_ERROR_MESSAGES.fieldRequired
    )
    .nullable(),
  enquiryDate: yup.string().nullable(),
  bookVisit: yup.string().nullable(),
  optIn: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.optIn, FORM_ERROR_MESSAGES.fieldRequired),
  channelOfEnquiry: yup.string().nullable(),
  websiteURL: yup.string().nullable(),
  leadSource: yup.string().nullable(),
  leadRecordType: yup.string().nullable(),
  sourceDetail: yup.string().nullable(),
  liveChatTranscript: yup.string().nullable(),
  utmCampaign: yup.string().nullable(),
  utmSource: yup.string().nullable(),
  utmMedium: yup.string().nullable(),
  utmContent: yup.string().nullable()
});
export const CREATE_EVENT_RSVP_FORM_SCHEMA = yup.object().shape({
  title: yup.string().nullable(),
  firstName: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.firstName, FORM_ERROR_MESSAGES.invalidFirstName)
    .max(40, FORM_ERROR_MESSAGES.charLimit),
  lastName: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.lastName, FORM_ERROR_MESSAGES.invalidLastName)
    .max(80, FORM_ERROR_MESSAGES.charLimit),
  emailAddress: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
    .max(80, FORM_ERROR_MESSAGES.charLimit),
  phoneNumber: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
    .max(40, FORM_ERROR_MESSAGES.charLimit),
  optIn: yup
    .string()
    .required(FORM_ERROR_MESSAGES.fieldRequired)
    .matches(VALIDATION_REGEX.optIn, FORM_ERROR_MESSAGES.fieldRequired),
  channelOfEnquiry: yup.string().nullable(),
  villagePreference: yup.string().nullable(),
  enquiringState: yup.string().nullable(),
  websiteURL: yup.string().nullable(),
  leadSource: yup.string().nullable()
});

export const COMMUNITY_CONTACT_FORM_SCHEMA = yup
  .object()
  .shape({
    salutation: yup.string().required(FORM_ERROR_MESSAGES.titleRequired),
    first_name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.firstName, FORM_ERROR_MESSAGES.invalidFirstName)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    last_name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.lastName, FORM_ERROR_MESSAGES.invalidLastName)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    email: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    phone: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    zip: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.postcode, FORM_ERROR_MESSAGES.invalidPostcode),
    '00N2800000JO1Ow': yup.array().nullable(), // enquiring for / on behalf of relationship
    '00N2800000JO1Om': yup.number().nullable(), // book a visit
    '00N2800000FSySg': yup.number().nullable(), // on behalf of relationship - hidden field
    '00N2800000JO1Oz': yup.number().nullable(), // privacy statement provided
    book_visit: yup.bool().nullable(), // book a visit - cosmetic field
    terms: yup.bool().oneOf([true], FORM_ERROR_MESSAGES.fieldRequired) // privacy statement provided - cosmetic field
  })
  .required();

export const COMMUNITY_ENQUIRY_FORM_SCHEMA = yup
  .object()
  .shape({
    salutation: yup.string().required(FORM_ERROR_MESSAGES.titleRequired),
    first_name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.firstName, FORM_ERROR_MESSAGES.invalidFirstName)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    last_name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.lastName, FORM_ERROR_MESSAGES.invalidLastName)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    email: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    phone: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    zip: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.postcode, FORM_ERROR_MESSAGES.invalidPostcode),
    '00N2800000JO1Ow': yup.array().nullable(), // enquiring for / on behalf of relationship
    '00N2800000JO1Om': yup.number().nullable(), // book a visit
    '00N2800000JO1Oz': yup.number().nullable(), // privacy statement provided
    book_visit: yup.bool().nullable(), // book a visit - cosmetic field
    terms: yup.bool().oneOf([true], FORM_ERROR_MESSAGES.fieldRequired) // privacy statement provided - cosmetic field
  })
  .required();

export const NATIONAL_CONTACT_FORM_SCHEMA = yup
  .object()
  .shape({
    '00N2y000000YMwS': yup // first name
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.firstName, FORM_ERROR_MESSAGES.invalidFirstName)
      .max(30, FORM_ERROR_MESSAGES.charLimit),
    '00N2y000000YMwT': yup // last name
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.lastName, FORM_ERROR_MESSAGES.invalidLastName)
      .max(30, FORM_ERROR_MESSAGES.charLimit),
    email: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    phone: yup // phone
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    subject: yup.string().nullable(), // subject - hidden field
    '00N0I00000K9TSa': yup.string().required(FORM_ERROR_MESSAGES.fieldRequired), // I want to* / ask a question / raise a complaint / provide feedback
    '00N0I00000K9voR': yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.postcode, FORM_ERROR_MESSAGES.invalidPostcode)
      .max(155, FORM_ERROR_MESSAGES.charLimit), // postcode
    description: yup.string().required(FORM_ERROR_MESSAGES.fieldRequired), // details / description
    '00N0I00000K9TSl': yup.number().nullable(), // privacy statement provided - hidden field
    terms: yup.bool().oneOf([true], FORM_ERROR_MESSAGES.fieldRequired) // privacy statement provided - cosmetic field
  })
  .required();

export const NATIONAL_ENQUIRY_FORM_SCHEMA = yup
  .object()
  .shape({
    salutation: yup.string().required(FORM_ERROR_MESSAGES.fieldRequired),
    first_name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.firstName, FORM_ERROR_MESSAGES.invalidFirstName)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    last_name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.lastName, FORM_ERROR_MESSAGES.invalidLastName)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    email: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    phone: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    zip: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.postcode, FORM_ERROR_MESSAGES.invalidPostcode),
    state: yup
      .string()
      .required('Please select the state you are interested in'),
    // note: communityPreference and state are coupled by field name
    communityPreference: yup.string().when('state', {
      is: (value: string) => !!value,
      then: yup.string().required('Please select your preferred community')
    }),
    '00N2800000JO1Ow': yup.array().nullable(), // enquiring for
    '00N2800000FSySg': yup.number().nullable(), // on behalf of relationship - hidden field
    '00N2800000FSyTC': yup.string().nullable(), // state interested in - hidden field
    '00N2800000JO1Oz': yup.number().nullable(), // privacy statement provided
    terms: yup.bool().oneOf([true], FORM_ERROR_MESSAGES.fieldRequired) // privacy statement provided - cosmetic field
  })
  .required();

export const CREATE_CASE_FORM_SCHEMA = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.name, FORM_ERROR_MESSAGES.invalidName)
      .max(120, FORM_ERROR_MESSAGES.charLimit),
    email: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.email, FORM_ERROR_MESSAGES.invalidEmail)
      .max(80, FORM_ERROR_MESSAGES.charLimit),
    phone: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .matches(VALIDATION_REGEX.phone, FORM_ERROR_MESSAGES.invalidPhone)
      .max(40, FORM_ERROR_MESSAGES.charLimit),
    location: yup
      .string()
      .required(FORM_ERROR_MESSAGES.fieldRequired)
      .max(255, FORM_ERROR_MESSAGES.charLimit),
    type: yup.string().required(FORM_ERROR_MESSAGES.invalidType).nullable(),
    state: yup.string().required(FORM_ERROR_MESSAGES.invalidState).nullable(),
    description: yup.string().required(FORM_ERROR_MESSAGES.fieldRequired),
    optIn: yup.bool().oneOf([true], FORM_ERROR_MESSAGES.fieldRequired) // privacy statement provided - cosmetic field
  })
  .required();

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ClockSVG.module.scss';

interface ClockSVG {
  color: Colors;
}

const ClockSVG: React.FC<ClockSVG> = ({ color }) => {
  const fillColor = classNames(styles.clockSvg, getColorClasses(color, styles));

  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.96 0C17.88 0 0 17.92 0 40C0 62.08 17.88 80 39.96 80C62.08 80 80 62.08 80 40C80 17.92 62.08 0 39.96 0ZM40 72C22.32 72 8 57.68 8 40C8 22.32 22.32 8 40 8C57.68 8 72 22.32 72 40C72 57.68 57.68 72 40 72ZM42 20H36V44L57 56.6L60 51.68L42 41V20Z"
        className={fillColor}
      />
    </svg>
  );
};

export default ClockSVG;

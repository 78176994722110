import {
  Colors,
  COLOR_BLACK,
  COLOR_WHITE,
  COLOR_SLATE,
  COLOR_LIGHT_SLATE,
  COLOR_LIME,
  COLOR_LIGHT_LIME,
  COLOR_YELLOW,
  COLOR_LIGHT_YELLOW,
  COLOR_ORANGE,
  COLOR_LIGHT_ORANGE,
  COLOR_LILAC,
  COLOR_LIGHT_LILAC,
  COLOR_BLUE,
  COLOR_LIGHT_BLUE,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_ERROR
} from '@src/types/colors';

export const getColorClasses = (
  color: Colors,
  styles: any
): { [x: number]: boolean } => ({
  [styles.colorBlack]: color === COLOR_BLACK,
  [styles.colorWhite]: color === COLOR_WHITE,
  [styles.colorPrimary]: color === COLOR_PRIMARY,
  [styles.colorSecondary]: color === COLOR_SECONDARY,
  [styles.colorSlate]: color === COLOR_SLATE,
  [styles.colorLightSlate]: color === COLOR_LIGHT_SLATE,
  [styles.colorLime]: color === COLOR_LIME,
  [styles.colorLightLime]: color === COLOR_LIGHT_LIME,
  [styles.colorYellow]: color === COLOR_YELLOW,
  [styles.colorLightYellow]: color === COLOR_LIGHT_YELLOW,
  [styles.colorOrange]: color === COLOR_ORANGE,
  [styles.colorLightOrange]: color === COLOR_LIGHT_ORANGE,
  [styles.colorLilac]: color === COLOR_LILAC,
  [styles.colorLightLilac]: color === COLOR_LIGHT_LILAC,
  [styles.colorBlue]: color === COLOR_BLUE,
  [styles.colorLightBlue]: color === COLOR_LIGHT_BLUE,
  [styles.colorError]: color === COLOR_ERROR
});

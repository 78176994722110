import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './SearchSVG.module.scss';

interface SearchSVG extends React.SVGProps<SVGSVGElement> {
  color: Colors;
}

const SearchSVG: React.FC<SearchSVG> = ({ color, ...rest }) => {
  const fillColor = classNames(
    styles.searchSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 70 70"
      {...rest}
    >
      <path
        className={fillColor}
        d="M50 44h-3.16l-1.12-1.08A25.885 25.885 0 0052 26C52 11.64 40.36 0 26 0S0 11.64 0 26s11.64 26 26 26c6.44 0 12.36-2.36 16.92-6.28L44 46.84V50l20 19.96L69.96 64 50 44zm-24 0c-9.96 0-18-8.04-18-18S16.04 8 26 8s18 8.04 18 18-8.04 18-18 18z"
      ></path>
    </svg>
  );
};

export default SearchSVG;

import { Maybe } from '@src/graphql/gql-types';

/**
 * Use this as a type predicate when filtering out null values in an array map
 *
 * Example:
  ```
  results?.filter(isDefined)
    .map((item: ResultType, index: number) => {
      // null values will be filtered, and type will be validated
    }
  };
  ```
 */
export function isDefined<T>(argument: Maybe<T> | undefined): argument is T {
  return !!argument;
}

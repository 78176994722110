import { useEffect, useState } from 'react';

/**
 * safe window object access
 * @returns object with window properties
 */
export const useWindow = () => {
  const [windowObj, setWindowObj] = useState<{
    origin?: string;
    href?: string;
    document?: Document;
  }>({});

  useEffect(() => {
    setWindowObj({
      origin: window.location.origin,
      href: window.location.href,
      document: window.document
    });
  }, []);

  return windowObj;
};

/**
 *
 * @param delayMs
 * @param callback
 * @returns resolved promise with callback executed AFTER delayMs
 */
export const promiseDelayLeading = (delayMs: number, callback?: () => void) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(
        (() => {
          callback ? callback() : null;
        })()
      );
    }, delayMs)
  );
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './LogoSVG.module.scss';

interface LogoSVG {
  color: Colors;
}

const LogoSVG: React.FC<LogoSVG> = ({ color }) => {
  const fillColor = classNames(styles.logoSvg, getColorClasses(color, styles));

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 249 56">
      <path
        className={fillColor}
        d="M35.532 0h20.775v8.61H43.979v15.125h11.544v8.51H43.979v15.126h12.328v8.61H35.532V0zM8.467 0H0v55.98h20.95v-8.53H8.468V0zm206.196 14.889v26.203c0 14.375-8.897 14.888-11.739 14.888h-13.641V0h13.641c2.842 0 11.739.513 11.739 14.889zm-8.447 1.698c0-5.766-1.372-8.057-4.821-8.057h-3.645v38.92h3.645c3.449 0 4.821-2.29 4.821-8.056V16.587zM249 8.609V0h-20.794v55.98H249v-8.609h-12.327V32.245h11.543v-8.53h-11.543V8.59H249v.02zm-82.745 23.636L155.005 0h-8.466v55.98h8.466V23.735l11.25 32.245h8.466V0h-8.466v32.245z"
      ></path>
      <path
        fill="#bee13e"
        d="M76.728.02l6.605 47.351L89.918.02h8.467L90.585 56H76.062L68.262.02h8.466zm27.752 55.96L112.28 0h14.542l7.801 55.98h-8.467l-6.605-47.35-6.604 47.35h-8.467z"
      ></path>
    </svg>
  );
};

export default LogoSVG;

import { useRouter } from 'next/router';
import React from 'react';
import styles from './PreviewBanner.module.scss';

const PreviewBanner: React.FC = () => {
  const router = useRouter();

  return (
    <div className={styles.previewBanner}>
      <div>
        <h5>PREVIEW MODE</h5>
      </div>
      <div className={styles.previewMessage}>
        This page is currently showing preview content
      </div>
      <div className={styles.previewExitButton}>
        {/* Workaround for Next.js API unable to redirect correctly via NextLink
            https://github.com/vercel/next.js/discussions/34991 */}
        <form method="GET" action={`/api/endPreviewSession`}>
          <input type="hidden" name="path" value={router.asPath} />
          <button>EXIT PREVIEW</button>
        </form>
      </div>
    </div>
  );
};

export default PreviewBanner;

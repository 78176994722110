// TODO: delete when phase2 forms are complete and in production
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

const useFormInit = (
  formType: 'web-to-case' | 'web-to-lead',
  formRef: React.MutableRefObject<HTMLFormElement | null>,
  setValue: UseFormSetValue<any>
) => {
  useEffect(() => {
    setValue('orgid', process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID_V2 as string);
    // set form action and method
    if (formRef.current) {
      formRef.current.setAttribute(
        'action',
        process.env.NEXT_PUBLIC_SALESFORCE_WEB_TO_CASE_V2_URL as string
      );

      formRef.current.setAttribute('method', 'POST');
    }
  }, [formType, formRef, setValue]);
};

export default useFormInit;

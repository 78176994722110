import React from 'react';
import classnames from 'classnames';
import { findSvgIcon } from '@src/utils/svg-icons';
import { SvgIconName } from '@src/types/svg';
import styles from './Icon.module.scss';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';

/**
 * Icon component is a wrapper component for SVG icons.
 * It primarily selects the SVG to render, and adds the color and a sizing property.
 * The color css property is set on this component
 * and child SVGs inherit the color prop using `currentColor` value in the SVG.
 * For now all SVGs have a hardcoded inverse color set in the css module of the SVG,
 * and in most cases this is white. This is currently not an issue,
 * because there are not many examples of icon inverse (hover states) that are not white.
 * The exceptions are the PlayIcon and ChevronCircle icons that have slghtly more complex inverse states.
 * If there was a need to select the inverse color then more work will be needed to add support in this component
 */
interface Icon {
  /**
   * Icon
   */
  icon?: SvgIconName;
  /**
   * The primary color of the SVG.
   * Color is optional because it can be inherited from the parent color property
   */
  color?: Colors;
  /**
   * Should icon size change responsively
   */
  fluid?: boolean;
}

const Icon: React.FC<Icon> = ({ icon, fluid = true, color, ...props }) => {
  if (!icon) {
    return null;
  }
  const iconDefinition = findSvgIcon(icon);
  const IconComponent = iconDefinition?.component;

  if (IconComponent) {
    return (
      <span
        className={classnames(
          color && getColorClasses(color, styles),
          styles.icon,
          {
            [styles.fluid]: !!fluid
          }
        )}
      >
        <IconComponent {...props} />
      </span>
    );
  }
  return null;
};

export default Icon;

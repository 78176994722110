import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import LogoSVG from '@src/svgs/LogoSVG';
import { PATHS } from '@src/constants/site';
import { Colors } from '@src/types/colors';
import classNames from 'classnames';
import styles from './NavLogo.module.scss';

interface NavLogo {
  small?: boolean;
  color: Colors;
}

const NavLogo: React.FC<NavLogo> = ({ color, small }) => {
  const router = useRouter();

  return (
    <div
      className={classNames(styles.navLogo, {
        [styles.navLogoSmall]: small
      })}
    >
      {router.asPath !== PATHS.HOME ? (
        <NextLink href={PATHS.HOME} passHref aria-label="Levande">
          <LogoSVG color={color} />
        </NextLink>
      ) : (
        <LogoSVG color={color} />
      )}
    </div>
  );
};

export default NavLogo;

export const THEME_DEFAULT = 'defaultTheme';
export const THEME_LILAC = 'lilacTheme';
export const THEME_ORANGE = 'orangeTheme';
export const THEME_BLUE = 'blueTheme';
export const THEME_SLATE = 'slateTheme';

export type ThemeDefault = typeof THEME_DEFAULT;
export type ThemeLilac = typeof THEME_LILAC;
export type ThemeOrange = typeof THEME_ORANGE;
export type ThemeBlue = typeof THEME_BLUE;
export type ThemeSlate = typeof THEME_SLATE;

export type Themes =
  | ThemeDefault
  | ThemeLilac
  | ThemeOrange
  | ThemeBlue
  | ThemeSlate;

import React from 'react';
import classNames from 'classnames';
import styles from './Container.module.scss';

interface Container extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Width of the container
   */
  containerWidth?: 'fixed' | 'fluid' | 'full';
  applyThemeBackground?: boolean;
}

const Container: React.FC<Container> = ({
  containerWidth = 'fixed',
  children,
  applyThemeBackground = false,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(styles.container, {
        [styles.fixed]: containerWidth === 'fixed',
        [styles.full]: containerWidth === 'full',
        [styles.withThemeBackground]: applyThemeBackground
      })}
    >
      {children}
    </div>
  );
};

export default Container;

import { Cloudinary } from '@cloudinary/url-gen';

export const cloudinaryClient = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
  },
  url: {
    secure: true,
    privateCdn: true,
    cname: process.env.NEXT_PUBLIC_CLOUDINARY_CNAME,
    secureDistribution: process.env.NEXT_PUBLIC_CLOUDINARY_CNAME,
    analytics: process.env.NEXT_PUBLIC_ENV === 'prod'
  }
});

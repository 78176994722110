import React from 'react';
import styles from './ReCaptchaTerms.module.scss';
import LinkWrapper from '../LinkWrapper';

const ReCaptchaTerms = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.copy}>
        This site is protected by reCAPTCHA and the Google{' '}
        <LinkWrapper
          href="https://policies.google.com/privacy"
          className={styles.link}
          openNewWindow
        >
          Privacy Policy
        </LinkWrapper>{' '}
        and{' '}
        <LinkWrapper
          href="https://policies.google.com/terms"
          className={styles.link}
          openNewWindow
        >
          Terms of Service
        </LinkWrapper>{' '}
        apply.
      </p>
    </div>
  );
};

export default ReCaptchaTerms;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './FeedbackSVG.module.scss';

interface FeedbackSVG {
  color: Colors;
}

const FeedbackSVG: React.FC<FeedbackSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.feedbackSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        className={fillColor}
        d="M18 0H2C.9 0 .01.9.01 2L0 20l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm-7 12H9v-2h2v2zm0-4H9V4h2v4z"
      ></path>
    </svg>
  );
};

export default FeedbackSVG;

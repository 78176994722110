export const COLOR_PRIMARY = 'primary';
export const COLOR_SECONDARY = 'secondary';
export const COLOR_BLACK = 'black';
export const COLOR_WHITE = 'white';
export const COLOR_SLATE = 'slate';
export const COLOR_LIGHT_SLATE = 'lightSlate';
export const COLOR_LIME = 'lime';
export const COLOR_LIGHT_LIME = 'lightLime';
export const COLOR_YELLOW = 'yellow';
export const COLOR_LIGHT_YELLOW = 'lightYellow';
export const COLOR_ORANGE = 'orange';
export const COLOR_LIGHT_ORANGE = 'lightOrange';
export const COLOR_LILAC = 'lilac';
export const COLOR_LIGHT_LILAC = 'lightLilac';
export const COLOR_BLUE = 'blue';
export const COLOR_LIGHT_BLUE = 'lightBlue';
export const COLOR_ERROR = 'errorColor';

export type ColorPrimary = typeof COLOR_PRIMARY;
export type ColorSecondary = typeof COLOR_SECONDARY;
export type ColorBlack = typeof COLOR_BLACK;
export type ColorWhite = typeof COLOR_WHITE;
export type ColorSlate = typeof COLOR_SLATE;
export type ColorLightSlate = typeof COLOR_LIGHT_SLATE;
export type ColorLime = typeof COLOR_LIME;
export type ColorLightLime = typeof COLOR_LIGHT_LIME;
export type ColorYellow = typeof COLOR_YELLOW;
export type ColorLightYellow = typeof COLOR_LIGHT_YELLOW;
export type ColorOrange = typeof COLOR_ORANGE;
export type ColorLightOrange = typeof COLOR_LIGHT_ORANGE;
export type colorLilac = typeof COLOR_LILAC;
export type colorLightLilac = typeof COLOR_LIGHT_LILAC;
export type ColorBlue = typeof COLOR_BLUE;
export type ColorLightBlue = typeof COLOR_LIGHT_BLUE;
export type ColorError = typeof COLOR_ERROR;

export type Colors =
  | ColorPrimary
  | ColorSecondary
  | ColorBlack
  | ColorWhite
  | ColorSlate
  | ColorLightSlate
  | ColorLime
  | ColorLightLime
  | ColorYellow
  | ColorLightYellow
  | ColorOrange
  | ColorLightOrange
  | colorLilac
  | colorLightLilac
  | ColorBlue
  | ColorLightBlue
  | ColorError;

export type DarkColors =
  | ColorPrimary
  | ColorSecondary
  | ColorBlack
  | ColorWhite
  | ColorSlate
  | ColorLime
  | ColorYellow
  | ColorOrange
  | colorLilac
  | ColorBlue
  | ColorError;

export type LightColors =
  | ColorLightSlate
  | ColorLightLime
  | ColorLightYellow
  | ColorLightOrange
  | colorLightLilac
  | ColorLightBlue;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './InstagramSVG.module.scss';

interface InstagramSVG {
  color: Colors;
}

const InstagramSVG: React.FC<InstagramSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.instagramSvg,
    getColorClasses(color, styles)
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <path
        className={fillColor}
        d="M32 12c-11.044 0-20 8.956-20 20v32c0 11.044 8.956 20 20 20h32c11.044 0 20-8.956 20-20V32c0-11.044-8.956-20-20-20H32zm40 8c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm-24 8c11.044 0 20 8.956 20 20s-8.956 20-20 20-20-8.956-20-20 8.956-20 20-20zm0 8a12 12 0 100 24 12 12 0 000-24z"
      ></path>
    </svg>
  );
};

export default InstagramSVG;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './PlanSVG.module.scss';

interface PlanSVG {
  color: Colors;
}

const PlanSVG: React.FC<PlanSVG> = ({ color }) => {
  const fillColor = classNames(styles.planSvg, getColorClasses(color, styles));
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m 8.5,16 c -2.190052,0 -4,1.809948 -4,4 v 16 c 0,2.190052 1.809948,4 4,4 h 24 c 2.190052,0 4,-1.809948 4,-4 V 20 c 0,-2.190052 -1.809948,-4 -4,-4 z m 0,3 h 24 c 0.579945,0 1,0.420055 1,1 v 16 c 0,0.579945 -0.420055,1 -1,1 h -24 c -0.5799453,0 -1,-0.420055 -1,-1 V 20 c 0,-0.579945 0.4200547,-1 1,-1 z"
        id="rect2"
        fill={fillColor}
      />
      <rect x="4.5" y="8" width="32" height="3" fill={fillColor} />
      <rect x="13.5" y="16" width="3" height="9" fill={fillColor} />
      <rect x="24.5" y="17" width="3" height="9" fill={fillColor} />
      <rect x="24.5" y="31" width="3" height="9" fill={fillColor} />
      <rect x="13.5" y="31" width="3" height="9" fill={fillColor} />
      <rect x="21.5" y="31" width="9" height="3" fill={fillColor} />
      <rect x="13.5" y="23" width="6" height="3" fill={fillColor} />
      <rect x="41.5" y="16" width="3" height="24" fill={fillColor} />
      <rect x="4.5" y="5" width="3" height="9" fill={fillColor} />
      <rect x="33.5" y="5" width="3" height="9" fill={fillColor} />
      <rect
        x="38.5"
        y="19"
        width="3"
        height="9"
        transform="rotate(-90 38.5 19)"
        fill={fillColor}
      />
      <rect
        x="38.5"
        y="40"
        width="3"
        height="9"
        transform="rotate(-90 38.5 40)"
        fill={fillColor}
      />
    </svg>
  );
};

export default PlanSVG;

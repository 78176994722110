import React from 'react';
import classNames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { HyperLink } from '@src/types/contentful';
import { SVG_CHEVRON_RIGHT } from '@src/types/svg';
import LinkWrapper from '@src/components/LinkWrapper';
import SubNavMenu from '@src/components/Nav/SubNavMenu';
import styles from './MobileMenuAccordion.module.scss';
import { COLOR_WHITE } from '@src/types/colors';
import ButtonIcon from '@src/components/Button/ButtonIcon';
import {
  PATHS,
  SELECT_COMMUNITY_NEWS_TAB_QUERY_PARAM
} from '@src/constants/site';

interface MobileMenuAccordion {
  menuLinks: HyperLink[];
  handleCloseMobileMenu: (event: React.MouseEvent) => void;
}

const MobileMenuAccordion: React.FC<MobileMenuAccordion> = ({
  menuLinks,
  handleCloseMobileMenu
}) => {
  return (
    <Accordion
      data-testid="mobileMenuAccordion"
      allowMultipleExpanded
      allowZeroExpanded
      className={styles.mobileMenuAccordion}
    >
      {menuLinks.map((item, index) => {
        // adds query param on to end of path for News & Events page so that the
        // page renders with the news tab open.
        const href = item?.urlPath?.includes(PATHS.COMMUNITY_NEWS_AND_EVENTS)
          ? item.urlPath + SELECT_COMMUNITY_NEWS_TAB_QUERY_PARAM
          : item.urlPath;

        return (
          <AccordionItem key={item.sys?.id}>
            <AccordionItemHeading>
              <AccordionItemButton
                className={classNames(styles.mobileMenuAccordionButton, {
                  [styles.mobileMenuAccordionFirstButton]: index === 0
                })}
                data-testid="mobileMenuAccordionItem"
              >
                <LinkWrapper
                  href={href as string}
                  openNewWindow={!!item.openNewWindow}
                  hoverUnderline
                  onClick={handleCloseMobileMenu}
                >
                  {item.title}
                </LinkWrapper>

                {item?.subNav && (
                  <ButtonIcon
                    size="md"
                    fillVariant="outline"
                    icon={SVG_CHEVRON_RIGHT}
                    color={COLOR_WHITE}
                    aria-label="Toggle accordion menu"
                  />
                )}
              </AccordionItemButton>
            </AccordionItemHeading>
            {item?.subNav && (
              <AccordionItemPanel
                className={styles.mobileMenuAccordionItemPanel}
                data-testid="mobileMenuSubNav"
              >
                <SubNavMenu
                  menu={item.subNav}
                  clickHandler={handleCloseMobileMenu}
                />
              </AccordionItemPanel>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default MobileMenuAccordion;

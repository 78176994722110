import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './ChevronRightSVG.module.scss';

interface ChevronRightSVG {
  color: Colors;
}

const ChevronRightSVG: React.FC<ChevronRightSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.chevronRightSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 48"
      width="50"
      height="50"
    >
      <path
        className={fillColor}
        d="M.36 42.36L18.68 24 .36 5.64 6 0l24 24L6 48 .36 42.36z"
      ></path>
    </svg>
  );
};

export default ChevronRightSVG;

import React, { MouseEventHandler } from 'react';
import { useRouter } from 'next/router';
import LinkWrapper from '@src/components/LinkWrapper';
import { HyperLink } from '@src/types/contentful';
import styles from './SubNavMenu.module.scss';

interface SubNavMenu {
  menu: HyperLink[];
  clickHandler?: MouseEventHandler<HTMLAnchorElement>;
}

const SubNavMenu: React.FC<SubNavMenu> = ({ menu, clickHandler }) => {
  const { asPath } = useRouter();

  return (
    <div className={styles.subNavMenu} data-testid="desktopSubNavigationMenu">
      <div className={styles.subNavNotch} />
      <ul className={styles.subNavList}>
        {menu.map((item: HyperLink) => {
          return (
            <li key={item.sys?.id} data-testid="desktopSubNavigationMenuItem">
              <LinkWrapper
                href={item.urlPath as string}
                isActive={(item.urlPath as string) === asPath}
                openNewWindow={!!item.openNewWindow}
                hoverUnderline
                onClick={clickHandler}
              >
                {item.title}
              </LinkWrapper>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubNavMenu;

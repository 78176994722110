import { useState, useEffect } from 'react';
import { FORM_SUCCESS_URL_PARAMETER } from '@src/types/forms';
import { useRouter } from 'next/router';
/**
 * Hook to trigger in page form success if # param matchs contact-success
 */
export const usePageFormSuccess = () => {
  const router = useRouter();
  const [formSuccess, setFormSuccess] = useState(false);
  const [hash, setHash] = useState('');

  const updateHash = (str: string) => {
    if (!str) {
      setFormSuccess(false);
      return;
    }
    setHash(str.split('#')[1]);
  };

  // get hash on page load (browser refresh or direct browser URL address navigation)
  useEffect(() => {
    updateHash(router.asPath.match(/#([a-z0-9-]+)/gi)?.[0] || '');
  }, [router.asPath]);

  useEffect(() => {
    const successHashes: string[] = [
      FORM_SUCCESS_URL_PARAMETER.CONTACT,
      FORM_SUCCESS_URL_PARAMETER.RSVP
    ];

    if (successHashes.includes(hash ?? '')) {
      setFormSuccess(true);
    }
  }, [hash]);
  // get hash on url rewrites
  useEffect(() => {
    const onWindowHashChange = () => updateHash(window.location.hash);
    const onNextJSHashChange = (url: string) => updateHash(url);

    router.events.on('hashChangeStart', onNextJSHashChange);
    window.addEventListener('hashchange', onWindowHashChange);
    window.addEventListener('load', onWindowHashChange);
    return () => {
      router.events.off('hashChangeStart', onNextJSHashChange);
      window.removeEventListener('load', onWindowHashChange);
      window.removeEventListener('hashchange', onWindowHashChange);
    };
  }, [router.asPath, router.events]);

  return formSuccess;
};

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './PdfSVG.module.scss';

interface PdfSVG extends React.SVGProps<SVGSVGElement> {
  color: Colors;
}

const PdfSVG: React.FC<PdfSVG> = ({ color, ...rest }) => {
  const fillColor = classNames(styles.pdfSvg, getColorClasses(color, styles));

  return (
    <svg
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M72 0H24C19.6 0 16 3.6 16 8V56C16 60.4 19.6 64 24 64H72C76.4 64 80 60.4 80 56V8C80 3.6 76.4 0 72 0ZM38 30C38 33.32 35.32 36 32 36H28V44H22V20H32C35.32 20 38 22.68 38 26V30ZM58 38C58 41.32 55.32 44 52 44H42V20H52C55.32 20 58 22.68 58 26V38ZM74 26H68V30H74V36H68V44H62V20H74V26ZM28 30H32V26H28V30ZM8 16H0V72C0 76.4 3.6 80 8 80H64V72H8V16ZM48 38H52V26H48V38Z"
        className={fillColor}
      />
    </svg>
  );
};

export default PdfSVG;

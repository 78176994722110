import React, { ReactNode } from 'react';
import { Inline } from '@contentful/rich-text-types';
import LinkWrapper from '@src/components/LinkWrapper';
import { isLocalUrl } from '@src/utils/url';
import Icon from '@src/elements/Icon';
import { SVG_EXTERNAL_LINK, SVG_TELEPHONE } from '@src/types/svg';
import styles from './RichTextHyperlink.module.scss';
import classNames from 'classnames';

interface RichTextHyperlinkProps {
  node: Inline;
  children: ReactNode;
  slateLinkStyle: boolean | undefined;
}

const RichTextHyperlink: React.FC<RichTextHyperlinkProps> = ({
  node,
  children,
  slateLinkStyle
}) => {
  const openNewWindow = !isLocalUrl(node?.data?.uri);
  const isTelLink = node?.data?.uri?.includes('tel:');

  return (
    <span
      className={classNames(styles.richTextHyperlink, {
        [styles.richTextHyperlinkSlate]: slateLinkStyle
      })}
    >
      <LinkWrapper
        key={node?.data?.sys?.id}
        href={node?.data?.uri}
        openNewWindow={openNewWindow}
      >
        {children}
        {(openNewWindow || isTelLink) && (
          <span className={styles.iconWrapper}>
            <Icon
              icon={
                openNewWindow && isTelLink ? SVG_TELEPHONE : SVG_EXTERNAL_LINK
              }
            />
          </span>
        )}
      </LinkWrapper>
    </span>
  );
};

export default RichTextHyperlink;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './MinusSVG.module.scss';

interface MinusSVG {
  color: Colors;
}

const MinusSVG: React.FC<MinusSVG> = ({ color }) => {
  const fillColor = classNames(styles.minusSvg, getColorClasses(color, styles));
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 2">
      <path className={fillColor} d="M14 2H0V0h14v2z"></path>
    </svg>
  );
};

export default MinusSVG;

import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import ButtonIcon from '@src/components/Button/ButtonIcon';
import { SVG_SEARCH } from '@src/types/svg';
import styles from './SiteSearch.module.scss';

type siteSearchVariant = 'light' | 'dark';

interface SiteSearch {
  variant: siteSearchVariant;
  focusSearch?: boolean;
  handleCloseMobileMenu?: (
    event: React.MouseEvent | React.KeyboardEvent
  ) => void;
  testid: string;
}

const SiteSearch: React.FC<SiteSearch> = ({
  variant = 'light',
  focusSearch,
  handleCloseMobileMenu,
  testid
}) => {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchParamValue = (router.query?.search || '') as string;
  const [value, setValue] = useState('');
  const onSubmit = (value: string) => {
    if (value.length) {
      router.push(`/search-results?search=${value}`);
    }
  };

  useEffect(() => {
    setValue((router.query?.search || '') as string);
  }, [router]);

  if (focusSearch) {
    inputRef.current?.focus();
    inputRef.current?.setAttribute('autofocus', 'true');
  } else {
    inputRef.current?.removeAttribute('autofocus');
  }

  return (
    <div className={styles.siteSearch} data-testid={testid}>
      <input
        defaultValue={searchParamValue}
        type="search"
        ref={inputRef}
        className={classNames(styles.siteSearchInput, {
          [styles.siteSearchInputDark]: variant === 'dark',
          [styles.siteSearchInputLight]: variant === 'light'
        })}
        placeholder="Search"
        onChange={(e) => {
          setValue(e.currentTarget?.value || '');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (handleCloseMobileMenu) {
              handleCloseMobileMenu(e);
            }
            onSubmit(e.currentTarget?.value || '');
          }
        }}
      />
      <span className={styles.siteSearchInputIcon}>
        <ButtonIcon
          data-testid="searchSubmitButton"
          fillVariant="outline"
          isBorderless
          icon={SVG_SEARCH}
          size="md"
          colorVariant={variant}
          onClick={(e) => {
            if (handleCloseMobileMenu) {
              handleCloseMobileMenu(e);
            }
            onSubmit(value || '');
          }}
          aria-label="Search"
        />
      </span>
    </div>
  );
};

export default SiteSearch;

import { AlignItems } from '@src/types/grid';
import React from 'react';
import styles from './Row.module.scss';

type RowDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

interface Row extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * xs screen size column order
   */
  directionXs?: RowDirection;
  /**
   * sm screen size column order
   */
  directionSm?: RowDirection;
  /**
   * md screen size column order
   */
  directionMd?: RowDirection;
  /**
   * lg screen size column order
   */
  directionLg?: RowDirection;
  /**
   * xl; screen size column order
   */
  directionXl?: RowDirection;
  /**
   * xs screen size column order
   */
  directionXxl?: RowDirection;
  /**
   * xs screen size horizontal align
   */
  justifyXs?: AlignItems;
  /**
   * sm screen size horizontal align
   */
  justifySm?: AlignItems;
  /**
   * md screen size horizontal align
   */
  justifyMd?: AlignItems;
  /**
   * lg screen size horizontal align
   */
  justifyLg?: AlignItems;
  /**
   * xl screen size horizontal align
   */
  justifyXl?: AlignItems;
  /**
   * xxl screen size horizontal align
   */
  justifyXxl?: AlignItems;
}

const Row: React.FC<Row> = ({
  directionXs,
  directionSm,
  directionMd,
  directionLg,
  directionXl,
  directionXxl,
  justifyXs,
  justifySm,
  justifyMd,
  justifyLg,
  justifyXl,
  justifyXxl,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={styles.row}
      data-direction-xs={directionXs}
      data-direction-sm={directionSm}
      data-direction-md={directionMd}
      data-direction-lg={directionLg}
      data-direction-xl={directionXl}
      data-direction-xxl={directionXxl}
      data-justify-xs={justifyXs}
      data-justify-sm={justifySm}
      data-justify-md={justifyMd}
      data-justify-lg={justifyLg}
      data-justify-xl={justifyXl}
      data-justify-xxl={justifyXxl}
    >
      {children}
    </div>
  );
};

export default Row;

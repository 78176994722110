import {
  ButtonSize,
  ButtonColorVariant,
  ButtonIconSize
} from '@src/types/buttons';
import {
  COLOR_SLATE,
  COLOR_LIME,
  COLOR_YELLOW,
  COLOR_ORANGE,
  COLOR_LILAC,
  COLOR_BLUE,
  COLOR_BLACK,
  COLOR_WHITE,
  DarkColors,
  COLOR_ERROR
} from '@src/types/colors';

export const getButtonColorClasses = (
  color: DarkColors,
  variant: ButtonColorVariant,
  outline: boolean,
  styles: any
): { [x: number]: boolean } => ({
  [styles.primaryButton]: color === 'primary',
  [styles.secondaryButton]: color === 'secondary',
  // Dark Filled
  [styles.blackButton]: color === COLOR_BLACK && !outline,
  [styles.blackOutlineButton]: color === COLOR_BLACK && !!outline,
  [styles.whiteButton]: color === COLOR_WHITE && !outline,
  [styles.whiteOutlineButton]: color === COLOR_WHITE && !!outline,
  [styles.slateButton]: color === COLOR_SLATE && !outline && variant === 'dark',
  [styles.limeButton]: color === COLOR_LIME && !outline && variant === 'dark',
  [styles.yellowButton]:
    color === COLOR_YELLOW && !outline && variant === 'dark',
  [styles.orangeButton]:
    color === COLOR_ORANGE && !outline && variant === 'dark',
  [styles.lilacButton]: color === COLOR_LILAC && !outline && variant === 'dark',
  [styles.blueButton]: color === COLOR_BLUE && !outline && variant === 'dark',
  [styles.redButton]: color === COLOR_ERROR && !outline && variant === 'dark',
  // Dark Outline
  [styles.slateOutlineButton]:
    color === COLOR_SLATE && !!outline && variant === 'dark',
  [styles.limeOutlineButton]:
    color === COLOR_LIME && !!outline && variant === 'dark',
  [styles.yellowOutlineButton]:
    color === COLOR_YELLOW && !!outline && variant === 'dark',
  [styles.orangeOutlineButton]:
    color === COLOR_ORANGE && !!outline && variant === 'dark',
  [styles.lilacOutlineButton]:
    color === COLOR_LILAC && !!outline && variant === 'dark',
  [styles.blueOutlineButton]:
    color === COLOR_BLUE && !!outline && variant === 'dark',
  [styles.redOutlineButton]:
    color === COLOR_ERROR && !!outline && variant === 'dark',
  // Light Filled
  [styles.lightSlateButton]:
    color === COLOR_SLATE && !outline && variant === 'light',
  [styles.lightLimeButton]:
    color === COLOR_LIME && !outline && variant === 'light',
  [styles.lightYellowButton]:
    color === COLOR_YELLOW && !outline && variant === 'light',
  [styles.lightOrangeButton]:
    color === COLOR_ORANGE && !outline && variant === 'light',
  [styles.lightLilacButton]:
    color === COLOR_LILAC && !outline && variant === 'light',
  [styles.lightBlueButton]:
    color === COLOR_BLUE && !outline && variant === 'light',
  [styles.lightRedButton]:
    color === COLOR_ERROR && !outline && variant === 'light',
  // Light Outline
  [styles.lightSlateOutlineButton]:
    color === COLOR_SLATE && !!outline && variant === 'light',
  [styles.lightLimeOutlineButton]:
    color === COLOR_LIME && !!outline && variant === 'light',
  [styles.lightYellowOutlineButton]:
    color === COLOR_YELLOW && !!outline && variant === 'light',
  [styles.lightOrangeOutlineButton]:
    color === COLOR_ORANGE && !!outline && variant === 'light',
  [styles.lightLilacOutlineButton]:
    color === COLOR_LILAC && !!outline && variant === 'light',
  [styles.lightBlueOutlineButton]:
    color === COLOR_BLUE && !!outline && variant === 'light',
  [styles.lightRedOutlineButton]:
    color === COLOR_ERROR && !!outline && variant === 'light'
});

export const getButtonClasses = (
  color: DarkColors,
  buttonSize: ButtonSize,
  variant: ButtonColorVariant,
  outline: boolean,
  styles: any
): { [x: number]: boolean } => {
  const colorClasses = getButtonColorClasses(color, variant, outline, styles);
  return {
    [styles.button]: true,
    [styles.large]: buttonSize === 'lg',
    [styles.medium]: buttonSize === 'md',
    [styles.small]: buttonSize === 'sm',
    ...colorClasses
  };
};

export const getButtonPillColorClasses = (
  color: DarkColors,
  variant: ButtonColorVariant,
  styles: any
): { [x: number]: boolean } => ({
  [styles.primaryButtonPill]: color === 'primary',
  [styles.secondaryButtonPill]: color === 'secondary',
  // Dark
  [styles.blackButtonPill]: color === COLOR_BLACK,
  [styles.slateButtonPill]: color === COLOR_SLATE && variant === 'dark',
  [styles.limeButtonPill]: color === COLOR_LIME && variant === 'dark',
  [styles.yellowButtonPill]: color === COLOR_YELLOW && variant === 'dark',
  [styles.orangeButtonPill]: color === COLOR_ORANGE && variant === 'dark',
  [styles.lilacButtonPill]: color === COLOR_LILAC && variant === 'dark',
  [styles.blueButtonPill]: color === COLOR_BLUE && variant === 'dark',
  // Light
  [styles.whiteButtonPill]: color === COLOR_WHITE,
  [styles.lightSlateButtonPill]: color === COLOR_SLATE && variant === 'light',
  [styles.lightLimeButtonPill]: color === COLOR_LIME && variant === 'light',
  [styles.lightYellowButtonPill]: color === COLOR_YELLOW && variant === 'light',
  [styles.lightOrangeButtonPill]: color === COLOR_ORANGE && variant === 'light',
  [styles.lightLilacButtonPill]: color === COLOR_LILAC && variant === 'light',
  [styles.lightBlueButtonPill]: color === COLOR_BLUE && variant === 'light'
});

export const getButtonPillClasses = (
  color: DarkColors,
  variant: ButtonColorVariant,
  styles: any
): { [x: number]: boolean } => {
  const colorClasses = getButtonPillColorClasses(color, variant, styles);
  return {
    [styles.buttonPill]: true,
    ...colorClasses
  };
};

export const getButtonIconClasses = (
  color: DarkColors,
  buttonSize: ButtonIconSize,
  variant: ButtonColorVariant,
  outline: boolean,
  styles: any
): { [x: number]: boolean } => {
  const colorStyles = getButtonColorClasses(color, variant, outline, styles);
  return {
    [styles.buttonIcon]: true,
    [styles.buttonIconXXLarge]: buttonSize === 'xxl',
    [styles.buttonIconXLarge]: buttonSize === 'xl',
    [styles.buttonIconLarge]: buttonSize === 'lg',
    [styles.buttonIconMedium]: buttonSize === 'md',
    [styles.buttonIconSmall]: buttonSize === 'sm',
    [styles.buttonIconXSmall]: buttonSize === 'xs',
    ...colorStyles
  };
};

// * The padding works differently on an <a> than a <button>
// * So the particular ButtonLinkIcon styles style the svg size inside the link, instead of using padding
export const getButtonLinkIconClasses = (
  color: DarkColors,
  buttonSize: ButtonIconSize,
  variant: ButtonColorVariant,
  outline: boolean,
  styles: any
): { [x: number]: boolean } => {
  const colorStyles = getButtonColorClasses(color, variant, outline, styles);
  return {
    [styles.buttonIcon]: true,
    [styles.buttonLinkIconXXLarge]: buttonSize === 'xxl',
    [styles.buttonLinkIconXLarge]: buttonSize === 'xl',
    [styles.buttonLinkIconLarge]: buttonSize === 'lg',
    [styles.buttonLinkIconMedium]: buttonSize === 'md',
    [styles.buttonLinkIconSmall]: buttonSize === 'sm',
    [styles.buttonLinkIconXSmall]: buttonSize === 'xs',
    ...colorStyles
  };
};

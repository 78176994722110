import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './BangSVG.module.scss';

interface BangSVG {
  color: Colors;
}

const BangSVG: React.FC<BangSVG> = ({ color }) => {
  const fillColor = classNames(styles.bangSvg, getColorClasses(color, styles));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        className={fillColor}
        d="M36 52h8v8h-8v-8zm0-32h8v24h-8V20zm3.96-20C17.88 0 0 17.92 0 40s17.88 40 39.96 40C62.08 80 80 62.08 80 40S62.08 0 39.96 0zM40 72C22.32 72 8 57.68 8 40S22.32 8 40 8s32 14.32 32 32-14.32 32-32 32z"
      ></path>
    </svg>
  );
};

export default BangSVG;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './CloseSVG.module.scss';

interface CloseSVG {
  color: Colors;
}

const CloseSVG: React.FC<CloseSVG> = ({ color }) => {
  const fillColor = classNames(styles.closeSvg, getColorClasses(color, styles));

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 56">
      <path
        className={fillColor}
        d="M56 5.64L50.36 0 28 22.36 5.64 0 0 5.64 22.36 28 0 50.36 5.64 56 28 33.64 50.36 56 56 50.36 33.64 28 56 5.64z"
      ></path>
    </svg>
  );
};

export default CloseSVG;

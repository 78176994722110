import { useRouter } from 'next/router';

export interface ModalState {
  open: boolean;
  initial?: number;
}

/**
 * Hook to trigger modal handleOpen or handleClose
 */
export const useModalOpenOrClose = (
  setModal: (value: React.SetStateAction<ModalState>) => void,
  hash: string
) => {
  const router = useRouter();
  const hasHash = router.asPath.includes('#');

  const handleOpen = (initial?: number) => {
    if (!hasHash) {
      router.push(
        {
          pathname: router.asPath,
          hash: hash
        },
        undefined,
        { scroll: false }
      );
    }
    setModal({ open: true, initial });
  };

  const handleClose = () => {
    setModal({ open: false, initial: 0 });
    router.back();
  };

  return { handleOpen, handleClose };
};

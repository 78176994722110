export const SVG_ARROW_DOWN = 'ArrowDown';
export const SVG_ARROW_LEFT = 'ArrowLeft';
export const SVG_ARROW_RIGHT = 'ArrowRight';
export const SVG_ARROW_UP = 'ArrowUp';
export const SVG_AT = 'At';
export const SVG_BANG = 'Bang';
export const SVG_BATH = 'Bath';
export const SVG_BED = 'Bed';
export const SVG_CALENDAR = 'Calendar';
export const SVG_CAR = 'Car';
export const SVG_CHEVRON_DOWN = 'ChevronDown';
export const SVG_CHEVRON_LEFT = 'ChevronLeft';
export const SVG_CHEVRON_RIGHT = 'ChevronRight';
export const SVG_CHEVRON_UP = 'ChevronUp';
export const SVG_CLOCK = 'Clock';
export const SVG_CLOSE = 'Close';
export const SVG_ENVELOPE = 'Envelope';
export const SVG_EXTERNAL_LINK = 'External Link';
export const SVG_FACEBOOK = 'Facebook';
export const SVG_FEEDBACK = 'Feedback';
export const SVG_HAMBURGER = 'Hamburger';
export const SVG_HELP_SOLID = 'HelpSolid';
export const SVG_INSTAGRAM = 'Instagram';
export const SVG_LINKEDIN = 'Linkedin';
export const SVG_LIST = 'List';
export const SVG_LOADING = 'Loading';
export const SVG_LOGO = 'Logo';
export const SVG_MAP_MARKER = 'MapMarker';
export const SVG_MINUS = 'Minus';
export const SVG_PAUSE = 'Pause';
export const SVG_PDF = 'Pdf';
export const SVG_PEN = 'Pen';
export const SVG_PERSON = 'Person';
export const SVG_PHONE = 'Phone';
export const SVG_PLAN = 'Plan';
export const SVG_PLAY = 'Play';
export const SVG_PLUS = 'Plus';
export const SVG_SEARCH = 'Search';
export const SVG_SLASH = 'Slash';
export const SVG_QUESTION_ANSWER = 'QuestionAnswer';
export const SVG_TELEPHONE = 'Telephone';
export const SVG_TWITTER = 'Twitter';
export const SVG_YOUTUBE = 'Youtube';
export const SVG_FILTER = 'Filter';
export const SVG_BUSINESS = 'Business';
export const SVG_DOLLAR_SIGN = 'DollarSign';

export type SvgArrowDown = typeof SVG_ARROW_DOWN;
export type SvgArrowLeft = typeof SVG_ARROW_LEFT;
export type SvgArrowRight = typeof SVG_ARROW_RIGHT;
export type SvgArrowUp = typeof SVG_ARROW_UP;
export type SvgAt = typeof SVG_AT;
export type SvgBang = typeof SVG_BANG;
export type SvgBath = typeof SVG_BATH;
export type SvgBed = typeof SVG_BED;
export type SvgCalendar = typeof SVG_CALENDAR;
export type SvgCar = typeof SVG_CAR;
export type SvgChevronDown = typeof SVG_CHEVRON_DOWN;
export type SvgChevronLeft = typeof SVG_CHEVRON_LEFT;
export type SvgChevronRight = typeof SVG_CHEVRON_RIGHT;
export type SvgChevronUp = typeof SVG_CHEVRON_UP;
export type SvgClock = typeof SVG_CLOCK;
export type SvgClose = typeof SVG_CLOSE;
export type SvgEnvelope = typeof SVG_ENVELOPE;
export type SvgExternalLink = typeof SVG_EXTERNAL_LINK;
export type SvgFacebook = typeof SVG_FACEBOOK;
export type SvgFeedback = typeof SVG_FEEDBACK;
export type SvgHamburger = typeof SVG_HAMBURGER;
export type SvgHelpSolid = typeof SVG_HELP_SOLID;
export type SvgInstagram = typeof SVG_INSTAGRAM;
export type SvgLinkedin = typeof SVG_LINKEDIN;
export type SvgList = typeof SVG_LIST;
export type SvgLoading = typeof SVG_LOADING;
export type SvgLogo = typeof SVG_LOGO;
export type SvgMapMarker = typeof SVG_MAP_MARKER;
export type SvgMinus = typeof SVG_MINUS;
export type SvgPause = typeof SVG_PAUSE;
export type SvgPhone = typeof SVG_PHONE;
export type SvgPdf = typeof SVG_PDF;
export type SvgPen = typeof SVG_PEN;
export type SvgPerson = typeof SVG_PERSON;
export type SvgPlan = typeof SVG_PLAN;
export type SvgPlay = typeof SVG_PLAY;
export type SvgPlus = typeof SVG_PLUS;
export type SvgQuestionAnswer = typeof SVG_QUESTION_ANSWER;
export type SvgSearch = typeof SVG_SEARCH;
export type SvgSlash = typeof SVG_SLASH;
export type SvgTelephone = typeof SVG_TELEPHONE;
export type SvgTwitter = typeof SVG_TWITTER;
export type SvgYoutube = typeof SVG_YOUTUBE;
export type SvgFilter = typeof SVG_FILTER;
export type SvgBusiness = typeof SVG_BUSINESS;
export type SvgDollarSign = typeof SVG_DOLLAR_SIGN;

export type SvgIconName =
  | SvgArrowDown
  | SvgArrowLeft
  | SvgArrowRight
  | SvgArrowUp
  | SvgAt
  | SvgBang
  | SvgBath
  | SvgBed
  | SvgCalendar
  | SvgCar
  | SvgChevronDown
  | SvgChevronLeft
  | SvgChevronRight
  | SvgChevronUp
  | SvgClock
  | SvgClose
  | SvgEnvelope
  | SvgExternalLink
  | SvgFacebook
  | SvgFeedback
  | SvgHamburger
  | SvgHelpSolid
  | SvgInstagram
  | SvgLinkedin
  | SvgList
  | SvgLoading
  | SvgLogo
  | SvgMapMarker
  | SvgMinus
  | SvgPause
  | SvgPdf
  | SvgPen
  | SvgPerson
  | SvgPhone
  | SvgPlan
  | SvgPlay
  | SvgPlus
  | SvgQuestionAnswer
  | SvgSearch
  | SvgSlash
  | SvgTelephone
  | SvgTwitter
  | SvgYoutube
  | SvgFilter
  | SvgBusiness
  | SvgDollarSign;

export interface SvgIconComponent {
  name: SvgIconName;
  component: React.FC<any>;
}

import Image from '@src/elements/Image';
import { MediaImageFragment, MediaVideoFragment } from '@src/graphql/gql-types';
import { ImageAspectRatio } from '@src/types/image';
import React from 'react';

type MediaImage = (MediaImageFragment | MediaVideoFragment) & {
  // NOTE: priority should only be set for images above the fold
  priority?: boolean;
  aspectRatio?: ImageAspectRatio;
  thumb?: boolean;
  base64Img?: string;
};

const MediaImage: React.FC<MediaImage> = (media) => {
  let image;
  let base64Img;
  if ('cloudinarySource' in media) {
    image = media.cloudinarySource[0];
    base64Img = media.base64Img;
  } else if ('coverImage' in media) {
    image = media.coverImage[0];
  }

  return (
    <>
      <Image
        image={image}
        priority={media.priority}
        base64Img={base64Img}
        caption={media.caption || ''}
        aspectRatio={media.aspectRatio}
        thumb={media.thumb}
      />
    </>
  );
};

export default MediaImage;

import { Link } from 'contentful-management';

export enum LINKTYPES {
  STANDARD = 'standard',
  EMAIL = 'email',
  TEL = 'tel'
}

export const CONTENTFUL_TAG_NATIONALCONTENT_ID = 'nationalContent';
export const CONTENTFUL_TAG_NATIONALCONTENT_LINK: Link<'Tag'> = {
  // The National Role Tag and ID
  sys: {
    type: 'Link',
    linkType: 'Tag',
    id: CONTENTFUL_TAG_NATIONALCONTENT_ID
  }
};

export const CONTENTFUL_TAG_COMMUNITY_PREFIX = `community`;

// ========== ENUMS ========================================

export enum CONTENTFUL_TYPENAME {
  CALL_TO_ACTION = 'CallToAction',
  HERO = 'Hero',
  GALLERY = 'Gallery',
  NAVIGATION_ELEMENT = 'NavigationElement',
  STATIC_BLOCK = 'StaticBlock',
  PRODUCT = 'Product',
  PRODUCT_CATALOG = 'ProductCatalog',
  MEDIA_IMAGE = 'MediaImage',
  MEDIA_VIDEO = 'MediaVideo',
  MEDIA_ASSET = 'MediaAsset',
  ALTERNATING_TEXT_BLOCK = 'AlternatingTextBlock',
  ALTERNATING_CAROUSEL_BLOCK = 'AlternatingCarouselBlock',
  GENERIC_TEXT_BLOCK = 'GenericTextBlock',
  MATTERPORT_BLOCK = 'Matterport3DVideoBlock'
}

export enum CONTENTFUL_PAGE_CONTENT_TYPES {
  COMMUNITY = 'PageCommunityDetail',
  COMMUNITY_STANDARD = 'PageCommunityStandard',
  COMMUNITY_PROPERTY_DETAIL = 'PageCommunityPropertyDetail',
  COMMUNITY_NEWS = 'PageCommunityNews',
  COMMUNITY_EVENT = 'PageCommunityEvent',

  COMMUNITY_EDM = 'PageCommunityEdm',
  NATIONAL_STANDARD = 'PageNationalStandard',
  NATIONAL_NEWS = 'PageNationalNews',
  SHARED_NEWS = 'PageSharedNews'
}

export enum STATIC_BLOCK_COMPONENT_NAME {
  NATIONAL_NEWS_LIST = 'national-news',
  COMMUNITY_NEWS_EVENTS_LIST = 'community-news-events',
  FORM_NATIONAL_CONTACT_US = 'form-national-contact-us',
  FORM_COMMUNITY_CONTACT_US = 'form-community-contact-us',
  COMMUNITY_PROPERTY_SEARCH = 'community-property-search',
  INTERACTIVE_MAP_STATE_COMMUNITIES = 'map-state-view',
  INTERACTIVE_MAP_HEAD_OFFICE = 'map-head-office',
  STATE_COMMUNITY_LIST = 'state-community-list',
  SITEMAP_LIST = 'sitemap-list'
}

export enum CONTENTFUL_ROLES {
  NATIONAL_MANAGER = 'National Manager'
}

export const CONTENTFUL_PAGE_CONTENT_TYPES_ARRAY: string[] = (
  Object.keys(CONTENTFUL_PAGE_CONTENT_TYPES) as Array<
    keyof typeof CONTENTFUL_PAGE_CONTENT_TYPES
  >
)
  .map((key) => CONTENTFUL_PAGE_CONTENT_TYPES[key])
  .filter((x) => !(parseInt(x) >= 0));

// https://www.contentful.com/developers/docs/references/errors/
// when using the Contentful SDK, these error codes can be obtained via error.name (where error is the thrown error object)
enum CONTENTFUL_ERROR_NAMES {
  BAD_REQUEST = 'BadRequest', // The request was malformed or missing a required parameter.
  INVALID_QUERY = 'InvalidQuery', // 	The request contained invalid or unknown query parameters.
  ACCESS_TOKEN_INVALID = 'AccessTokenInvalid', // The authorization token was invalid.
  ACCESS_DENIED = 'AccessDenied', // The user tried to access a resource they do not have access to. This could include a missing role.
  NOT_FOUND = 'NotFound', // The requested resource or endpoint could not be found.
  VERSION_MISMATCH = 'VersionMismatch', // This error occurs when you're trying to update an existing asset, entry or content type, and you didn't specify the current version of the object or specified an outdated version.
  VALIDATION_FAILED = 'ValidationFailed', // The request payload was valid JSON, but something was wrong with the data. The error details should provide more specific information about the error.
  UNKNOWN_FIELD = 'UnknownField', // The triggered query references an unknown field.
  INVALID_ENTRY = 'InvalidEntry', // The entered value is invalid.
  RATE_LIMIT_EXCEEDED = 'RateLimitExceeded', // The user sent too many requests in a given timeframe. See API Rate limits for details.
  SERVER_ERROR = 'ServerError', // Something went wrong on the Contentful servers.
  HIBERNATED = 'Hibernated' // The space has not been accessed for a long time and has been "hibernated" in a saved state. This error should resolve on its own in a few moments, once the space has been successfully awakened.
}

export const ContentfulErrors: Record<CONTENTFUL_ERROR_NAMES, number> = {
  [CONTENTFUL_ERROR_NAMES.BAD_REQUEST]: 400,
  [CONTENTFUL_ERROR_NAMES.INVALID_QUERY]: 400,
  [CONTENTFUL_ERROR_NAMES.ACCESS_TOKEN_INVALID]: 401,
  [CONTENTFUL_ERROR_NAMES.ACCESS_DENIED]: 403,
  [CONTENTFUL_ERROR_NAMES.NOT_FOUND]: 404,
  [CONTENTFUL_ERROR_NAMES.VERSION_MISMATCH]: 409,
  [CONTENTFUL_ERROR_NAMES.VALIDATION_FAILED]: 422,
  [CONTENTFUL_ERROR_NAMES.UNKNOWN_FIELD]: 422,
  [CONTENTFUL_ERROR_NAMES.INVALID_ENTRY]: 422,
  [CONTENTFUL_ERROR_NAMES.RATE_LIMIT_EXCEEDED]: 429,
  [CONTENTFUL_ERROR_NAMES.SERVER_ERROR]: 500,
  [CONTENTFUL_ERROR_NAMES.HIBERNATED]: 502
};

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './LinkedinSVG.module.scss';

interface LinkedinSVG {
  color: Colors;
}

const LinkedinSVG: React.FC<LinkedinSVG> = ({ color }) => {
  const fillColor = classNames(
    styles.linkedinSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
      <path
        className={fillColor}
        d="M64 32a24 24 0 0124 24v28H72V56a8 8 0 00-16 0v28H40V56a24 24 0 0124-24zm-40 4H8v48h16V36zm-8-12a8 8 0 100-16 8 8 0 000 16z"
      ></path>
    </svg>
  );
};

export default LinkedinSVG;

import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useHoverIntent } from '@src/hooks/useHoverIntent';
import { HyperLink } from '@src/types/contentful';
import classNames from 'classnames';
import LinkWrapper from '@src/components/LinkWrapper';
import SubNavMenu from '@src/components/Nav/SubNavMenu';
import styles from './DesktopMenu.module.scss';
import { Container } from '@src/elements/Grid';
import { getActiveTopLevelRoute } from '@src/utils/url';
import {
  PATHS,
  SELECT_COMMUNITY_NEWS_TAB_QUERY_PARAM
} from '@src/constants/site';

interface DesktopMenu {
  menuLinks: HyperLink[];
}

interface DesktopMenuItem {
  item: HyperLink;
}

export const DesktopMenuItem: React.FC<DesktopMenuItem> = ({ item }) => {
  const router = useRouter();
  const [isHovering, setIsHovering, ref] = useHoverIntent({
    timeout: 0,
    sensitivity: 10,
    interval: 100
  });

  // deactivate hover on subnav item click (so the subnav menu is not rendered)
  const handleDeactivateHover = useCallback(
    () => setIsHovering(false),
    [setIsHovering]
  );

  // nav item is active on top level route match
  const isActive = getActiveTopLevelRoute(item.urlPath, router.asPath);

  // adds query param on to end of path for News & Events page so that the
  // page renders with the news tab open.
  const href = item?.urlPath?.includes(PATHS.COMMUNITY_NEWS_AND_EVENTS)
    ? item.urlPath + SELECT_COMMUNITY_NEWS_TAB_QUERY_PARAM
    : item.urlPath;
  return (
    <li
      key={item.sys?.id}
      ref={ref as React.RefObject<HTMLLIElement>}
      className={classNames(styles.desktopMenuListItem, {
        [styles.desktopMenuListItemHover]: isHovering,
        [styles.desktopMenuListItemActive]: isActive
      })}
      data-testid="desktopNavigationMenuItem"
      data-active={isActive && !item?.subNav}
    >
      <LinkWrapper
        href={href as string}
        openNewWindow={!!item.openNewWindow}
        className={styles.desktopMenuListItemLink}
      >
        {item.title}
      </LinkWrapper>
      {item?.subNav && (
        <SubNavMenu menu={item.subNav} clickHandler={handleDeactivateHover} />
      )}
    </li>
  );
};

const DesktopMenu: React.FC<DesktopMenu> = ({ menuLinks }) => {
  return (
    <div className={styles.desktopMenu}>
      <Container>
        <ul
          className={styles.desktopMenuList}
          data-testid="desktopNavigationMenu"
        >
          {menuLinks?.map((item) => (
            <DesktopMenuItem key={item?.sys?.id} item={item} />
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default DesktopMenu;

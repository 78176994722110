import React from 'react';
import classNames from 'classnames';
import styles from './CheckboxInput.module.scss';

interface CheckboxInput extends Omit<React.HTMLProps<HTMLInputElement>, 'ref'> {
  /**
   * make ID required
   */
  id: string;
  /**
   * The form field is in an error state
   */
  hasError?: boolean;
  /**
   * The form field error text
   */
  fieldErrorMessage?: string;
}

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInput>(
  function CheckboxInput(
    { id, hasError, fieldErrorMessage, children, ...rest },
    ref
  ) {
    return (
      <>
        <label
          htmlFor={id}
          className={classNames(styles.checkboxLabel, {
            [styles.checkboxLabelError]: hasError,
            [styles.checkboxLabelDisabled]: rest?.disabled,
            [styles.checkboxLabelReadOnly]: rest?.readOnly
          })}
        >
          <input
            ref={ref}
            id={id}
            type="checkbox"
            {...rest}
            className={classNames(styles.checkboxInput, {
              [styles.checkboxInputError]: hasError
            })}
          />
          {children}
        </label>
        {hasError && fieldErrorMessage && (
          <p className={styles.fieldErrorMessage}>{fieldErrorMessage}</p>
        )}
      </>
    );
  }
);

export default CheckboxInput;

import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './PlusSVG.module.scss';

interface PlusSVG {
  color: Colors;
}

const PlusSVG: React.FC<PlusSVG> = ({ color }) => {
  const fillColor = classNames(styles.plusSvg, getColorClasses(color, styles));
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path className={fillColor} d="M14 8H8v6H6V8H0V6h6V0h2v6h6v2z"></path>
    </svg>
  );
};

export default PlusSVG;

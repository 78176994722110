import { yupResolver } from '@hookform/resolvers/yup';
import { CREATE_EVENT_RSVP_FORM_SCHEMA } from '@src/constants/forms';
import { usePageFormSuccess } from '@src/hooks/usePageFormSuccess';
import { useWindow } from '@src/hooks/useWindow';
import {
  CreateLeadFormFields,
  FORM_ERROR_URL_PARAMETER,
  FORM_SUCCESS_URL_PARAMETER,
  LEVANDE_WEBSITE_CHANNEL_OF_ENQUIRY,
  RSVP_LEAD_SOURCE
} from '@src/types/forms';
import logger from '@src/utils/logger';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './CommunityEventRSVPForm.module.scss';
import {
  CheckboxInput,
  FieldSet,
  SelectInput,
  TextInput
} from '@src/elements/Form';
import { Row, Column } from '@src/elements/Grid';
import { ButtonStandard } from '@src/components/Button';
import { COLOR_LIME } from '@src/types/colors';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import {
  GlobalSettingItemFragment,
  Maybe,
  PageCommunityEventFieldsFragment,
  SysPageFragment
} from '@src/graphql/gql-types';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import ReCaptchaTerms from '@src/components/ReCaptchaTerms';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface CommunityEventRSVPForm
  extends Omit<PageCommunityEventFieldsFragment, 'sys'> {
  disclaimerRichText: Maybe<GlobalSettingItemFragment>;
  sys: SysPageFragment | undefined;
}

const CommunityEventRSVPForm: React.FC<CommunityEventRSVPForm> = ({
  disclaimerRichText,
  eventDate,
  community,
  sys,
  slug
}) => {
  const router = useRouter();
  const windowObj = useWindow();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formSuccess = usePageFormSuccess();
  const [showForm, setShowForm] = useState(true);

  // The id of the form, used for reCAPTCHA analytics
  const pageId = 'community_event_rsvp_form';

  // checks for changes in the url and sets showForm to false if success/error hashes are present
  useEffect(() => {
    if (router.isReady) {
      const hashString = new URL(router.asPath, location.href).hash;

      setShowForm(
        hashString !== `#${FORM_SUCCESS_URL_PARAMETER.RSVP}` &&
          hashString !== `#${FORM_ERROR_URL_PARAMETER.RSVP}`
      );
    }
  }, [formSuccess, router]);

  const formattedState = community?.state?.slug?.toUpperCase();
  const formattedDate = new Date(eventDate)
    // passing AU locale so that the format is DD/MM/YYYY
    .toLocaleDateString('en-AU', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    .split('/')
    .reverse()
    .join('-');

  const formattedWebsiteURL = `${windowObj.href}?name=${slug}&id=${
    sys?.id ?? ''
  }&startDate=${formattedDate}&state=${formattedState ?? ''}`;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<CreateLeadFormFields>({
    resolver: yupResolver(CREATE_EVENT_RSVP_FORM_SCHEMA),
    defaultValues: {
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      optIn: '',
      channelOfEnquiry: '',
      villagePreference: '',
      enquiringState: '',
      websiteURL: '',
      leadSource: ''
    }
  });

  const handleError = () => {
    setShowForm(false);
    setIsSubmitting(false);

    const errorUrl = new URL(router.asPath, location.href);
    errorUrl.hash = FORM_ERROR_URL_PARAMETER.RSVP;
    router.push(errorUrl.toString());
  };

  // submit form with synthetic delay after validating reCaptcha
  const formSubmit = async () => {
    setIsSubmitting(true);

    if (executeRecaptcha) {
      const gRecaptchaToken = await executeRecaptcha(pageId);

      try {
        const body = {
          ...getValues(),
          enquiryDate: new Intl.DateTimeFormat('en-AU').format(
            new Date(Date.now())
          ),
          optIn: getValues().optIn.toString() || 'false',
          villagePreference: community?.title ?? '',
          enquiringState: formattedState,
          channelOfEnquiry: LEVANDE_WEBSITE_CHANNEL_OF_ENQUIRY,
          websiteURL: formattedWebsiteURL,
          leadSource: RSVP_LEAD_SOURCE,
          gRecaptchaToken
        };

        fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/v1/leads/`, {
          method: 'post',
          headers: {
            'content-type': 'application/json',
            'x-api-key': process.env.NEXT_PUBLIC_LEVANDE_API_KEY || ''
          },
          body: JSON.stringify(body)
        })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              setIsSubmitting(false);

              const successUrl = new URL(router.asPath, location.href);
              successUrl.hash = FORM_SUCCESS_URL_PARAMETER.RSVP;
              router.push(successUrl.toString());

              // log submit to datadog
              logger.info(
                'forms v2 submit Community Event RSVP (web-to-lead)',
                {
                  form: 'Community Event RSVP (web-to-lead)',
                  source: 'Web',
                  service: 'Form'
                }
              );
            } else {
              handleError();
            }
          })
          .catch((error) => {
            handleError();
            console.log('Failed to submit form', error);
            throw error;
          });
      } catch (error) {
        handleError();
        // log exception to Sentry
        throw error;
      }
    } else {
      handleError();
      console.log('Could not verify ReCaptcha. Form not submitted.');
    }
  };

  return (
    <>
      {!showForm && !formSuccess && (
        <div className={classNames(styles.submissionMessage, styles.error)}>
          <p data-testid="rsvpErrorHeader">
            There seems to be a problem with this page. Please call 1800 72 71
            70 or try again later.
          </p>
        </div>
      )}

      {!showForm && formSuccess && (
        <div className={classNames(styles.submissionMessage, styles.success)}>
          <p data-testid="rsvpSuccessHeader">
            Thank you for RSVPing to this event.
          </p>
        </div>
      )}

      {showForm && (
        <form
          data-testid="communityEventRSVPForm"
          noValidate
          onSubmit={handleSubmit(formSubmit)}
          ref={formRef}
          className={styles.communityEventRsvpForm}
        >
          <FieldSet noBottomSpacing>
            <Row>
              <Column>
                <SelectInput
                  data-testid="selectInputTitle"
                  id="salutation"
                  label="Title"
                  {...register('title')}
                  readOnly={isSubmitting}
                >
                  <option value="">Select</option>
                  <option value="Mr.">Mr</option>
                  <option value="Mrs.">Mrs</option>
                  <option value="Ms.">Ms</option>
                </SelectInput>
              </Column>
            </Row>

            <Row>
              <Column>
                <TextInput
                  id="first_name"
                  label="First Name *"
                  type="text"
                  placeholder="First name"
                  {...register('firstName')}
                  readOnly={isSubmitting}
                  hasError={!!errors?.firstName}
                  fieldErrorMessage={errors?.firstName?.message}
                />
              </Column>
            </Row>

            <Row>
              <Column>
                <TextInput
                  id="last_name"
                  label="Last Name *"
                  type="text"
                  placeholder="Last name"
                  {...register('lastName')}
                  readOnly={isSubmitting}
                  hasError={!!errors?.lastName}
                  fieldErrorMessage={errors?.lastName?.message}
                />
              </Column>
            </Row>

            <Row>
              <Column>
                <TextInput
                  id="email"
                  label="Email *"
                  type="text"
                  placeholder="Email"
                  {...register('emailAddress')}
                  readOnly={isSubmitting}
                  hasError={!!errors?.emailAddress}
                  fieldErrorMessage={errors?.emailAddress?.message}
                />
              </Column>
            </Row>

            <Row>
              <Column>
                <TextInput
                  id="phone"
                  label="Phone *"
                  type="text"
                  placeholder="Phone"
                  {...register('phoneNumber')}
                  readOnly={isSubmitting}
                  hasError={!!errors?.phoneNumber}
                  fieldErrorMessage={errors?.phoneNumber?.message}
                />
              </Column>
            </Row>
          </FieldSet>

          <FieldSet>
            <CheckboxInput
              id="communityContactTerms"
              {...register('optIn')}
              readOnly={isSubmitting}
              // intentionally disable field to exclude from POST data
              disabled={isSubmitting}
              hasError={!!errors?.optIn}
              fieldErrorMessage={errors?.optIn?.message}
            >
              <span>
                {disclaimerRichText?.richText && (
                  <RichTextRender
                    bodyContent={disclaimerRichText?.richText}
                    pageType={CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_EVENT}
                    renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                    forceLinksOpenNewWindow={true}
                  />
                )}
              </span>
            </CheckboxInput>
          </FieldSet>

          <FieldSet noBottomSpacing>
            <ButtonStandard
              type="submit"
              disabled={isSubmitting}
              color={COLOR_LIME}
              aria-label="Submit"
              data-testid="rsvpSubmit"
            >
              Submit
            </ButtonStandard>
            <ReCaptchaTerms />
          </FieldSet>
        </form>
      )}
    </>
  );
};

export default CommunityEventRSVPForm;

import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Options } from '@contentful/rich-text-react-renderer/dist/types';
import {
  Block,
  BLOCKS,
  Inline,
  INLINES,
  Document,
  Text
} from '@contentful/rich-text-types';
import {
  // NOTE: no assets at the mo : getReferencedAssetDataWithId,
  getReferencedEntryDataWithId
} from '@src/utils/richText';

import {
  ENTRY_TYPES,
  RichTextBodyContent,
  RICHTEXT_RENDER_MODE
} from '@src/types/richText';

import {
  ContentfulPageTypes,
  isMediaAssetEntry,
  isPageMetadataEntry
} from '@src/types/contentful';
import { CloudinarySource } from '@src/types/cloudinary';
import { COLOR_SLATE } from '@src/types/colors';
import { camelizeString, replaceRichTextJson } from '@src/utils/strings';
import { WordDictionary } from '@src/types/dictionary';
import { getPageUrlPath } from '@src/utils/url';
import Content from '@src/elements/Content';
import LinkWrapper from '@src/components/LinkWrapper';
import RichTextHyperlink from '@src/components/RichTextHyperlink';
import RenderReferencedComponent from '@src/components/RichTextRender/RenderReferencedComponent';
import styles from '@src/components/RichTextRender/RichTextRender.module.scss';
import Table from '../Table';
import { PdfSVG } from '@src/svgs';
import classNames from 'classnames';
import { cloudinaryClient } from '@src/services/cloudinary';

interface RichTextRender {
  /**
   * Post body content
   */
  bodyContent?: RichTextBodyContent;
  /**
   * Page type to determine how components are rendered
   */
  pageType: ContentfulPageTypes;

  renderMode: RICHTEXT_RENDER_MODE;

  // Force the opening of any link to be in a new window. (eg: Used for disclaimer text for internal links that should open in new tab)
  forceLinksOpenNewWindow?: boolean;

  replacementDictionary?: WordDictionary;
  slateLinkStyle?: boolean;
}

/** Element tags */
type TagName = keyof Omit<
  HTMLElementTagNameMap,
  'applet' | 'dir' | 'font' | 'frame' | 'frameset' | 'marquee'
>;

/** Elements that we will add spacing to  */
type SpacerElement = keyof Pick<
  HTMLElementTagNameMap,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
>;

const spacerElements: TagName[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

/**
 * Render the element
 * @param children
 * @param tagName
 * @returns
 */

const BodyCopyElement: React.FC<{
  tagName: TagName;
  children: React.ReactNode;
}> = ({ tagName, children }) => {
  const Element = tagName;

  switch (tagName) {
    case 'p':
      return <Element>{children}</Element>;
    case 'blockquote':
      return <Element>{children}</Element>;
    case 'ul':
    case 'ol':
      return <Element>{children}</Element>;
    default:
      return <Element>{children}</Element>;
  }
};

/**
 * Wrapper for rendering block quote elements
 * @param children
 * @returns
 */
const renderBlockQuote = (children: ReactNode) => (
  <BodyCopyElement tagName="blockquote">
    <hr />
    {children}
    <hr />
  </BodyCopyElement>
);

/**
 * Wrapper for rendering body elements
 * @param children
 * @param tagName
 * @returns
 */
const renderBodyCopy = (
  children: ReactNode,
  tagName: TagName,
  pageType: ContentfulPageTypes
) => {
  const spacerTag = spacerElements.includes(tagName)
    ? (tagName as SpacerElement)
    : undefined;

  // TODO: remove noop placeholder if these vars get used
  if (spacerTag || pageType) {
    0;
  }

  return (
    <>
      {children?.toString() && children?.toString().length > 0 && (
        <BodyCopyElement tagName={tagName}>{children}</BodyCopyElement>
      )}
    </>
  );
};

function renderEntryLink(
  bodyContent: RichTextBodyContent | undefined,
  node: Block | Inline,
  children: ReactNode,
  forceLinksOpenNewWindow: boolean,
  slateLinkStyle: boolean | undefined
) {
  const referencedEntry = getReferencedEntryDataWithId(
    node.data.target.sys.id,
    ENTRY_TYPES.ENTRY_HYPERLINK,
    bodyContent?.links
  );

  if (referencedEntry) {
    if (isPageMetadataEntry(referencedEntry.fields)) {
      const linkData = node.content[0] as Text;
      const hyperLinkPathUrl = getPageUrlPath(referencedEntry.fields, true);

      return (
        <span
          className={classNames(styles.inlineEntryLink, {
            [styles.inlineEntryLinkSlate]: slateLinkStyle
          })}
        >
          <LinkWrapper
            key={`${referencedEntry?.sys?.id}-${camelizeString(
              linkData.value
            )}}`}
            href={hyperLinkPathUrl}
            openNewWindow={forceLinksOpenNewWindow}
          >
            {children}
          </LinkWrapper>
        </span>
      );
    }

    if (isMediaAssetEntry(referencedEntry.fields)) {
      const isEntryHyperLink = node.nodeType === INLINES.ENTRY_HYPERLINK;
      let linkString = '';
      let keyId = '';
      if (isEntryHyperLink) {
        const linkData = node.content[0] as Text;
        keyId = `${referencedEntry?.sys?.id}-${camelizeString(
          linkData.value
        )}}`;
      } else {
        // We are an inline embed entry so need to get the text from the content-type
        linkString = referencedEntry.fields.caption as string;
        keyId = `${referencedEntry?.sys?.id}-${camelizeString(linkString)}}`;
      }

      const cloudinaryData: CloudinarySource =
        referencedEntry.fields.cloudinarySource?.[0];
      const isPdf = cloudinaryData?.format === 'pdf';

      const mediaAssetCloudinaryPublicId = cloudinaryData?.public_id || '';
      const cloudinaryUrl = cloudinaryClient
        .image(mediaAssetCloudinaryPublicId)
        .toURL();

      return (
        <span
          className={classNames(styles.inlineMediaAssetLink, {
            [styles.inlineMediaAssetLinkSlate]: slateLinkStyle
          })}
        >
          <LinkWrapper key={keyId} href={cloudinaryUrl} openNewWindow={true}>
            <span className={styles.iconSvgWrapper}>
              {linkString}
              {children}
              {isPdf && <PdfSVG color={COLOR_SLATE} />}
            </span>
          </LinkWrapper>
        </span>
      );
    }
  }

  return '/';
}

function renderEmbedEntry(
  bodyContent: RichTextBodyContent | undefined,
  node: Block | Inline,
  children: ReactNode,
  slateLinkStyle: boolean | undefined
) {
  const referencedEntry = getReferencedEntryDataWithId(
    node.data.target.sys.id,
    ENTRY_TYPES.EMBEDDED_ENTRY_INLINE,
    bodyContent?.links
  );

  if (referencedEntry) {
    if (isMediaAssetEntry(referencedEntry.fields)) {
      const isEntryHyperLink = node.nodeType === INLINES.ENTRY_HYPERLINK;
      let linkString = '';
      let keyId = '';
      if (isEntryHyperLink) {
        const linkData = node.content[0] as Text;
        keyId = `${referencedEntry?.sys?.id}-${camelizeString(
          linkData.value
        )}}`;
      } else {
        // We are an inline embed entry so need to get the text from the content-type
        linkString = referencedEntry.fields.caption as string;
        keyId = `${referencedEntry?.sys?.id}-${camelizeString(linkString)}}`;
      }

      const cloudinaryData: CloudinarySource =
        referencedEntry.fields.cloudinarySource?.[0];
      const isPdf = cloudinaryData?.format === 'pdf';

      const mediaAssetCloudinaryPublicId = cloudinaryData?.public_id || '';
      const cloudinaryUrl = cloudinaryClient
        .image(mediaAssetCloudinaryPublicId)
        .toURL();

      return (
        <span
          className={classNames(styles.inlineMediaAssetLink, {
            [styles.inlineMediaAssetLinkSlate]: slateLinkStyle
          })}
        >
          <LinkWrapper key={keyId} href={cloudinaryUrl} openNewWindow={true}>
            <span className={styles.iconSvgWrapper}>
              {linkString}
              {children}
              {isPdf && <PdfSVG color={COLOR_SLATE} />}
            </span>
          </LinkWrapper>
        </span>
      );
    }
  }
  return '';
}

const RichTextRender: React.FC<RichTextRender> = ({
  bodyContent,
  pageType,
  renderMode,
  forceLinksOpenNewWindow = false,
  replacementDictionary,
  slateLinkStyle
}) => {
  const untagChildrenOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => children,
      [INLINES.ENTRY_HYPERLINK]: (node, children) =>
        renderEntryLink(
          bodyContent,
          node as Inline,
          children,
          forceLinksOpenNewWindow,
          slateLinkStyle
        ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <RichTextHyperlink
            node={node as Inline}
            slateLinkStyle={slateLinkStyle}
          >
            {children}
          </RichTextHyperlink>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) =>
        renderEmbedEntry(bodyContent, node as Inline, children, slateLinkStyle)
    }
  };

  const options: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) =>
        renderBodyCopy(children, 'p', pageType),
      [BLOCKS.HEADING_1]: (_node, children) =>
        renderBodyCopy(children, 'h1', pageType),
      [BLOCKS.HEADING_2]: (_node, children) =>
        renderBodyCopy(children, 'h2', pageType),
      [BLOCKS.HEADING_3]: (_node, children) =>
        renderBodyCopy(children, 'h3', pageType),
      [BLOCKS.HEADING_4]: (_node, children) =>
        renderBodyCopy(children, 'h4', pageType),
      [BLOCKS.HEADING_5]: (_node, children) =>
        renderBodyCopy(children, 'h5', pageType),
      [BLOCKS.HEADING_6]: (_node, children) =>
        renderBodyCopy(children, 'h6', pageType),
      [BLOCKS.UL_LIST]: (_node, children) =>
        renderBodyCopy(children, 'ul', pageType),
      [BLOCKS.OL_LIST]: (_node, children) =>
        renderBodyCopy(children, 'ol', pageType),
      [BLOCKS.LIST_ITEM]: (node) =>
        documentToReactComponents(node as Document, untagChildrenOptions),
      [BLOCKS.TABLE]: (_node, children) => (
        // Code for displaying table, styling etc
        <Table>{children}</Table>
      ),
      [BLOCKS.QUOTE]: (_node, children) => renderBlockQuote(children),
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        // This is where content-types/ components are rendered if allowed
        switch (renderMode) {
          case RICHTEXT_RENDER_MODE.ALLOW_BLOCK_EMBEDS:
            return (
              <RenderReferencedComponent
                nodeData={node.data}
                links={bodyContent?.links}
                pageType={pageType}
              />
            );

          default:
            break;
        }
        return null;
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) =>
        renderEntryLink(
          bodyContent,
          node as Inline,
          children,
          forceLinksOpenNewWindow,
          slateLinkStyle
        ),
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <RichTextHyperlink
            node={node as Inline}
            slateLinkStyle={slateLinkStyle}
          >
            {children}
          </RichTextHyperlink>
        );
      },
      // eslint-disable-next-line react/display-name
      [INLINES.EMBEDDED_ENTRY]: (node, children) =>
        renderEmbedEntry(bodyContent, node as Inline, children, slateLinkStyle),
      // eslint-disable-next-line react/display-name
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        console.log(
          'INLINES.ASSET_HYPERLINK : not in use (asset are in cloudinary, not locally in contentful)',
          node,
          children
        );
        return null;
      }
    }
  };

  // Here is where the render begins
  return (
    <Content>
      {documentToReactComponents(
        replaceRichTextJson(bodyContent?.json, replacementDictionary),
        options
      )}
    </Content>
  );
};

export default RichTextRender;

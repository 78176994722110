import React, { useCallback, useState } from 'react';
import {
  GlobalSettingItemFragment,
  Maybe,
  NavElFragment,
  NavSubElFragment,
  PageCommunityDetail,
  PageCommunityNews,
  PageCommunityPropertyDetail,
  PageCommunityStandard
} from '@src/graphql/gql-types';
import { COLOR_SLATE } from '@src/types/colors';
import { getLinkData } from '@src/utils/url';
import Visibility from '@src/elements/Visibility';
import Icon from '@src/elements/Icon';
import { NavLogo, DesktopMenu, MobileMenu } from '@src/components/Nav';
import PreviewBanner from '@src/components/PreviewBanner';
import { SVG_HAMBURGER, SVG_SEARCH, SVG_SLASH } from '@src/types/svg';
import { ButtonIcon } from '@src/components/Button';
import SiteSearch from './SiteSearch';
import styles from './Header.module.scss';
import { Container } from '@src/elements/Grid';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import { isCommunityPage } from '@src/utils/helpers';
import LinkWrapper from '../LinkWrapper';
import EnquireButton from './EnquireButton';
import classNames from 'classnames';

interface Header {
  primaryMenu?: Maybe<NavElFragment & NavSubElFragment>[];
  pageCommunityData?: Partial<
    | PageCommunityStandard
    | PageCommunityDetail
    | PageCommunityPropertyDetail
    | PageCommunityNews
  >;
  communityName?: Maybe<string>;
  communitySlug?: Maybe<string>;
  dynamicFormValues?: NationalEnquiryDynamicFormValues;
  formDisclaimerRichText?: GlobalSettingItemFragment;
  headOfficePhoneNumber?: Maybe<string>;
  preview?: Maybe<boolean>;
}

const Header: React.FC<Header> = ({
  primaryMenu,
  pageCommunityData,
  communityName,
  communitySlug,
  dynamicFormValues,
  formDisclaimerRichText,
  headOfficePhoneNumber,
  preview
}) => {
  const menuLinks = getLinkData(primaryMenu, true) || [];

  const [mobileMenuState, setMobileMenuState] = useState({
    open: false,
    focusSearch: false
  });

  const isCommunity = isCommunityPage(pageCommunityData);

  const handleToggleMobileMenuOpen = useCallback(
    ({ focusSearch }: { focusSearch: boolean }) => {
      setMobileMenuState(() => ({
        open: !mobileMenuState.open,
        focusSearch
      }));
    },
    [mobileMenuState]
  );

  return (
    <div
      className={classNames(styles.header, {
        [styles.previewHeader]: preview
      })}
      data-testid="header"
    >
      {preview && <PreviewBanner />}
      <div className={styles.headerTopBar}>
        <Container>
          <div className={styles.headerTopBarInner}>
            <div className={styles.headerTopBarLogoWrapper}>
              <div
                className={styles.headerTopBarLogoInner}
                data-testid="headerLogo"
              >
                <NavLogo color={COLOR_SLATE} small />

                {isCommunity && (
                  <Visibility xs={false} sm={true}>
                    <div className={styles.headerTopBarLogoSlash}>
                      <Icon icon={SVG_SLASH} />
                    </div>
                  </Visibility>
                )}
              </div>

              {isCommunity && (
                <Visibility xs={false} sm={true}>
                  <span className={styles.headerTopBarLogoText}>
                    <LinkWrapper
                      {...(communitySlug && {
                        href: `/community/${communitySlug}`
                      })}
                    >
                      {communityName}
                    </LinkWrapper>
                  </span>
                </Visibility>
              )}
            </div>

            <div className={styles.headerTopBarControls}>
              <Visibility xs={false} lg>
                <EnquireButton
                  testid="headerEnquireButton"
                  isCommunity={isCommunity}
                  dynamicFormValues={dynamicFormValues}
                  formDisclaimerRichText={formDisclaimerRichText}
                  headOfficePhoneNumber={headOfficePhoneNumber}
                />
                <SiteSearch testid="headerSiteSearch" variant="dark" />
              </Visibility>

              <Visibility xs={true} sm={false}>
                <EnquireButton
                  size="sm"
                  testid="mobileHeaderEnquireButton"
                  isCommunity={isCommunity}
                  dynamicFormValues={dynamicFormValues}
                  formDisclaimerRichText={formDisclaimerRichText}
                  headOfficePhoneNumber={headOfficePhoneNumber}
                />
              </Visibility>

              <Visibility xs={false} sm={true} lg={false}>
                <EnquireButton
                  size="md"
                  testid="tabletHeaderEnquireButton"
                  isCommunity={isCommunity}
                  dynamicFormValues={dynamicFormValues}
                  formDisclaimerRichText={formDisclaimerRichText}
                  headOfficePhoneNumber={headOfficePhoneNumber}
                />
              </Visibility>

              <Visibility xs lg={false}>
                <ButtonIcon
                  data-testid="searchMenuButton"
                  aria-label="Open the menu and search"
                  size="xxl"
                  fillVariant="outline"
                  color={COLOR_SLATE}
                  isBorderless
                  icon={SVG_SEARCH}
                  onClick={() =>
                    handleToggleMobileMenuOpen({ focusSearch: true })
                  }
                />
              </Visibility>

              <Visibility xs={true} lg={false}>
                <ButtonIcon
                  data-testid="hamburgerMenuButton"
                  aria-label="Open the menu"
                  size="xxl"
                  fillVariant="outline"
                  color={COLOR_SLATE}
                  isBorderless
                  icon={SVG_HAMBURGER}
                  onClick={() =>
                    handleToggleMobileMenuOpen({ focusSearch: false })
                  }
                />
              </Visibility>
            </div>
          </div>
        </Container>
      </div>
      <Visibility xs={true} lg={false}>
        <MobileMenu
          menuLinks={menuLinks}
          mobileMenuState={mobileMenuState}
          setMobileMenuState={setMobileMenuState}
          pageCommunityData={pageCommunityData}
          formDisclaimerRichText={formDisclaimerRichText}
          dynamicFormValues={dynamicFormValues}
        />
      </Visibility>
      <Visibility xs={false} lg={true}>
        <DesktopMenu menuLinks={menuLinks} />
      </Visibility>
    </div>
  );
};

export default Header;

import React from 'react';
import classNames from 'classnames';
import { COLOR_SLATE } from '@src/types/colors';
import { getButtonPillClasses } from '@src/utils/buttonClasses';
import styles from './ButtonPill.module.scss';
import Icon from '@src/elements/Icon';
import { Button } from '@src/types/buttons';

const ButtonPill: React.FC<Button> = ({
  color = COLOR_SLATE,
  colorVariant = 'dark',
  icon,
  iconPosition = 'right',
  isIconBouncing,
  children,
  ...rest
}) => {
  const buttonPillStyle = classNames(
    getButtonPillClasses(color, colorVariant, styles)
  );

  return (
    <button
      type="button"
      className={classNames(buttonPillStyle, styles.buttonContainer, {
        [styles.buttonContainerIconLeft]: iconPosition === 'left',
        [styles.disabled]: rest?.disabled
      })}
      {...rest}
    >
      {children}
      {icon && (
        <div
          className={classNames(styles.icon, {
            [styles.iconSolo]: !children,
            [styles.iconLeft]: iconPosition === 'left',
            [styles.bounceIcon]: isIconBouncing
          })}
        >
          <Icon icon={icon} />
        </div>
      )}
    </button>
  );
};

export default ButtonPill;

import React from 'react';
import { useWindow } from '@src/hooks/useWindow';
import {
  FORM_ERROR_URL_PARAMETER,
  FORM_SUCCESS_URL_PARAMETER
} from '@src/types/forms';

type UrlRedirectInput = {
  urlParam: FORM_SUCCESS_URL_PARAMETER | FORM_ERROR_URL_PARAMETER;
};

/**
 * Salesforce UrlRedirectInput component
 * @returns hidden form input with env specific redirect url (retURL)
 */
const UrlRedirectInput: React.FC<UrlRedirectInput> = ({ urlParam }) => {
  const windowObj = useWindow();

  return (
    <input
      type="hidden"
      name="retURL"
      // NOTE: retUrl will only redirect on a secure connection AND not localhost :(
      value={
        process.env.NEXT_PUBLIC_LOCAL_DEV
          ? `https://dev.levande.com.au/#${urlParam}`
          : `${windowObj.href}#${urlParam}`
      }
    />
  );
};

export default UrlRedirectInput;
